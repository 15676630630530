import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { AREA_PREFIX, BANNER_IMG, GIFT_PREFIX, COUPON_TYPE, TOAST_MSG_TYPE,COUPON_AMOUNT_TYPE,NOTICE_PREFIX } from '../../utils/Constants';
import { routeService } from '../../Services/RouteService';
import { productService } from '../../Services/ProductService';
import { Select } from 'antd';
import { DatePicker } from 'antd';
import moment from 'moment';
import ImageUpload from '../ImageUpload';
import { BANNER_LINK_TYPE } from '../../utils/Constants';
import { useCostomerAppTokenList } from '../../hooks/ProductHooks';
import { useNotificationsList,useCityList,useCustomerTableCityList } from '../../hooks/RouteHooks';
import { useMerchantProductList } from '../../hooks/ProductHooks';
import { useAdminCateogryList } from '../../hooks/CategoryHooks';
const dateFormat = 'YYYY/MM/DD';
const CARD_TYPE = [
	{label: "All Customers", value: "ALL_USERS"},
	{label: "Select Customers", value: "SELECTED_CUSTOMERS"},
    {label: "Cart Customers", value: "CART_USERS"},
    {label: "New Customers", value: "NEW_USERS"},	
	{label: "Existing Customers", value: "EXISTING_USERS"},	
	{label: "Order Customers", value: "ORDER_CUSTOMERS"},
	{label: "Default Merchant Customers", value: "DEFAULT_MERCHANT_CUSTOMERS"},
	{label: "Non-Default Merchant Customers", value: "NON_DEFAULT_MERCHANT_CUSTOMERS"},
	{label: "Payment Intent Customers", value: "PAYMENT_INTENT_CUSTOMERS"},
]
const initialState = {message: "", title:"",external_link:"",notice_image: {server: ""},send_to:[],customer_city:"",firebase_topic_name:"",product_id:0,category_id:0,schedule_date: moment(),}
const GiftCardForm = (props) => {
    const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
	
	const [params, setParams] = useState({paginated: false, limit: 20, offset: 0, search: "", field: "id", sort: "DESC", counter: 0,customer_city: "",fetch_now:false })
	const {categoryData} = useAdminCateogryList(axiosPrivate, params)
	const [cityParam, seCityParams] = useState({paginated: false, offset:0, limit: 10, field: "area_name", sort: "ASC", search: ""})
    const {citiesData, loading:Floading, error:Eerror} = useCustomerTableCityList(axiosPrivate);
	
	const [optionsProducts, setOptionsProducts] = useState([]);
	const [customerData, setCustomerData] = useState([]);
    useEffect(() => {
        setShowLoader(false)
    }, [props.id])
    
    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])
    const onClickProdcut= () => {
		if(customerData && customerData.length==0){
		setParams({
            ...params,
			fetch_now: true
        })
		}
	}
    const onChangeField = (e) => {		
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
    const onChangeProduct = (e) => {
		setFormState({...formState, send_to: e})		
    }
	const onChangeEndDate = (value, dateString) => {
		console.log(dateString)
        setFormState({...formState, schedule_date: moment(dateString)})
		if(formState.firebase_topic_name==="ORDER_CUSTOMERS"){
			let paramData= { search_string:"", customer_city:params.customer_city,order_customers:true,order_date:dateString }
			productService.pushNotificationCustomers(axiosPrivate, paramData )
			  .then((res) => {
				const relatedProductList = res.data.pushNotificationCustomers;
				setCustomerData(relatedProductList);
				var customers=[]
				relatedProductList && relatedProductList.map(customer => {
				customers.push(customer.id)
				});
				setFormState({...formState, send_to: customers,schedule_date: moment(dateString)})
				return;
			  }).catch(error => {
				console.error('Error searching for related products:', error);
			});
		}
    }
	const onChangeProductLink = (e) => {
		setFormState({...formState, product_id: e})
    }
	const onChangeCategory = (e) => {
		setFormState({...formState, category_id: e})		
    }
	const onChangeBannerType = (value) => {		
		if(value==="ORDER_CUSTOMERS"){
			let paramData= { search_string:"", customer_city:params.customer_city,order_customers:true,order_date:formState.schedule_date }
			productService.pushNotificationCustomers(axiosPrivate, paramData )
			  .then((res) => {
				const relatedProductList = res.data.pushNotificationCustomers;
				setCustomerData(relatedProductList);
				var customers=[]
				relatedProductList && relatedProductList.map(customer => {
				customers.push(customer.id)
				});
				setFormState({...formState, send_to: customers,firebase_topic_name: value})
				return;
			  }).catch(error => {
				console.error('Error searching for related products:', error);
			});
		}else{
			setFormState({...formState, firebase_topic_name: value});
		}
    }
	const filterChange = (e) => {
		console.log(e)
        let newVal = e
		setFormState({...formState, customer_city: newVal,firebase_topic_name:""})
    }
	const handleInputChangeRelatedProduct = (inputValue) => {
		if (inputValue && inputValue.length>2) {
		productService.searchRelatedProduct(axiosPrivate, inputValue)
		  .then((res) => {
			const relatedProductList = res?.searchproductsListingAdmin;
			const newOptions = relatedProductList?.map(product => ({
			  id: Number(product.id),
			  product_title: product.product_title+"-"+product.store_name
			}));
			//const uniqueOptions = [...optionsProducts, ...newOptions.filter(newOpt => !optionsProducts.some(opt => opt.value === newOpt.value))];
			setOptionsProducts(newOptions);
		  })
		  .catch(error => {
			console.error('Error searching for related products:', error);
		  });
		}
	}
	const handleInputChangeCustomers = (inputValue) => {
		let paramData= { search_string:inputValue, customer_city:params.customer_city  }
			if (inputValue && inputValue.length>2 && formState.firebase_topic_name!=="ORDER_CUSTOMERS") {
			productService.pushNotificationCustomers(axiosPrivate, paramData )
			  .then((res) => {
				  console.log(res)
				const relatedProductList = res.data.pushNotificationCustomers;
				setCustomerData(relatedProductList);
				return;
			  })
			  .catch(error => {
				console.error('Error searching for related products:', error);
			  });
			}
	}

    const submitHandler = (e) => {
        e.preventDefault()
		console.log(formState)
        if(!formState.title) {
            showToast("Please Enter Title", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.message) {
            showToast("Please Enter Message", TOAST_MSG_TYPE.ERROR)
            return
        }
		let paramData ={
			...formState,
			title: formState.title,
			message: formState.message,
			external_link: formState.external_link,
			notification_type: "GENERAL",
			firebase_topic_name: formState.firebase_topic_name,
			schedule_date: Number(formState.schedule_date/1000),
		}
		delete paramData.notice_image
        if("local" in formState.notice_image) {
            delete formState.notice_image.local
        }
        const apiPromise = productService.sendNotifications(axiosPrivate, paramData, formState.notice_image.server)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.sendNotifications) {
                showToast(props.crud === "create" ? "Notification Send Successfully!" : "Notification Send Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
               navigate(`${NOTICE_PREFIX}`)
            }
        })
    }
    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
				    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>Notice Title *</label>
                            <input type="text" className='form-control' name='title' value={formState.title} onChange={onChangeField} />
                        </div>
                    </div>				
					<div className='col-md-4'>
                        <div className='form-group'>
                            <label>External link</label>
                            <input type="text" className='form-control' name='external_link' value={formState.external_link} onChange={onChangeField} disabled={disabled}/>
                        </div>	
                    </div>
                    <div className='col-md-4'>
                            <div className='form-group'>
                                <label>Upload Image</label>
                                <ImageUpload
                                    fileName={formState.notice_image.server}
                                    fileList={
                                    formState.notice_image.server
                                        ? [
                                            {
                                            uid: formState.notice_image,
                                            url:"local" in formState.notice_image ? `${formState.notice_image.local.url}` : `${BANNER_IMG}/${formState.notice_image.server}`
                                            }
                                        ]
                                        : []
                                    }
                                    getFile={file => {
                                        setFormState({...formState, notice_image: file});
                                    }}
                                    onRemove={() => setFormState({...formState, notice_image: {server: ""}})}
                                    crud={props.crud}
                                />
                            </div>
                        </div>					
                </div>
				<div className='row mb-4'>
				<div className='col-md-3'>
					<label className='mobile-lable-spacing'>City:</label>
					<Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.customer_city}
                                options={citiesData}
								fieldNames={{label: "customer_address_city", value: "customer_address_city"}}
                                onChange={filterChange}
                            />
				</div>
				<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Customer Filter*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.firebase_topic_name}
                                options={CARD_TYPE}
                                onChange={onChangeBannerType}
                            />
                        </div>
                    </div>
				
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Select Date</label>
                            <DatePicker							
                                placeholder="Select End Date" 
                                className="form-control" 
								onChange={onChangeEndDate} 
                                value={formState.schedule_date || ""}
								defaultValue={moment()} 
								format={dateFormat}
                            />
                        </div>
                    </div>
					{
                    formState.firebase_topic_name=="SELECTED_CUSTOMERS" || formState.firebase_topic_name=="ORDER_CUSTOMERS" ?  
					<div className='col-md-12'>
						<div className='form-group'>
							<label>Select Customer</label>
							<Select 
								showSearch={true}
								mode="multiple"
								className='select col-md-12'
								value={formState.send_to}
								options={customerData}
								onChange={onChangeProduct}
								onClick={onClickProdcut}
								optionFilterProp={"email"}
								fieldNames={{label: "email", value: "id"}}
								onSearch={handleInputChangeCustomers}
							/>
						</div>
					</div>: null   
					}
					
					</div>	
                <div className='row mb-4'>
					<div className='col-md-6'>
						<div className='form-group'>
							<label>Link Product</label>
							<Select 
								showSearch={true}
								className='select col-md-12'
								options={optionsProducts}
								onChange={onChangeProductLink}
								optionFilterProp={"product_title"}
								fieldNames={{label: 'product_title', value: "id"}}
								onSearch={handleInputChangeRelatedProduct}
							/>
						</div>
					</div>	
					<div className='col-md-6'>
						<div className='form-group'>
							<label>Link Category</label>
							<Select 
								showSearch={true}
								className='select col-md-12'
								value={formState.category_id}
								options={categoryData}
								onChange={onChangeCategory}
								optionFilterProp={"category_title"}
								fieldNames={{label: "category_title", value: "id"}}
							/>
						</div>
					</div>					
					</div>										
				<div className='row mb-4'>
					<div className='col-md-12'>
					<div className='form-group'>
                                <label>Message:</label>
                                <textarea className='form-control' rows={4} value={formState.message} name='message' onChange={onChangeField}></textarea>
                            </div>
					</div>
				</div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save & Send</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default GiftCardForm