import { Table } from 'antd';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Link,useParams } from 'react-router-dom';
import moment from 'moment';
import { itemRender } from '../Components/paginationfunction';
import { CommonContext } from '../context/CommonContext';
import { useCardITEMList } from '../hooks/ProductHooks';
import useAuth from '../hooks/useAuth';
import { PRODUCT_PREFIX, TOAST_MSG_TYPE, getConstantData} from '../utils/Constants';
import { productService } from '../Services/ProductService';

const CardDetails = () => {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
    let { cardId } = useParams()
    const {customerData} = useCardITEMList(axiosPrivate, cardId)
	
    const render = useMemo(() => {
        const columns = [
		    {
                title: 'P Id',
                dataIndex: 'product_id'
            },
            {
                title: 'product_title',
                dataIndex: 'product_title'
            },
            {
                title: 'Brand',
                dataIndex: 'product_brand'
            },
            {
                title: 'Variant',
                dataIndex: 'variant_value',
				render: (text,row) => {
					return row.variant_value+ ' x '+ row.variant_qty
				}
            },
            {
                title: 'Qty',
                dataIndex: 'quantity'
            },
			{
                title: 'Variant Price',
                dataIndex: 'variant_price',
				render: (text,row) => {
					return row.variant_discounted_price>0 ? row.variant_discounted_price : row.variant_price
				}
            }
        ]
        return columns
    }, [auth.permissions])

    

    
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Products</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Customers card Items</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">{cardId}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                               
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
							pagination= { {pageSize: 100, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}             
                                bordered
                                dataSource={customerData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}
export default CardDetails