import React, { Component, useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams, withRouter } from 'react-router-dom';
import ImageUpload from '../Components/ImageUpload';
import { CommonContext } from '../context/CommonContext';
import { categoryService } from '../Services/CategoryService';
import { productService } from '../Services/ProductService';
import { API_BASE_URL, PRODUCT_PREFIX, TOAST_MSG_TYPE } from '../utils/Constants';
import { menuService } from '../Services/MenuService';

const initialState = {csv_file: {server: ""}}
const ProductCsvUploader = () => {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState})
    let title = "Upload Product CSV"
    let breadcrumbData = [{title: "Upload Csv", link: null}]
    const navigate = useNavigate()

    const menuData = (e) => {
        e.preventDefault()
        const apiPromise = menuService.getMenuExcel(axiosPrivate)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            const data = res.data.merchantAllStoreMenusEXCEL
            if(data.status == "success") {
                const filePath = data.path.replace("./", "")
                console.log(filePath)
                const link = document.createElement("a");
                link.download = `category.xlsx`;
                link.href = `${API_BASE_URL}${filePath}`;
                link.click();
            }
        })
    }

    const sampleCSV = (e) => {
        const link = document.createElement("a");
        // link.download = `product.csv`;
        link.href = `${API_BASE_URL}public/order%20app%20products%20sample.csv`;
        link.click();
    }
	
	const deleteProductsAndTags = (e) => {
		console.log("deleteProductsAndTags Called")
		const apiPromise = productService.deleteProductsTags(axiosPrivate)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.deleteAllProductsAndTags) {
                showToast("Products,Variants And Tags Deleted Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${PRODUCT_PREFIX}`)
            }
        })
	}

    const onSubmitHandler = (e) => {
        e.preventDefault()
        if(!formState.csv_file.server) {
            showToast("Please select file to upload", TOAST_MSG_TYPE.ERROR)
            return
        }
        const apiPromise = productService.uploadProductCsv(axiosPrivate, formState.csv_file.server)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.updloadCsvAddGrocery) {
                showToast("Product Uploaded Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${PRODUCT_PREFIX}`)
            }
        })
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3 className="page-title">{title}</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                {
                                    breadcrumbData.map((obj, index) => {
                                        if(obj.link){
                                            return (
                                                <li key={`bread_${index}`} className="breadcrumb-item"><Link to={`${obj.link}`}>{obj.title}</Link></li>
                                            )
                                        }else {
                                            return (
                                                <li key={`bread_${index}`} className="breadcrumb-item active">{obj.title}</li>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 mb-1'>
                        <button className="float-right btn btn-primary btn-width d-block" onClick={menuData}>Download Menu Data</button>
                        <button className="float-right btn btn-primary btn-width d-block mr-2" onClick={sampleCSV}>Download Sample CSV</button>
						<button className="float-right btn btn-danger btn-width d-block mr-2" onClick={deleteProductsAndTags}>Delete All Products,Variants and Tags</button>
                    </div>
                    <div className='col-12'>
                    <form>
                        <div className='row mb-4'>
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Upload Product XL</label>
                                    <ImageUpload
                                        btnText={"Upload"}
                                        fileName={formState.csv_file.server}
                                        fileList={
                                        formState.csv_file.server
                                            ? [
                                                {
                                                uid: formState.csv_file,
                                                url:"local" in formState.csv_file ? `${formState.csv_file.local.url}` : ``
                                                }
                                            ]
                                            : []
                                        }
                                        getFile={file => {
                                            setFormState({...formState, csv_file: file});
                                        }}
                                        onRemove={() => setFormState({...formState, csv_file: {server: ""}})}
                                        crud={"create"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <button className="btn btn-primary btn-width mx-auto d-block" onClick={onSubmitHandler}>Submit CSV</button>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductCsvUploader