import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import Select from 'react-select';

const initialState = {child_cat_id: undefined, display_order: 0}
const MenuCategory = (props) => {
    const { axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState, localID: props.menu_item.localID})
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        props.updateFormState({...formState}, props.menu_item.localID)
    }, [formState])
    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])
    useEffect(() => {
        if(props.categoriesData.length && props.menu_item) {
            setFormState({
                ...formState, 
                ...props.menu_item
            })
        }
    }, [props.menu_item, props.categoriesData])
    const onChangeField = (e) => {
        setFormState({...formState, display_order: e.target.value})
    }
    
    const onChangeCategory = (e) => {
        setFormState({...formState, child_cat_id: {...e}})
    }
    return (
        <>
        {
            <div className='row mb-4'>
                <div className='col-md-4'>
                    <label>Submenu<span className="text-danger">*</span></label>
                    <Select 
                        className="select" 
                        value={formState.child_cat_id} 
                        options={props.categoriesData}
                        isLoading={props.loading}
                        name="category" 
                        onChange={(e) => onChangeCategory(e)} 
                        isDisabled={disabled}
                        getOptionValue={(option) => `${option.id}`}
                        getOptionLabel={(option) => `${option.category_title}`}
                    >
                    </Select>
                </div>
                <div className='col-md-3'>
                    <label>Display Order<span className="text-danger">*</span></label>
                    <input type="text" className='form-control' name='display_order' value={formState.display_order} onChange={onChangeField} disabled={disabled}/>
                </div>
                <div className="col-md-1">
                    <i className='la la-trash delete-icon float-right variant-delete' onClick={(e) => props.deleteSubcateogry(e, props.menu_item.localID)}/>
                </div>
            </div>
        }
        </>
    )
}

export default MenuCategory