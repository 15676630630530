import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom';
import { AREA_PREFIX, TOAST_MSG_TYPE } from '../../utils/Constants';
import { routeService } from '../../Services/RouteService';

const initialState = {city_id: 0, area_name: "", min_order_amount:0, shipping_charges: 0, free_shipping_amount: 0,free_delivary_days: [],min_order_amount_2:0,shipping_charges_2:0,free_shipping_day_amount:0}
const CityForm = (props) => {
    const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    useEffect(() => {
        if(props.city_id) {
            routeService.cityByID(axiosPrivate, props.city_id).then(res => {
                if(res.data.cityDetailsById) {
                    const data = res.data.cityDetailsById
                    setFormState({
                        ...data,
                        city_id: data.id
                    })
                    setShowLoader(false)
                }else {
                    navigate(`${AREA_PREFIX}`)
                }
            })    
        }else {
            setShowLoader(false)
        }
    }, [props.city_id])

    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
		if(name!=="area_name"){
			setFormState({...formState, [name]: Number(value)})
		}else{
        setFormState({...formState, [name]: value})
		}
    }
	const handleCheckboxChange = (event) => {
		if (event.target.checked) {
		  if (!formState.free_delivary_days.includes(event.target.value)) {
			    setFormState({...formState, free_delivary_days: [...formState.free_delivary_days,event.target.value]})
		  }
		} else {
			setFormState({...formState, free_delivary_days: formState.free_delivary_days.filter(day => day !== event.target.value)});
		}
	}
    
    const submitHandler = (e) => {
		console.log(formState)
        e.preventDefault()
        if(!formState.area_name) {
            showToast("Please Fill Area", TOAST_MSG_TYPE.ERROR)
            return
        }
		if(formState.min_order_amount_2>0 && formState.shipping_charges_2<1) {
            showToast("Please Fill Shipping Charges(A) or set 0 to Shipping Charge(A) Max Range", TOAST_MSG_TYPE.ERROR)
            return
        }
        const apiPromise = routeService.addUpdateArea(axiosPrivate, formState)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.createUpdateCity) {
                showToast(props.crud === "create" ? "Area Added Successfully!" : "Area Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${AREA_PREFIX}`)
            }
        })
    }

    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Area Name*</label>
                            <input type="text" className='form-control' name='area_name' value={formState.area_name} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Min. Order Amount*</label>
                            <input type="number" className='form-control' name='min_order_amount' value={formState.min_order_amount} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Shipping Charge(A) Max Range</label>
                            <input type="number" className='form-control' name='min_order_amount_2' value={formState.min_order_amount_2} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>					
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Shipping Charges(A)</label>
                            <input type="number" className='form-control' name='shipping_charges_2' value={formState.shipping_charges_2} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Shipping Charges(B)*</label>
                            <input type="number" className='form-control' name='shipping_charges' value={formState.shipping_charges} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Order Amount for Free Shipping*</label>
                            <input type="number" className='form-control' name='free_shipping_amount' value={formState.free_shipping_amount} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-9'>
						<div className='form-group'>
							<input type="checkbox" id="Sunday" value="Sunday" onChange={handleCheckboxChange} style={{marginRight: "5px"}} checked={formState.free_delivary_days.includes("Sunday")}/><label htmlFor="subcat" style={{marginRight: "10px"}}>Sunday</label>
							
							<input type="checkbox" id="Monday" value="Monday" onChange={handleCheckboxChange} style={{marginRight: "5px"}} checked={formState.free_delivary_days.includes("Monday")}/><label htmlFor="subcat" style={{marginRight: "10px"}}>Monday</label>
							
							<input type="checkbox" id="Tuesday" value="Tuesday" onChange={handleCheckboxChange} style={{marginRight: "5px"}} checked={formState.free_delivary_days.includes("Tuesday")} /><label htmlFor="subcat" style={{marginRight: "10px"}}>Tuesday</label>
							
							<input type="checkbox" id="Wednesday" value="Wednesday" onChange={handleCheckboxChange} style={{marginRight: "5px"}} checked={formState.free_delivary_days.includes("Wednesday")} /><label htmlFor="subcat" style={{marginRight: "10px"}}>Wednesday</label>
							
							<input type="checkbox" id="Thursday" value="Thursday" onChange={handleCheckboxChange} style={{marginRight: "5px"}} checked={formState.free_delivary_days.includes("Thursday")}  /><label htmlFor="subcat" style={{marginRight: "10px"}}>Thursday</label>
							
							<input type="checkbox" id="Friday" value="Friday" onChange={handleCheckboxChange} style={{marginRight: "5px"}} checked={formState.free_delivary_days.includes("Friday")} /><label htmlFor="subcat" style={{marginRight: "10px"}}>Friday</label>
							
							<input type="checkbox" id="Saturday" value="Saturday" onChange={handleCheckboxChange} style={{marginRight: "5px"}} checked={formState.free_delivary_days.includes("Saturday")} /><label htmlFor="subcat" style={{marginRight: "10px"}}>Saturday</label>
							
						</div>
						               
					</div>	
						<div className='col-md-6'>
							<div className='form-group'>
								<label>Min. Order Amount For Free Shipping Day*</label>
								<input type="number" className='form-control' name='free_shipping_day_amount' value={formState.free_shipping_day_amount} onChange={onChangeField} disabled={disabled}/>
							</div>
						</div>					
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default CityForm