import React, { useState, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

function UploadImage({ formState, onChangeField }) {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (formState.product_images) {
      setFileList(formState.product_images);
    }
  }, [formState.product_images]);

  const handleChange = ({ file, fileList: newFileList }) => {
    if (file.status === 'removed') {
      const removedFileName = file.name;
    const updatedFileList = newFileList.filter(item => item.name !== removedFileName);
    setFileList(updatedFileList);
    onChangeField('product_images', updatedFileList);
    onChangeField('product_uploaded_image', updatedFileList.map((item)=>item.name));
    } else {
      setFileList(newFileList);
      onChangeField('product_images', newFileList);
      onChangeField('product_uploaded_image', newFileList.map((item)=>item.name));
      if (file.status === 'done') {
        const uploadedFile = file.originFileObj;
        console.log('Uploaded file:', uploadedFile);
      } else if (file.status === 'error') {
        console.log('Error uploading file:', file);
      }
    }
  };

  return (
    <div className="card mb-4" style={{ minHeight: `${fileList.length * 10 + 200}px` }}>
      <div className="card-header d-flex justify-content-between align-items-center">
        <h5 className="mb-0 card-title">Media</h5>
      </div>
      
      <div className="card-body d-flex flex-column justify-content-center align-items-center">
        <Upload
          action="/upload"
          className="dropzone needsclick"
          id="dropzone-basic"
          fileList={fileList}
          onChange={handleChange}
          beforeUpload={() => false} 
          accept="image/*"
          multiple
        >
          <div className="dz-message needsclick text-center">
            <p className="fs-4 note needsclick mb-1">Drag and drop your images here</p>
            <p className="text-muted mb-2">or</p>
            <UploadOutlined className="note needsclick btn bg-label-primary" id="btnBrowse" style={{ fontSize: '36px' }} />
          </div>
        </Upload>
      </div>
    </div>
  );
}

export default UploadImage;