const API_BASE_URL = "https://bazaaromall.com:8082/"
const PERMISSION_STR_OBJ = {
    "addStore": "AddStore"
}
const TOAST_MSG_TYPE = {
    ERROR: "error",
    SUCCESS: "success"
}
export const BUSINESS_TYPE = [
    {label: "Grocery", value:"1"},
    {label: "Restaurant", value:"2"},
]

const OTP_LENGTH = 4
const STORE_TYPE = [
    {label: "Online", value: 1},
    // {label: "Physical", value: "Physical"},
    // {label: "Both", value: "Both"},
]
export const DAYS = [
    {label: "Sunday", value: "Sunday"},
    {label: "Monday", value: "Monday"},
    {label: "Tuesday", value: "Tuesday"},
    {label: "Wednesday", value: "Wednesday"},
    {label: "Thursday", value: "Thursday"},
    {label: "Friday", value: "Friday"},
    {label: "Saturday", value: "Saturday"},
]
export const TIMING = [
    "00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00",
    "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", 
    "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00",
    "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", 
    "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", 
    "22:30", "23:00", "23:30"
]
export const BANNER_TYPE = [
    {label: "Home", value: "Home"},
    {label: "Submenu", value: "Submenu"},
	{label: "Featured", value: "Featured"},
]
export const COUPON_TYPE = [
    {label: "Checkout", value: "Checkout"},
    {label: "Free Product", value: "Free_Product"},
	{label: "Discounted Product", value: "Discounted_Product"},
	{label: "Category", value: "Category"},
	{label: "Free Delivery", value: "Free_Delivery"},
]
export const COUPON_AMOUNT_TYPE = [
    {label: "True", value: true},
    {label: "False", value: false},
]
export const BANNER_LINK_TYPE = [
    {label: "External", value: "External"},
    {label: "Internal", value: "Internal"},
]
const ACTIONS = {
    MAKE_REQUEST: 'make-request',
    GET_DATA: 'get-data',
    ERROR: 'error'
}
const USER_ROLE = {
    ADMIN: "1",
    MERCHANT: "2"
}
export const DASHBOARD = "/dashboard"
export const MERCHANT_PREFIX = "/merchant"
export const STORE_PREFIX = "/store"
export const AREA_PREFIX = "/area"
export const ROUTE_PREFIX = "/route"
export const FLEET_PREFIX = "/fleet"
export const F_D_CHANGE = "/f-d-change"
export const TEAM_PREFIX = "/team"
export const CATEGORY_PREFIX = "/categories"
export const PRODUCT_PREFIX = "/products"
export const STORE_MENU = "/menu"
export const BANNER_PREFIX = "/banner"
export const ORDER_PREFIX = "/order"
export const COUPON_PREFIX = "/coupons"
export const REVIEW_PREFIX = "/reviews"
export const GIFT_PREFIX = "/giftcards"
export const PUSHNOTICE_PREFIX="/pushnotice"
export const NOTICE_PREFIX="/pushnotice"
export const CUSTOMER_PREFIX="/customers"


export const CATEGORY_IMG = `${API_BASE_URL}public/images/product-images`
export const STORE_IMG = `${API_BASE_URL}public/images/store-images`
export const BANNER_IMG = `${API_BASE_URL}public/images/misc-images`
export const LICENSE_IMG = `${API_BASE_URL}public/images/misc-images`
export const ORDER_STATUS_IMG = `${API_BASE_URL}public/images/misc-images`
function checkPermission(checkAction, userPermissions) {
    return PERMISSION_STR_OBJ.hasOwnProperty(checkAction) ? userPermissions.includes(PERMISSION_STR_OBJ[checkAction]) : false
}
export function getConstantData(key, data) {
    if(data.hasOwnProperty(key)) {
        return data[key]
    }else {
        return []
    }
}
export function convertTimeTo12Hours(time) {
    let timePart = time.split(":")
    let hours = parseInt(timePart[0])
    let minutes = timePart[1]
    let convertedTime = ""
    if(hours > 12) {
        let timeDiff = hours - 12
        convertedTime = `${timeDiff > 9 ? timeDiff : `0${timeDiff}`}:${minutes}PM`
    }else {
        convertedTime = `${timePart[0]}:${timePart[1]}${hours == 12 ? "PM" : "AM"}`
    }
    return convertedTime
}
export {
    API_BASE_URL,
    TOAST_MSG_TYPE,
    OTP_LENGTH,
    STORE_TYPE,
    ACTIONS,
    USER_ROLE,
    checkPermission
}