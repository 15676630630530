import { useEffect, useState } from "react"

const Timer = (props) => {
    const [timing, setTiming] = useState({hours: 0, minutes: 0, seconds: 0, timing: props.testRemainingTime.timing})
    useEffect(() => {
        let timing = 0
        let timerStarted = false
        let interval = setInterval(() => {
            setTiming((prevObj) => {
                timing = prevObj.timing - 1
                timerStarted = true
                if(timing > 0) {
                    let hours = 0
                    let minutes = 0
                    let seconds = 0
                    hours = parseInt(timing/3600)
                    let remainTime = timing%3600
                    minutes = parseInt(remainTime/60)
                    remainTime = remainTime%60
                    seconds = parseInt(remainTime)
                    return {
                        hours: hours, minutes: minutes, seconds: seconds, timing: timing
                    }
                }else {
                    clearInterval(interval)
                    return {
                        hours: 0, minutes: 0, seconds: 0, timing: 0
                    }
                }
            })
            
            if(timerStarted && timing <= 1) {
                props.setTimeOver()
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [props.testRemainingTime])

    useEffect(() => {
        setTiming({...timing, timing: props.testRemainingTime.timing})
    }, [props.testRemainingTime])

    return (
        <span>{timing.minutes < 10 ? `0${timing.minutes}` : timing.minutes}:{timing.seconds < 10 ? `0${timing.seconds}` : timing.seconds}</span>
    )
}
export default Timer