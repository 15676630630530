/**
 * App Routes
 */
 import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../CommonLayout/header';
import AdminSidebar from './AdminSidebar';
 
const Layout = (props) => {
    
    return (
        <div className="main-wrapper">
            <Header logoURL={`/dashboard`}/>	
            <AdminSidebar />	
            <Outlet />
        </div>
    );
 }
 export default Layout;