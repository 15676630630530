import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContainerComponent = () => {
    return (
        <>
            <ToastContainer autoClose={8000} />
        </>
    )
}

export default ToastContainerComponent