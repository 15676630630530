import React, { useEffect,useState } from 'react';
import { Switch } from 'antd';
import 'antd/dist/antd.css';
import { DeleteOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

function ProductVariants({ formState, onChangeField }) {
  const { product_variants } = formState;
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const initializeFileList = async () => {
      const updatedFileList = await Promise.all(
        product_variants?.map(async (variant) => {
          try {
            const fileObject = JSON.parse(variant.variant_image);
            if (typeof fileObject === 'object' && fileObject !== null) {
              return [fileObject];
            }
          } catch (error) {
            if (variant.variant_image.trim() !== '') {
              return [{
                uid: variant.variant_image,
                name: variant.variant_image,
                status: 'uploaded'
              }];
            }
          }
          return [];
        })
      );
      setFileList(updatedFileList);
    };
    initializeFileList();
  }, [product_variants]);


  const handleChange = (info, index) => {
    const { file, fileList: newFileList } = info;
    if (file.status === 'removed') {
      const updatedFileList = [...fileList];
      updatedFileList[index] = newFileList;
      setFileList(updatedFileList);
      const updatedVariants = [...product_variants];
      updatedVariants[index].variant_image = '';
      updatedVariants[index].originFileObj = null;
      onChangeField('product_variants', updatedVariants);
    } else {
      const updatedFileList = [...fileList];
      updatedFileList[index] = newFileList;
      setFileList(updatedFileList);
      const fileObjectString = JSON.stringify(newFileList[0]);
      const updatedVariants = [...product_variants];
      updatedVariants[index].variant_image = fileObjectString || '';
      updatedVariants[index].originFileObj = newFileList[0] ? newFileList[0].originFileObj : null;
      onChangeField('product_variants', updatedVariants);
      if (file.status === 'error') {
        console.log('Error uploading file:', file);
      }
    }
  };

  useEffect(() => {
    if (product_variants.length === 0) {
      // Add a default variant if product_variants is empty
      const defaultVariant = {
        id: 0,
        variant_name: '',
        variant_value: '',
        variant_qty: 0,
        variant_stock: 0,
        variant_price: 0,
        variant_discounted_price: 0,
        varaint_supplier_price: 0,
        variant_rackid: '',
        variant_barcode: '',
        qty_per_user: 0,
		varaint_total_weight: 0.01,
		varaint_length: 1,
		varaint_width: 1,
		varaint_height: 1,
        variant_status: 0,
        variant_image: '',
        variant_uploaded_image:'',
        originFileObj: null
      };
      onChangeField('product_variants', [defaultVariant]);
      setFileList([[]]); 
    }
  }, []);

  const handleFieldChange = (e, index) => {
    const { name, value } = e.target;
    const updatedVariants = [...product_variants];
    updatedVariants[index][name] = value;
    onChangeField('product_variants', updatedVariants);
  };

  const handleSwitchChange = (checked, index) => {
    const updatedVariants = [...product_variants];
    updatedVariants[index].variant_status = checked ? 1 : 0;
    onChangeField('product_variants', updatedVariants);
  };

  const handleAddOption = () => {
    const lastVariant = product_variants[product_variants.length - 1];
    const newVariant = { ...lastVariant, id: 0 };
    onChangeField('product_variants', [...product_variants, newVariant]);
    setFileList([...fileList, []]);
  };

  const handleDeleteOption = (index) => {
    if (index === 0) return; // Prevent deleting the first variant
    const updatedVariants = product_variants.filter((_, i) => i !== index);
    onChangeField('product_variants', updatedVariants);
    const updatedFileList = [...fileList];
    updatedFileList.splice(index, 1); // Remove the file list for the deleted variant
    setFileList(updatedFileList);
  };

  return (
   
      <div className="card mb-4">
          <div className="card-header">
            <h5 className="card-title mb-0">Variants</h5>
          </div>

      {product_variants.map((form, index) => (
          <div className="card-body" key={index}>
            <div className="row mb-3">
              <div className="col">
                <label className="form-label" >Variants Name</label>
                <input
                  type="text"
                  className="form-control"
                  id={`variants-name-${index}`}
                  placeholder="Variants Name"
                  name="variant_name"
                  aria-label="Variants Name"
                  value={form.variant_name}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
              <div className="col">
                <label className="form-label">Variants Value</label>
                <input
                  type="text"
                  className="form-control"
                  id={`variants-value-${index}`}
                  placeholder="Variants Value"
                  name="variant_value"
                  aria-label="Variants Value"
                  value={form.variant_value}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label className="form-label" >Variants Qty</label>
                <input
                  type="number"
                  className="form-control"
                  id={`variants-qty-${index}`}
                  placeholder="Variants Qty"
                  name="variant_qty"
                  aria-label="Variants Qty"
                  value={form.variant_qty}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
              <div className="col">
                <label className="form-label" >Variants Stock</label>
                <input
                  type="number"
                  className="form-control"
                  id={`variants-stock-${index}`}
                  placeholder="Variants Stock"
                  name="variant_stock"
                  aria-label="Variants Stock"
                  value={form.variant_stock}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label className="form-label">Display Price</label>
                <input
                  type="number"
                  step="any"
                  className="form-control"
                  id={`display-price-${index}`}
                  placeholder="Display Price"
                  name="variant_price"
                  aria-label="Display Price"
                  value={form.variant_price}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
              <div className="col">
                <label className="form-label">Discounted Price</label>
                <input
                  type="number"
                  step="any"
                  className="form-control"
                  id={`discounted-price-${index}`}
                  placeholder="Discounted Price"
                  name="variant_discounted_price"
                  aria-label="Discounted Price"
                  value={form.variant_discounted_price}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label className="form-label">Supplier Price</label>
                <input
                  type="number"
                  className="form-control"
                  id={`supplier-price-${index}`}
                  placeholder="Supplier Price"
                  name="varaint_supplier_price"
                  aria-label="Supplier Price"
                  value={form.varaint_supplier_price}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
              <div className="col">
                <label className="form-label">Rack ID</label>
                <input
                  type="text"
                  className="form-control"
                  id={`rack-id-${index}`}
                  placeholder="Rack ID"
                  name="variant_rackid"
                  aria-label="Rack ID"
                  value={form.variant_rackid}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <label className="form-label">Barcode</label>
                <input
                  type="text"
                  className="form-control"
                  id={`variants-barcode-${index}`}
                  placeholder="Barcode"
                  name="variant_barcode"
                  aria-label="Barcode"
                  value={form.variant_barcode}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
              <div className="col">
                <label className="form-label">Max Order Qty</label>
                <input
                  type="number"
                  className="form-control"
                  id={`max-order-qty-${index}`}
                  placeholder="Max Order Qty"
                  name="qty_per_user"
                  aria-label="Max Order Qty"
                  value={form.qty_per_user}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
			  <div className="col">
                <label className="form-label">Total Weigth(kg)</label>
                <input
                  type="number"
                  className="form-control"
                  id={`varaint_total_weight-${index}`}
                  placeholder="Total Weigth(kg)"
                  name="varaint_total_weight"
                  aria-label="Total Weigth(kg)"
                  value={form.varaint_total_weight}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
			  <div className="col">
                <label className="form-label">Length(Inch)</label>
                <input
                  type="number"
                  className="form-control"
                  id={`varaint_length-${index}`}
                  placeholder="Length(Inch)"
                  name="varaint_length"
                  aria-label="Length(Inch)"
                  value={form.varaint_length}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
			  <div className="col">
                <label className="form-label">Width(Inch)</label>
                <input
                  type="number"
                  className="form-control"
                  id={`varaint_width-${index}`}
                  placeholder="Width(Inch)"
                  name="varaint_width"
                  aria-label="Width(Inch)"
                  value={form.varaint_width}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
			  <div className="col">
                <label className="form-label">Height(Inch)</label>
                <input
                  type="number"
                  className="form-control"
                  id={`varaint_height-${index}`}
                  placeholder="Height(Inch)"
                  name="varaint_height"
                  aria-label="Height(Inch)"
                  value={form.varaint_height}
                  onChange={(e) => handleFieldChange(e, index)}
                />
              </div>
			  
            </div>

          <div className="row mb-3">
            <div className="col">
              <div className="card-body d-flex flex-column justify-content-center align-items-center">
                <Upload
                  action="/upload"
                  className="dropzone needsclick"
                  id={`dropzone-basic-${index}`}
                  fileList={fileList[index] || []}
                  onChange={(info) => handleChange(info, index)}
                  beforeUpload={() => false}
                  accept="image/*"
                  maxCount={1}
                >
                  <div className="dz-message needsclick text-center">
                    <p className="fs-4 note needsclick mb-1">Drag and drop your variants images here</p>
                    <p className="text-muted mb-2">or</p>
                    <UploadOutlined className="note needsclick btn bg-label-primary" id={`btnBrowse-${index}`} style={{ fontSize: '36px' }} />
                  </div>
                </Upload>
              </div>
            </div>
          </div>

            <div className="row mb-3">
              <div className="col">
                <div className="d-flex justify-content-between align-items-center border-bottom pt-3">
                  <label className="form-label">InStock / Out of Stock</label>
                  <Switch checked={form.variant_status == 1} onChange={(checked) => handleSwitchChange(checked, index)} title='variant_status' />
                </div>
              </div>
            </div>

            {/* Delete button for each form */}
            {index !== 0 && (
            <div className="d-flex justify-content-between align-items-end">
              <div></div>
              <div>
              <DeleteOutlined style={{ color: 'red', fontSize: '30px' }} onClick={() => handleDeleteOption(index)} />
          </div>
  </div>
)}
          </div>
      ))}

          <div  style={{marginBottom:'10px',marginLeft:'10px'}}>
          <button className="btn btn-primary" onClick={handleAddOption}>
            Add another option
          </button>
          </div>
       </div>

  );
}

export default ProductVariants;