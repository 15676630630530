class DashboardService {
    getDashboardData(axios, store) {
        let body = {
            query: `
                query {
                    dashboardData(
                        store_id: ${store.store_id}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
}

let dashboardService = new DashboardService()
export {
    dashboardService
}