import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie'
import { CommonContext } from "./CommonContext";
import { userService } from "../Services/UserService";
import { useLocation } from "react-router-dom";

function getPermissionsArray(permissionsStr) {
    permissionsStr = permissionsStr.replace("[", "").replace("]", "").replaceAll("\"", "")
    let permissionsArray = permissionsStr.split(",")
    return permissionsArray
}

const INIT_STATE = {
    access_token: localStorage.getItem("access_token"),
    permissions:  localStorage.getItem("permissions") ? getPermissionsArray(window.atob(localStorage.getItem("permissions"))) : [],
    role: localStorage.getItem("role"),
    constants: {}
}

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {

    const [auth, setAuth] = useState(INIT_STATE)
    const [userInfo, setUserInfo] = useState({})
    const {axiosPrivate} = useContext(CommonContext)
    
    const location = useLocation()
    
    function getUserCookie(name) {
        return Cookies.get(name)
    }

    function setUserCookies(name, value) {
        Cookies.set(name, value)
    }

    function removeUserCookie(name) {
        Cookies.remove(name)
    }

    function setLocalStorage(details) {
        localStorage.setItem("access_token", details.token)
        localStorage.setItem("role", details.user_details.account_type)
        localStorage.setItem("permissions", window.btoa(details.user_details.role_permissions))
        setAuth({...auth, access_token: details.token, role: details.user_details.account_type, permissions: getPermissionsArray(details.user_details.role_permissions), constants: details.constants})
        let deliveryCitiesObjArray = []
        if(details.merchant_delivery_city) {
            for(let i=0;i<details.merchant_delivery_city.length;i++) {
                let obj = {
                    id: i + 1,
                    area_name: details.merchant_delivery_city[i]
                }
                deliveryCitiesObjArray.push(obj)
            }
        }
        setUserInfo({...details.user_details, merchant_business_type: details.merchant_business_type, merchant_delivery_city: [...deliveryCitiesObjArray]})
    }

    function removeLocalStorage() {
        localStorage.removeItem("access_token")
        localStorage.removeItem("role")
        localStorage.removeItem("permissions")
    }

    useEffect(() => {
        if(location.pathname != "/" && location.pathname != "/login" && location.pathname != "/register") {
            if(auth.access_token) {
                userService.getUserDetailsByToken(axiosPrivate).then(res => {
                    let data = {
                        user_details: {...res.data.userDetailsByToken.user_details}, 
                        token: auth.access_token,
                        constants: res.data.userDetailsByToken.constants,
                        merchant_business_type: res.data.userDetailsByToken.merchant_business_type, 
                        merchant_delivery_city: res.data.userDetailsByToken.merchant_delivery_city
                    }
                    setLocalStorage(data)
                    // setUserInfo({...res.data.merchantDetailsByToken})
                }).catch(err => {
                    if(err.response && err.response.status === 400) {
                        removeLocalStorage()
                        window.location.href = "/login"
                    }
                })
            }else {
                window.location.href = "/login"
            }
        }else {
            if(auth.access_token) {
                let url = ""
                if(auth.role) {
                    url = `/dashboard`
                }else {
                    removeLocalStorage()
                    url = "/login"
                }
                window.location.href = url
            }
        }
    }, [])

    return (
        <AuthContext.Provider value={{auth, setAuth, setLocalStorage, removeLocalStorage, getUserCookie, setUserCookies, removeUserCookie, userInfo}}>
            {children}
        </AuthContext.Provider>
    )
}

// export default AuthContext