import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { CommonContext } from "../context/CommonContext"
import useAuth from "../hooks/useAuth"
import { userService } from "../Services/UserService"
import { ADMIN_PREFIX, MERCHANT_PREFIX, OTP_LENGTH, TOAST_MSG_TYPE } from "../utils/Constants"
import OTPInput from "./OTPInput"
import Timer from "./Timer"

const OTPForm = (props) => {
    const { setUserCookies, setLocalStorage } = useAuth()
    const { showToast, showPromiseToast, axiosPublic } = useContext(CommonContext)
    const [OTP, setOTP] = useState("")

    let updateOTPValue = (otpValue) => {
        setOTP(otpValue)
    }

    const setTimeOverFlag = () => {
        props.updateRemainingTime(0)
    }

    const resendOTP = (e) => {
        e.preventDefault()
        if(props.remainingTime.timing == 0 && props.formState.userID > 0) {
            const servicePromise = userService.resendOTP(axiosPublic, {id: props.formState.userID})
            showPromiseToast(servicePromise, "Sending OTP...")
            servicePromise.then(res => {
                if(res.data.merchantResendOTP) {
                    showToast("OTP Sent", TOAST_MSG_TYPE.SUCCESS)
                    props.updateRemainingTime(30)
                }else {
                    showToast("Something Wrong.", TOAST_MSG_TYPE.ERROR)
                }
            })
        }else {
            showToast("Can not resend until time lapse", TOAST_MSG_TYPE.ERROR)
        }
    }

    const verifyOTP = (e) => {
        e.preventDefault()
        if(OTP === "" || OTP.length < OTP_LENGTH) {
            showToast("Please Enter Correct OTP Before Submit", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(props.formState.userID <= 0) {
            showToast("Please refresh page.", TOAST_MSG_TYPE.ERROR)
            return
        }
        let servicePromise = userService.verifyOTP(axiosPublic, {id: props.formState.userID, otp: OTP})
        showPromiseToast(servicePromise, "Verifying OTP. Please wait")
        servicePromise.then(res => {
            let data = res.data.merchantVerifyOtp
            setLocalStorage(data)
            if(data.merchantDetails.role_title) {
                window.location.href = `/dashboard`
                return
            }else {
                showToast("Role Not Defined", TOAST_MSG_TYPE.ERROR)
                return
            }
        })
    }

    return (
        <form>
            <div className="form-group">
                <i className="la la-arrow-left" style={{marginRight: "10px", cursor: "pointer"}} onClick={(e) => props.backPressed(e)}/>
                <label>Enter OTP</label>
                <div className="row">
                    <OTPInput otpLength={OTP_LENGTH} updateOTPValue={updateOTPValue}/>
                </div>
            </div>
            <div className="form-group">
                <div className="row">
                    <div className="col-auto">
                        <a className="text-muted" href="#" onClick={(e) => resendOTP(e)}>
                        Resend OTP? (<Timer testRemainingTime={props.remainingTime} setTimeOver={setTimeOverFlag}/>)
                        </a>
                    </div>
                </div>
            </div>
            <div className="form-group text-center">
                <button className="btn btn-primary account-btn" onClick={(e) => verifyOTP(e)}>
                Submit OTP</button>
            </div>
        </form>
    )
}

export default OTPForm