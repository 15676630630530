import { useEffect, useReducer } from "react"
import { storeService } from "../Services/StoreService"
import { ACTIONS } from "../utils/Constants"

function storeListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, storeData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}

function useStoreList(axios, params) {
    const [state, dispatch] = useReducer(storeListReducer, {loading: false, storeData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        storeService.getStoresList(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.merchantStoreListing.list, total: res.data.merchantStoreListing.total}})
        })
    }, [params])
    return state
}

function useStoreListing(axios, params) {
    const [state, dispatch] = useReducer(storeListReducer, {loading: false, storeData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        storeService.getStoresList(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.merchantStoreListing.list, total: res.data.merchantStoreListing.total}})
        })
    }, [params])
    return state
}

export {
    useStoreList,
    useStoreListing
}