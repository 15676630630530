import { Table } from "antd"
import { useAdminCateogryList } from "../../hooks/CategoryHooks"
import { useContext, useMemo, useState } from "react"
import { CommonContext } from "../../context/CommonContext"
import useAuth from "../../hooks/useAuth"
import { itemRender } from "../paginationfunction"
import { CATEGORY_PREFIX, TOAST_MSG_TYPE, getConstantData } from "../../utils/Constants"
import { Link } from "react-router-dom"
import { categoryService } from "../../Services/CategoryService"

const AdminCategoryTable = (props) => {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [params, setParams] = useState({paginated: true, counter: 0, limit: 10, offset: 0, search: "", field: "category_title", sort: "ASC"})
    const {categoryData, loading, error, total} = useAdminCateogryList(axiosPrivate, params)
    const {auth} = useAuth()
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Category',
                dataIndex: 'category_title',
                sorter: true
            },
            {
                title: 'Slug',
                dataIndex: 'category_slug'
            },
            {
                title: 'Category Type',
                dataIndex: 'category_type',
                render: function(text, record) {
                    return `${getCategoryTypeString(text)}`
                }
            },
            {
                title: 'Display Order',
                dataIndex: 'category_display_order'
            },
            {
                title: 'Reward Points',
                dataIndex: 'category_reward_points'
            }
        ]
        if(auth.permissions.includes("InActiveCategory")) {
            columns.push(
                {
                    title: 'Status',
                    render: (text, record) => (
                        <div className="dropdown action-label text-center">
                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">{`${getCategoryStatusString(record.category_status)}`}</a>
                            <div className="dropdown-menu dropdown-menu-right">
                            {
                                getConstantData("STATUS", auth.constants).map(status => {
                                    return <a key={`${record.id}_${status.id}`} className="dropdown-item" href="#" onClick={(e) => changeCategoryStatus(e, record, status.id)}>{status.value}</a>
                                })
                            }
                            </div>
                        </div>
                    )
                        
                }
            )
        }
        if(auth.permissions.includes("EditCategory")) {
            columns.push(
                {
                    title: 'Action',
                    render: (text, record) => (
                        <div className="dropdown dropdown-action text-right">
                            <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <Link className="dropdown-item" to={`${CATEGORY_PREFIX}/edit/${record.id}`} data-toggle="modal" data-target="#edit_leave"><i className="fa fa-pencil m-r-5" /> Edit</Link>
                            </div>
                        </div>
                    ),
                }
            )
        }
        return columns
    }, [auth.permissions, auth.constants])

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const getCategoryTypeString = (type) => {
        var typeObj = {}
        if(auth.constants.hasOwnProperty("BUSINESS_TYPE")) {
            typeObj = auth.constants["BUSINESS_TYPE"].find((s) => s.id == type)
        }
        return typeObj.hasOwnProperty("value") ? typeObj.value : ""
    }

    const getCategoryStatusString = (status) => {
        var statusObj = {}
        if(auth.constants.hasOwnProperty("STATUS")) {
            statusObj = auth.constants["STATUS"].find((s) => s.id == status)
        }
        return statusObj && statusObj.hasOwnProperty("value") ? statusObj.value : ""
    }

    const changeCategoryStatus = (e, record, objKey) => {
        const param = {
            id: record.id,
            status: objKey
        }
        const apiPromise = categoryService.updateCategoryStatus(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.changeCategoryStatus) {
                showToast("category Status Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
            let newCounter = params.counter + 1
            setParams((prev) => {
                return {
                    ...prev,
                    counter: newCounter
                }
            })
        })
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }
	const customerSEarchData = (e) => {
        e.preventDefault()
		let param={}
        const apiPromise = categoryService.generateMainAndFeaturedCatories(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.generateMainAndFeaturedCatories) {
                showToast("App Home Page Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
        })
    }

    return (
        <>
            <div className="card-header">
                <div className='row filter-row'>
                    <div className='col-md-3'>
                        <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                    </div>
					<div className="col-md-6 ">                                    
						<a className="btn btn-danger" href="#" onClick={(e) => customerSEarchData(e)}>Regenrate APP home page static data</a> 
					</div>
                </div>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                <Table
                    pagination= { {total : total,pageSize: 10, 
                        showSizeChanger : false,itemRender : itemRender } }
                    style = {{overflowX : 'auto'}}
                    columns={render}
                    loading={loading}               
                    bordered
                    dataSource={categoryData}
                    rowKey={record => record.id}
                    onChange={handleTableChange}
                />
                </div>
            </div>
        </>
        
    )
}
export default AdminCategoryTable