import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Select from 'react-select';
import { Select as AntSelect } from 'antd';
import { CommonContext } from '../../context/CommonContext';
import { STORE_PREFIX, STORE_TYPE, TOAST_MSG_TYPE, getConstantData } from '../../utils/Constants';
import { storeService } from '../../Services/StoreService';
import { Link, useNavigate } from 'react-router-dom';
import createSlug from '../../utils/createSlug';
import countryCodes from '../../utils/countryCodes';
import { useCityList } from '../../hooks/RouteHooks';
import useAuth from '../../hooks/useAuth';

const initialState = {store_id: 0, store_name: "", store_slug: "", phone: "", gst_number:"", address1: "", origin_zipcode: "", country_name: undefined, store_city: undefined, store_type: undefined, store_delivery_city: []}

export default function StoreForm(props) {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    let { auth, userInfo } = useAuth()
    console.log(userInfo)
    const [queryParam, setQueryParam] = useState({paginated: false, limit: 10, offset: 0, search: "", field: "area_name", sort: "ASC"})
    const {citiesData, loading, error, total} = useCityList(axiosPrivate, queryParam) 
    const [formState, setFormState] = useState({ ...initialState })
    const [showLoader, setShowLoader] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        if (props.store_id && props.store_id > 0 && citiesData.length) {
            storeService.getStoreByID(axiosPrivate, props.store_id).then(res => {
                if(res.data.merchantStoreDetailsByid && res.data.merchantStoreDetailsByid.merchantStoreDetails) {
                    setShowLoader(false)
                    let data = res.data.merchantStoreDetailsByid.merchantStoreDetails
                    setFormState({
                        ...formState,
                        store_id: data.store_id,
                        store_name: data.store_name,
                        store_slug: data.store_slug,
                        phone: data.phone,
                        gst_number:data.other_details.gst_number || "", 
                        address1: data.store_address.address1, 
                        origin_zipcode: data.store_address.origin_zipcode, 
                        country_name: countryCodes.find(c => c.name == data.store_address.country), 
                        store_city: citiesData.find(c => c.area_name == data.store_address.store_city), 
                        store_delivery_city: data.store_delivery_city,
                        store_type: data.store_type
                    })
                }else {
                    navigate(`${STORE_PREFIX}`)
                }
                
            })
        } else {
            setFormState({ ...initialState })
            setShowLoader(false)
        }
    }, [props.store_id, citiesData])

    useEffect(() => {
        if (props.crud === "read") {
            setDisabled(true)
        } else {
            setDisabled(false)
        }

    }, [props.crud])

    const onBusinessTypeChange = (e) => {
        setFormState({ ...formState, store_type: e })
    }

    const onChangeCountry = (e) => {
        setFormState({ ...formState, country_name: e })
    }

    const onChangeStoreCity = (e) => {
        setFormState({ ...formState, store_city: e })
    }

    const onChangeDelivrableCity = (e) => {
        setFormState({...formState, store_delivery_city: [...e]})
    }

    const onChangeField = (e, slugSource = false) => {
        const name = e.target.name
        const value = e.target.value
        let slugObj = {}
        if (slugSource) {
            slugObj = { store_slug: createSlug(value) }
        }
        setFormState({ ...formState, [name]: value, ...slugObj })
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        if (!formState.store_name) {
            showToast("Please Fill Store Name", TOAST_MSG_TYPE.ERROR)
            return
        }
        if (!formState.store_type) {
            showToast("Please Select Store Type", TOAST_MSG_TYPE.ERROR)
            return
        }
        if (!formState.phone) {
            showToast("Please Fill Store Phone Number", TOAST_MSG_TYPE.ERROR)
            return
        }
        if (!formState.country_name) {
            showToast("Please Choose Store Country", TOAST_MSG_TYPE.ERROR)
            return
        }
        if (!formState.origin_zipcode) {
            showToast("Please Fill Zipcode/Pincode", TOAST_MSG_TYPE.ERROR)
            return
        }
        if (!formState.address1) {
            showToast("Please Fill Store Address", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.store_city) {
            showToast("Please Select Store City", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.store_delivery_city.length) {
            //showToast("Please Select Delivery cities", TOAST_MSG_TYPE.ERROR)
            //return
        }
        if(!formState.gst_number) {
            showToast("Please Fill GST Number", TOAST_MSG_TYPE.ERROR)
            return
        }
        // let params = { ...formState, merchant_id: userInfo.id }
        let params = {
            store_id: formState.store_id,
            phone: formState.phone,
            country_phone_code: formState.country_name.dial_code,
            store_name: formState.store_name,
            store_slug: formState.store_slug,
            store_type: formState.store_type,
            store_address: {
                address1: formState.address1,
                store_city: formState.store_city.area_name,
                origin_zipcode: formState.origin_zipcode,
                country: formState.country_name.name 
            },
            other_details: {
                gst_number: formState.gst_number
            },
            store_delivery_city: formState.store_delivery_city
        }
        const apiPromise = storeService.addStore(axiosPrivate, params)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if (res.data.createUpdateMerchantStore) {
                showToast(props.crud === "create" ? "Store Added Successfully!" : "Store Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({ ...initialState })
                window.location.href = `${STORE_PREFIX}`
                // navigate(`${STORE_PREFIX}`)
            }
        })
    }

    const onCancel = (e) => {
        e.preventDefault()
        navigate(-1)
    }

    return (
        <>
            {
                showLoader ? <p>Loading...</p> :
                    <form action="#">
                        <div className='row'>
                            <div className='col-md-6'>
                                <h4 className="mb-2 mt-2">Store Information</h4>
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col-md-6'>
                                <label>Store Type<span className="text-danger">*</span></label>
                                <AntSelect 
                                    className='select col-md-12'
                                    value={formState.store_type}
                                    options={userInfo.account_type == 1 ? getConstantData("BUSINESS_TYPE", auth.constants) : userInfo.merchant_business_type}
                                    onChange={onBusinessTypeChange}
                                    optionFilterProp={"value"}
                                    fieldNames={{label: "value", value: "id"}}
                                    isDisabled={disabled}
                                />
                            </div>
                            <div className='col-md-6'>
                                <label>Legal Store Name<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" placeholder='' name="store_name" value={formState.store_name} onChange={(e) => onChangeField(e, true)} disabled={disabled} />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-md-12'>
                                <label>Store Deliverable Cities</label>
                                <AntSelect
                                    className="select col-md-12"
                                    value={formState.store_delivery_city}
                                    mode='multiple'
                                    options={userInfo.account_type == 1 ? citiesData : userInfo.merchant_delivery_city}
                                    loading={loading}
                                    loadingMessage={"Loading Cities"}
                                    name="store_delivery_city"
                                    onChange={onChangeDelivrableCity}
                                    isDisabled={disabled}
                                    optionFilterProp={"area_name"}
                                    fieldNames={{label: "area_name", value: "area_name"}}
                                >
                                </AntSelect>
                                {/* <input type="text" className="form-control" placeholder='' name='currency' value={formState.currency} onChange={(e) => onChangeField(e)} disabled={disabled}/> */}
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h4 className="mb-2 mt-2">Store Contact Details</h4>
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col-md-6'>
                                <label>Store Phone Number<span className="text-danger">*</span></label>
                                <input type="tel" className="form-control" placeholder='' name='phone' value={formState.phone} onChange={(e) => onChangeField(e)} disabled={disabled} />
                            </div>
                            <div className='col-md-6'>
                                <label>Store Address<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" placeholder='' name='address1' value={formState.address1} onChange={(e) => onChangeField(e)} disabled={disabled} />
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className='col-md-6'>
                                <label>Store City<span className="text-danger">*</span></label>
                                <Select
                                    className="select"
                                    value={formState.store_city}
                                    options={citiesData}
                                    isLoading={loading}
                                    loadingMessage={"Loading Cities"}
                                    name="store_city"
                                    onChange={(e) => onChangeStoreCity(e)}
                                    isDisabled={disabled}
                                    getOptionValue={(option) => `${option.id}`}
                                    getOptionLabel={(option) => `${option.area_name}`}
                                >
                                </Select>
                                {/* <input type="text" className="form-control" placeholder='' name='currency' value={formState.currency} onChange={(e) => onChangeField(e)} disabled={disabled}/> */}
                            </div>
                            <div className='col-md-6'>
                                <label>Zipcode/Pincode<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" placeholder='' name='origin_zipcode' value={formState.origin_zipcode} onChange={(e) => onChangeField(e)} disabled={disabled} />
                            </div>
                            <div className='col-md-6'>
                                <label>Store Country<span className="text-danger">*</span></label>
                                <Select
                                    className="select"
                                    value={formState.country_name}
                                    options={countryCodes}
                                    name="country_name"
                                    onChange={(e) => onChangeCountry(e)}
                                    isDisabled={disabled}
                                    getOptionValue={(option) => `${option.name}`}
                                    getOptionLabel={(option) => `${option.name} (${option.code})`}
                                >
                                </Select>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h4 className="mb-2 mt-2">Other Information</h4>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className='col-md-6'>
                                <label>GST Number<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" placeholder='' name="gst_number" value={formState.gst_number} onChange={(e) => onChangeField(e)} disabled={disabled} />
                            </div>
                            <div className='col-md-6'>
                                <label>Store Slug<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" placeholder='' name="store_slug" value={formState.store_slug} onChange={(e) => onChangeField(e)} disabled={disabled} />
                            </div>
                        </div>

                        <div className='row form-group'>
                            <div className='col-md-12'>
                                {(() => {
                                    if (props.crud === "create") {
                                        return (
                                            <a href="#" className="btn btn-primary btn-width mx-auto d-block" onClick={(e) => onSubmitHandler(e)}>Save</a>
                                        )
                                    } else if (props.crud === "read") {
                                        return (
                                            <>
                                                <Link to={`${STORE_PREFIX}/edit/${props.store_id}`} className="btn btn-primary btn-width mx-auto d-block">Edit</Link>
                                                <Link to={`${STORE_PREFIX}`} className="btn btn-primary btn-width mx-auto d-block">Back</Link>
                                            </>
                                        )
                                    } else if (props.crud === "edit") {
                                        return (
                                            <>
                                                <div className='mx-auto d-block btn-width mt-3'>
                                                    <a href="#" className="btn btn-primary btn-width" onClick={(e) => onSubmitHandler(e)}>Save</a>
                                                    <Link to={`${STORE_PREFIX}`} className="btn btn-primary mx-3 btn-width">Back</Link>
                                                    <a href="#" className="btn btn-primary btn-width" onClick={(e) => onCancel(e)}>Cancel</a>
                                                </div>
                                            </>
                                        )
                                    }
                                })()}
                            </div>
                        </div>
                    </form>
            }
        </>
    )
}
