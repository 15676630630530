import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { BANNER_IMG, BANNER_PREFIX, MERCHANT_PREFIX, TOAST_MSG_TYPE, getConstantData } from '../utils/Constants';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { userService } from '../Services/UserService';
import { useBannerList } from '../hooks/BannerHooks';
import { bannerService } from '../Services/BannerService';

const BannerList = () => {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
    const [params, setParams] = useState({paginated: true, counter: 0, limit: 10, offset: 0, search: "", field: "banner_title", sort: "ASC"})
    const {bannerData, loading, error, total} = useBannerList(axiosPrivate, params)
    
    
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Banner Image',
                dataIndex: 'banner_image',
                width: 50,
                maxWidth: 50,
                className: 'user-icon',
                render: (t, r) => <img src={`${BANNER_IMG}/${t}`} />
            },
            {
                title: 'Banner Title',
                dataIndex: 'banner_title',
                sorter: true
            },
            {
                title: 'Display Order',
                dataIndex: 'banner_display_order',
                sorter: true
            },
            {
                title: 'Link Type',
                dataIndex: 'banner_link_type'
            },
            {
                title: 'Link Data',
                dataIndex: 'banner_link_data'
            },
            {
                title: 'Banner Type',
                dataIndex: 'banner_type'
            },
            {
                title: 'Store',
                dataIndex: 'store_id'
            },
        ]
        if(auth.permissions.includes("InActiveBanner")) {
            columns.push(
                {
                    title: 'Status',
                    render: (text, record) => (
                        <div className="dropdown action-label text-center">
                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">{`${getBannerStatusString(record.banner_status)}`}</a>
                            <div className="dropdown-menu dropdown-menu-right">
                            {
                                getConstantData("STATUS", auth.constants).map(status => {
                                    return <a key={`${record.id}_${status.id}`} className="dropdown-item" href="#" onClick={(e) => changeBannerStatus(e, record, status.id)}>{status.value}</a>
                                })
                            }
                            </div>
                        </div>
                    )
                        
                }
            )
        }
        if(auth.permissions.includes("EditBanner")) {
            columns.push(
                {
                    title: 'Action',
                    render: (text, record) => (
                        <div className="dropdown dropdown-action text-right">
                            <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <Link className="dropdown-item" to={`${BANNER_PREFIX}/edit/${record.id}`} data-toggle="modal" data-target="#edit_leave"><i className="fa fa-pencil m-r-5" /> Edit</Link>
                            </div>
                        </div>
                    ),
                }
            )
        }
        return columns
    }, [auth.permissions])

    const getBannerStatusString = (status) => {
        var statusObj = {}
        if(auth.constants.hasOwnProperty("STATUS")) {
            statusObj = auth.constants["STATUS"].find((s) => s.id == status)
        }
        return statusObj.hasOwnProperty("value") ? statusObj.value : ""
    }

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const changeBannerStatus = (e, record, objKey) => {
        const param = {
            id: record.id,
            status: objKey
        }
        const apiPromise = bannerService.changeBannerStatus(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.changeBannerStatus) {
                showToast("Banner Status Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
            let newCounter = params.counter + 1
            setParams((prev) => {
                return {
                    ...prev,
                    counter: newCounter
                }
            })
        })
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Banners</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Manage Banners</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Banners</li>
                            </ul>
                        </div>
                        {
                            auth.permissions.includes("AddBanner") ?
                            <div className="col-sm-6">
                                <Link to={`${BANNER_PREFIX}/create`} className="btn btn-primary btn-width float-right d-block">Add New Banner</Link>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 10, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={bannerData}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default BannerList;