import React,{useState,useEffect,useContext} from 'react'
import { Switch} from 'antd';
import Select from 'react-select';
import 'antd/dist/antd.css';
import { CommonContext } from '../../context/CommonContext';
import { productService } from '../../Services/ProductService';
// import { TagsInput } from "react-tag-input-component";
import CreatableSelect from 'react-select/creatable'
import { useMenusList } from '../../hooks/ProductHooks';


function Organize({ formState, onChangeField ,storeID}) {
  const {axiosPrivate } = useContext(CommonContext);
  const [storeNameList,setStoreNameList] = useState([])
  const status = [{id:0,status_code:'Draft'},{id:1,status_code:'Published'}]
  const[Options,setOptions] = useState([]);
  const [params, setParams] = useState({store: storeID})
  const {menuData, loading} = useMenusList(axiosPrivate, params)
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [optionsBrand, setOptionsBrand] = useState([]);

  const [suplierMenuIsOpen, setSuplierMenuIsOpen] = useState(false);
  const [optionsSuplier, setOptionsSuplier] = useState([]);
  const [menuIsOpenRelatedproduc, setMenuIsOpenRelatedProduct] = useState(false);
  const [optionsRelatedProduct, setOptionsrelatedProduct] = useState([]);

  const [menuIsOpenTag, setMenuIsOpenTag] = useState(false);
  const [optionsTags, setOptionsTags] = useState([]);


  const handleInputChangeTag = (inputValue) => {
    setMenuIsOpenTag(!!inputValue);
    if (inputValue) {
        productService.searchProductTag(axiosPrivate, inputValue)
            .then((res) => {
                const brandList = res.searchProductTags;
                const newOptions = brandList?.map(Tags => ({
                    value: Tags.name,
                    label: Tags.name
                }));
                // Filter out duplicates and merge with existing options
                const uniqueOptions = Array.from(new Set([...optionsTags, ...newOptions]));
                setOptionsTags(uniqueOptions);
            })
            .catch(error => {
                console.error('Error searching for brands:', error);
         });
    }
};

const handleCreateTag = (inputValue) => {
  // Check if the option already exists
  const optionExists = optionsTags.some(option => option.label.toLowerCase() === inputValue.toLowerCase());
  if (!optionExists) {
    const newOption = { value: inputValue, label: inputValue };
    setOptionsTags([...optionsTags, newOption]);
    const updatedTags = [...formState.product_tags, inputValue];
    onChangeField('product_tags', updatedTags);
  }
};

  const handleInputChangeBrand = (inputValue) => {
    setMenuIsOpen(!!inputValue);
    if (inputValue) {
        productService.searchProductBrandList(axiosPrivate, inputValue)
            .then((res) => {
                const brandList = res.searchProductBrands;
                const newOptions = brandList?.map(brand => ({
                    value: brand.name,
                    label: brand.name
                }));
                // Filter out duplicates and merge with existing options
                const uniqueOptions = Array.from(new Set([...optionsBrand, ...newOptions]));
                setOptionsBrand(uniqueOptions);
            })
            .catch(error => {
                console.error('Error searching for brands:', error);
         });
    }
};

  const handleCreateBrand = (inputValue) => {
    // Check if the option already exists
    const optionExists = optionsBrand.some(option => option.label.toLowerCase() === inputValue.toLowerCase());
    if (!optionExists) {
      const newOption = { value: inputValue, label: inputValue };
      setOptionsBrand([...optionsBrand, newOption]);
      onChangeField('product_brand', inputValue);
    }
  };

  const handleInputChangeSuplier = (inputValue) => {
    setSuplierMenuIsOpen(!!inputValue);
    if (inputValue) {
        productService.searchProductSupplierList(axiosPrivate, inputValue)
            .then((res) => {
                const suplierList = res.searchProductSuppiers;
                const newOptions = suplierList?.map(brand => ({
                    value: brand.name,
                    label: brand.name
                }));
                // Filter out duplicates and merge with existing options
                const uniqueOptions = [...optionsSuplier, ...newOptions.filter(newOpt => !optionsSuplier.some(opt => opt.value === newOpt.value))];
                setOptionsSuplier(uniqueOptions);
            })
            .catch(error => {
                console.error('Error searching for brands:', error);
         });
    }
};

const handleCreateSuplier = (inputValue) => {
  // Check if the option already exists
  const optionExists = optionsSuplier.some(option => option.label.toLowerCase() === inputValue.toLowerCase());
  if (!optionExists) {
    const newOption = { value: inputValue, label: inputValue };
    setOptionsSuplier([...optionsSuplier, newOption]);
    onChangeField('product_supplier', inputValue);
  }
};

const handleInputChangeRelatedProduct = (inputValue) => {
  setMenuIsOpenRelatedProduct(!!inputValue);
  if (inputValue) {
	  console.log(formState)
    productService.searchRelatedProduct(axiosPrivate, inputValue,formState.store_id[0])
      .then((res) => {
        const relatedProductList = res?.searchproductsListingAdmin;
        const newOptions = relatedProductList?.map(product => ({
          value: product.product_sku,
          label: product.product_title+"-"+product.product_brand
        }));
        const uniqueOptions = [...optionsRelatedProduct, ...newOptions.filter(newOpt => !optionsRelatedProduct.some(opt => opt.value === newOpt.value))];
        setOptionsrelatedProduct(uniqueOptions);
      })
      .catch(error => {
        console.error('Error searching for related products:', error);
      });
  }
};


    useEffect(()=>{
      productService.productStoreNameList(axiosPrivate).then((res)=>{
        const storeList = res.data?.merchantStoreListing?.list;
        setStoreNameList(storeList)
      })
    },[])

    useEffect(()=>{
      if(storeID!=null && menuData.length){
          const categoryList = menuData;
          const options = categoryList?.map(category => ({
            label: category.main_cat_title,
            options: category.subcats.map(subcat => ({
              value: parseInt(subcat.menu_item_id), 
              label: subcat.child_cat_title
            }))
          }));
          setOptions(options);
      }
    },[menuData])

    const handleSwitchChange = (checked) => {
      onChangeField('product_in_stock',checked)
    };

    const onChangeStore = (selectedOption) => {
	  var storeArray=[]
	  storeArray.push(selectedOption.store_id)
	  setParams({store:storeArray})
	  var store_names=[]
	  store_names.push(selectedOption)
      onChangeField('store_name', store_names);
      onChangeField('store_id', storeArray);
    };

    const onChangeStatus = (selectedOption) => {
      onChangeField('status', selectedOption);
      onChangeField('product_status', selectedOption.id);
    };

    const handleFieldChange = (e) => {
      const { name, value, type, checked } = e.target;
      let fieldValue;
      switch (type) {
          case 'checkbox':
              fieldValue = checked ? 1 : 0;
              break;
          case 'number':
              const isValidNumber = /^[+]?\d*\.?\d+$/.test(value);
              fieldValue = isValidNumber || value === '' ? value : formState[name];
              break;
          default:
              fieldValue = value;
              break;
      }
  
      // Update the form state
      onChangeField(name, fieldValue);
    }

    const handleSelectChange = (selectedValues) => {
      onChangeField('product_categories',selectedValues.map((item) => item.value));
    };

    const flattenOptions = (options) => {
      return options.reduce((acc, option) => {
        acc.push(option);
        if (option.options) {
          acc.push(...flattenOptions(option.options));
        }
        return acc;
      }, []);
    };
  
  return (
       <div class="card mb-4">
                      <div class="card-header">
                        <h5 class="card-title mb-0">Organize</h5>
                      </div>
                      <div class="card-body">

                      <div class="mb-3">
                          <label class="form-label" for="ecommerce-store-name">Store Name</label>
                          <Select
                            className="select"
                            value={storeNameList.filter(item => formState.store_id.includes(item.store_id))}
                            options={storeNameList}
                            loadingMessage={"Loading Store name"}
                            name="store_name"
                            onChange={(e) => onChangeStore(e)}
                            getOptionValue={(option) => `${option.store_id}`}
                            getOptionLabel={(option) => `${option.store_name}`}
                            
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                minHeight: '40px',
                              }),
                            }}
                            />
                        </div>

                        <div class="mb-3">
                          <label class="form-label" for="ecommerce-Category">Category</label>
                          <Select
                            isMulti
                            options={Options}
                            value={flattenOptions(Options).filter(option => formState.product_categories.includes(option.value))}
                            onChange={handleSelectChange}
                            isLoading={false}
                            isDisabled={false}
                            loadingMessage={"Loading Category"}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                minHeight: '40px',
                              }),
                            }}
                          />
                        </div>
                     
                    <div className="mb-3">
                        <label className="form-label" htmlFor="ecommerce-Brand">Brand</label>
                            <CreatableSelect
                                isClearable
                                options={optionsBrand}
                                onInputChange={handleInputChangeBrand}
                                onCreateOption={handleCreateBrand}
                                value={
                                  formState.product_brand && optionsBrand.some(option => option.label === formState.product_brand)
                                      ? optionsBrand.find(option => option.label === formState.product_brand)
                                      : formState.product_brand
                                          ? { value: formState.product_brand, label: formState.product_brand }
                                          : null }
                                menuIsOpen={menuIsOpen}
                                onChange={(selectedOption) => {
                                  const newValue = selectedOption ? selectedOption.label : null;
                                  onChangeField('product_brand', newValue);
                                  if (selectedOption === null) {
                                      onChangeField('product_brand', null);
                                  }
                              }}
                              placeholder="Select or create a brand..."
                            />
                    </div>

                        <div class="mb-3">
                          <label class="form-label" for="ecommerce-product-name">Display Order</label>
                          <input
                            type="number"
                            class="form-control"
                            id="ecommerce-Display-Order"
                            placeholder="Display Order"
                            name="product_display_order"
                            aria-label="Display Order"
                            value={formState.product_display_order}
                            onChange={handleFieldChange}
                             />
                        </div>

                        <div className="mb-3">
                        <label className="form-label" htmlFor="ecommerce-Brand">Supplier Name</label>
                            <CreatableSelect
                                isClearable
                                options={optionsSuplier}
                                onInputChange={handleInputChangeSuplier}
                                onCreateOption={handleCreateSuplier}
                                value={
                                  formState.product_supplier && optionsSuplier.some(option => option.label === formState.product_supplier)
                                      ? optionsSuplier.find(option => option.label === formState.product_supplier)
                                      : formState.product_supplier
                                          ? { value: formState.product_supplier, label: formState.product_supplier }
                                          : null }
                                menuIsOpen={suplierMenuIsOpen}
                                onChange={(selectedOption) => {
                                  const newValue = selectedOption ? selectedOption.label : null;
                                  onChangeField('product_supplier', newValue);
                                  if (selectedOption === null) {
                                      onChangeField('product_supplier', null);
                                  }
                              }}
                              placeholder="Select or create a supplier..."
                            />
                    </div>

                        <div class="mb-3">
                        <label class="form-label" for="ecommerce-Category">Status</label>
                        <Select
                            className="select"
                            value={status.find(item => item.id === formState.product_status)}
                            options={status}
                            isLoading={false}
                            loadingMessage={"Loading Status"}
                            name="status"
                            onChange={onChangeStatus}
                            isDisabled={false}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.status_code}
                        />
                      </div>

                        <div class="mb-3">
                          <label class="form-label" for="ecommerce-product-name">HST</label>
                          <input
                            type="number"
                            step="any"
                            class="form-control"
                            id="ecommerce-HST-Name"
                            placeholder="HST"
                            name="product_hst"
                            aria-label="HST" 
                            value={formState.product_hst}
                            onChange={handleFieldChange}
                            />
                        </div>

                        <div className="mb-3">
                          <label htmlFor="ecommerce-product-tags" className="form-label mb-1">Tags</label>
                          <div>
                              {/* <TagsInput
                                  value={formState.product_tags}
                                  onChange={(tags) => {
                                      onChangeField('product_tags', tags.map(tag => tag));
                                  }}
                                  name="product_tags"
                                  placeHolder="enter tags"
                              /> */}

                            <CreatableSelect
                                isClearable
                                isMulti
                                options={optionsTags}
                                onInputChange={handleInputChangeTag}
                                onCreateOption={handleCreateTag}
                                value={formState.product_tags?.map(tag => ({ value: tag, label: tag }))}
                                menuIsOpen={menuIsOpenTag}
                                onChange={(selectedOption) => {
                                  if (selectedOption === null) {
                                    onChangeField('product_tags', null);
                                  } else {
                                    if (Array.isArray(selectedOption)) {
                                      const newValue = selectedOption.map(option => option.label);
                                      onChangeField('product_tags', newValue);
                                    } else {
                                      const newValue = selectedOption.label;
                                      onChangeField('product_tags', [newValue]);
                                    }
                                  }
                              }}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  minHeight: '40px',
                                }),
                              }}
                              placeholder="Select or create a Tags..."
                            />
                              <em>press enter or comma to add new tag</em>
                          </div>
                      </div>

                      <div class="mb-3">
                          <label class="form-label" for="ecommerce-Category">Related Products</label>
                          <Select
                                isClearable
                                isMulti
                                options={optionsRelatedProduct}
                                onInputChange={handleInputChangeRelatedProduct}
                                value={formState.related_products && formState.related_products.map(product => {
                                  const option = optionsRelatedProduct.find(option => option.value === product.product_sku);
                                  return {
                                      value: product.product_sku,
                                      label: option ? option.label : product.product_title 
                                  };
                              })}
                                menuIsOpen={menuIsOpenRelatedproduc}
                                onChange={(selectedOptions) => {
                                const relatedProducts = selectedOptions.map((item) => ({
                                    id:0,
                                    product_sku: item.value,
                                    product_title: item.label
                                }));
                                  onChangeField('related_products',relatedProducts);
                                }}
                                placeholder="Select or create related products..."
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    minHeight: '40px',
                                  }),
                                }}
                            />
                        </div>

                      <div class="form-check mb-2">
                          <input class="form-check-input" type="checkbox" name="product_isfree" id="price-charge-tax" value={formState.top_selling}
                            onChange={handleFieldChange}  checked={formState.product_isfree === 1}/>
                          <label class="form-label" for="price-charge-tax"> Free </label>
                      </div>

                        
                      <div class="form-check mb-2">
                          <input class="form-check-input" type="checkbox" name="top_selling" id="price-charge-tax" value={formState.top_selling}
                            onChange={handleFieldChange}  checked={formState.top_selling === 1}/>
                          <label class="form-label" for="price-charge-tax"> Top Selling </label>
                      </div>
					  <div class="form-check mb-2">
                          <input class="form-check-input" type="checkbox" name="top_selling_missingpop" id="price-charge-tax" value={formState.top_selling_missingpop}
                            onChange={handleFieldChange}  checked={formState.top_selling_missingpop === 1}/>
                          <label class="form-label" for="price-charge-tax">Missing Pop Top Selling </label>
                      </div>
					  <div class="form-check mb-2">
                          <input class="form-check-input" type="checkbox" name="top_deal_missingpop" id="price-charge-tax" value={formState.top_deal_missingpop}
                            onChange={handleFieldChange}  checked={formState.top_deal_missingpop === 1}/>
                          <label class="form-label" for="price-charge-tax"> Missing Pop Top Deal</label>
                      </div>
					  <div class="form-check mb-2">
                          <input class="form-check-input" type="checkbox" name="notify_customers" id="price-charge-tax" value={formState.notify_customers}
                            onChange={handleFieldChange}  checked={formState.notify_customers === 1}/>
                          <label class="form-label" for="price-charge-tax"> Notify Customers</label>
                      </div>                              
                     <div className="row mb-3">
                        <div className="col">
                            <div className="d-flex justify-content-between align-items-center border-top pt-3">
                            <label class="form-label" for="ecommerce-product-barcode">Stock</label>
                            <Switch checked={formState.product_in_stock} onChange={handleSwitchChange} title='product_in_stock' />
                            </div>
                        </div>
                    </div>
                        
                       
                    </div>
         </div>
  )
}

export default Organize
