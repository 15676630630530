import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import { STORE_MENU, STORE_PREFIX, STORE_TYPE, TOAST_MSG_TYPE, getConstantData } from '../utils/Constants';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { useStoreMenuList } from '../hooks/StoreMenuHook';
import { menuService } from '../Services/MenuService';

const StoreMenu = () => {
    let {store} = useParams()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo } = useAuth()
    const [params, setParams] = useState({store: store, paginated: true, counter: 0, limit: 10, offset: 0, search: "", field: "main_cat_id", sort: "ASC"})
    const {menuData, loading, error, total} = useStoreMenuList(axiosPrivate, params)
    useEffect(() => {
        if(store) {
            setParams({...params, store: store})
        }
    }, [store])

    const render = useMemo(() => {
        const columns = [
            {
                title: 'Menu Name',
                dataIndex: 'main_cat_title',
                sorter: true,
            },
            {
                title: "Menu Items",
                dataIndex: "subcats",
                render: (text, record) => {
                    return <>
                        {
                            text.map((item) => {
                                return <p>{`${item.child_cat_title} (${item.display_order})`}</p>
                            })
                        }
                    </>
                }
            }
        ]
        if(auth.permissions.includes("InActiveMenu")) {
            columns.push(
                {
                    title: 'Status',
                    render: (text, record) => (
                        <div className="dropdown action-label text-center">
                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">{`${getMenuStatusString(record.menu_status)}`}</a>
                            <div className="dropdown-menu dropdown-menu-right">
                            {
                                getConstantData("STATUS", auth.constants).map(status => {
                                    return <a key={`${record.id}_${status.id}`} className="dropdown-item" href="#" onClick={(e) => changeStoreMenuStatus(e, record, status.id)}>{status.value}</a>
                                })
                            }
                            </div>
                        </div>
                    )
                        
                }
            )
        }
        if(auth.permissions.includes("EditMenu")) {
            columns.push(
                {
                    title: 'Action',
                    render: (text, record) => (
                        <div className="dropdown dropdown-action text-right">
                            <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <Link className="dropdown-item" to={`${STORE_PREFIX}/${store}${STORE_MENU}/edit/${record.main_cat_id}`} data-toggle="modal" data-target="#edit_leave"><i className="fa fa-pencil m-r-5" /> Edit</Link>
                            </div>
                        </div>
                    ),
                }
            )
        }
        return columns
    }, [auth.permissions])

    const getMenuStatusString = (status) => {
        var statusObj = {}
        if(auth.constants.hasOwnProperty("STATUS")) {
            statusObj = auth.constants["STATUS"].find((s) => s.id == status)
        }
        return statusObj.hasOwnProperty("value") ? statusObj.value : ""
    }

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const changeStoreMenuStatus = (e, record, objKey) => {
        const param = {
            cat: record.main_cat_id,
            store: record.store_id,
            status: objKey
        }
        const apiPromise = menuService.changeStoreMenuStatus(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.changeStatusStoreMenu) {
                let newCounter = params.counter + 1
                setParams((prev) => {
                    return {
                        ...prev,
                        counter: newCounter
                    }
                })
                showToast("Menu Status Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
        })
    }
    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Store Menu</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Store Menu</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item"><Link to={`${STORE_PREFIX}`}>Stores</Link></li>
                                <li className="breadcrumb-item active">Store Menu</li>
                            </ul>
                        </div>
                        {
                            auth.permissions.includes("AddMenu") ?
                            <div className="col-sm-6">
                                <Link to={`${STORE_PREFIX}/${store}${STORE_MENU}/create`} className="btn btn-primary btn-width float-right d-block">Add New Menu</Link>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 10, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={menuData}
                                rowKey={record => record.main_cat_id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default StoreMenu;