import cookie from 'js-cookie'

class UserService {
    getMerchantsList(axios, params) {
        let body = {
            query: `
                query {
                    merchantListing(
                        paginated: true
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            merchant_id
                            first_name
                            last_name
                            email
                            business_name
                            country_phone_code
                            phone
                            business_type
                            merchant_status
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getMerchantByID(axios, id) {
        let body = {
            query: `
                query {
                    merchantDetailsByid(
                        merchant_id: ${id}
                    ) {
                        merchantDetails {
                            merchant_id
                            first_name
                            last_name
                            email
                            business_name
                            country_phone_code
                            phone
                            business_type
                            merchant_delivery_city
							merchant_stripe_secret_key
							merchant_packing_handing_charges
							is_default_merchant
                        }
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    userSignIn(axios, params) {
        let body = {}
        if(params.hasOwnProperty("phone")) {
            body = {
                query: `
                    mutation {
                        adminSignIn(
                            phone: "${params.phone}"
                        ){
                            merchant_id
                            msg
                        }
                    }
                `
            }
        }else if(params.hasOwnProperty("email")) {
            body = {
                query: `
                    mutation {
                        adminSignIn(
                            email: "${params.email}"
                            password: "${params.password}"
                        ){
                            user_details {
                                id
                                email
                                phone
                                country_phone_code
                                login_type
                                account_status
                                account_type
                                role_permissions
                                verification_data {
                                    email_verified
                                    phone_verified
                                }
                            }
                            account_type
                            token
                            constants
                            merchant_delivery_city
                            merchant_business_type
                        }
                    }
                `
            }
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    resendOTP(axios, params) {
        let body = {
            query: `
                mutation {
                    merchantResendOTP(
                        merchant_id: ${params.id}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    verifyOTP(axios, params) {
        let body = {
            query: `
                mutation {
                    merchantVerifyOtp(
                        merchant_id: ${params.id},
                        Otp: ${params.otp}
                    ){
                        token
                        merchantDetails{
                            id
                            name
                            email
                            business_name
                            phone
                            role_title
                            role_permissions
                        }
                        constants
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    getUserDetailsByToken(axios) {
        let header = {};
        header["Content-Type"] = 'application/json';
        if (typeof window !== "undefined") {
            if(cookie.get('access_token') !== null){
                header["x-token"] = cookie.get('access_token')
            }
        }
        let body = {
            query: `
                query {
                    userDetailsByToken {
                        user_details {
                            id
                            email
                            phone
                            country_phone_code
                            login_type
                            account_status
                            account_type
                            role_permissions
                            verification_data {
                                email_verified
                                phone_verified
                            }
                        }
                        account_type
                        token
                        constants
                        merchant_delivery_city
                        merchant_business_type
                    }
                }
            `
        }
        return axios.post("", JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    addUpdateMerchant(axios, params) {
        let query = `
                mutation($is_default_merchant:Boolean,$merchant_id: ID, $first_name: String!, $last_name: String, $email: String!, $password: String, $country_phone_code: String, $phone: String, $business_name: String, $business_type: [Int], $merchant_delivery_city: JSON,$merchant_stripe_secret_key: String,$merchant_stripe_publishable_key: String,$merchant_packing_handing_charges:Float) {
                    createUpdateMerchant(
                        merchant_id: $merchant_id
                        first_name: $first_name
                        last_name: $last_name
                        email: $email
                        password: $password
                        country_phone_code: $country_phone_code
                        phone: $phone
                        business_name: $business_name
                        business_type: $business_type
                        merchant_delivery_city: $merchant_delivery_city
						merchant_stripe_secret_key: $merchant_stripe_secret_key
						merchant_stripe_publishable_key: $merchant_stripe_publishable_key
						merchant_packing_handing_charges: $merchant_packing_handing_charges
						is_default_merchant: $is_default_merchant
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    merchant_id: params.merchant_id,
                    first_name: params.first_name,
                    last_name: params.last_name,
                    email: params.email,
                    password: params.password,
                    country_phone_code: params.country_phone_code.dial_code,
                    phone: params.phone,
                    business_name: params.business_name,
                    business_type: params.business_type,
                    merchant_delivery_city: params.merchant_delivery_city,
					merchant_stripe_secret_key: params.merchant_stripe_secret_key,
					merchant_stripe_publishable_key: params.merchant_stripe_publishable_key,
					merchant_packing_handing_charges: parseFloat(params.merchant_packing_handing_charges),
					is_default_merchant: params.is_default_merchant
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    changeUserStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeMerchantStatus(
                        merchant_id: ${params.id},
                        merchant_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
}

let userService = new UserService()
export { userService }