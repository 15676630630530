import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import { BANNER_PREFIX } from '../utils/Constants';
import CityForm from '../Components/Forms/CityForm';
import BannerForm from '../Components/Forms/BannerForm';

const AddBanner = () => {
    let {useraction, id} = useParams()

    let title = ""
    let breadcrumbData = []
    switch(useraction) {
        case "create":
            title = "Add Banner"
            breadcrumbData.push({title: "Add Banner", link: null})
            break
        case "edit":
            title = "Edit Banner"
            breadcrumbData.push({title: "Banner", link: `${BANNER_PREFIX}`}, {title: "Edit Banner", link: null})
            break
        case "read":
            title = "Read Banner"
            breadcrumbData.push({title: "Banner", link: `${BANNER_PREFIX}`}, {title: "Read Banner", link: null})
            break
    }
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3 className="page-title">{title}</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                {
                                    breadcrumbData.map((obj, index) => {
                                        if(obj.link){
                                            return (
                                                <li key={`bread_${index}`} className="breadcrumb-item"><Link to={`${obj.link}`}>{obj.title}</Link></li>
                                            )
                                        }else {
                                            return (
                                                <li key={`bread_${index}`} className="breadcrumb-item active">{obj.title}</li>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <BannerForm banner_id={id} crud={useraction} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddBanner