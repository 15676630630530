import Select from 'react-select';
import { Select as AntSelect } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom';
import { BUSINESS_TYPE, MERCHANT_PREFIX, TOAST_MSG_TYPE, getConstantData,COUPON_AMOUNT_TYPE } from '../../utils/Constants';
import countryCodes from '../../utils/countryCodes';
import { userService } from '../../Services/UserService';
import { useCityList } from '../../hooks/RouteHooks';
var bolOption=["true","false"]
const initialState = {merchant_id: 0, first_name: "", last_name: "", email: "", phone: "", country_phone_code: "", password: "", business_name: "", business_type: [], merchant_delivery_city: [],merchant_stripe_secret_key: "",merchant_packing_handing_charges:0,is_default_merchant:false}
const MerchantForm = (props) => {
    const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    let { auth } = useAuth()
    const [queryParam, setQueryParam] = useState({paginated: false, limit: 10, offset: 0, search: "", field: "area_name", sort: "ASC"})
    const {citiesData, loading, error, total} = useCityList(axiosPrivate, queryParam) 
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    useEffect(() => {
        if(props.merchant_id) {
            userService.getMerchantByID(axiosPrivate, props.merchant_id).then(res => {
                if(res.data.merchantDetailsByid) {
                    const data = res.data.merchantDetailsByid.merchantDetails
					console.log(data)
                    setFormState({
                        ...data,
						//is_default_merchant: data.is_default_merchant,
                        country_phone_code: countryCodes.find(country => country.dial_code == data.country_phone_code),
                        business_type: data.business_type
                    })
                    setShowLoader(false)
                }else {
                    navigate(`${MERCHANT_PREFIX}`)
                }
            })    
        }else {
            setShowLoader(false)
        }
    }, [props.merchant_id])

    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }

    const onCountryChange = (e) => {
        setFormState({...formState, country_phone_code: e})
    }

    const onBusinessTypeChange = (e) => {
        setFormState({...formState, business_type: [...e]})
    }
    const onDeliveryCitiesChange = (e) => {
        setFormState({...formState, merchant_delivery_city: [...e]})
    }
	const onChangeDefaultMer = (value) => {
		console.log(value)
		setFormState({...formState, is_default_merchant: value})
    }
    const submitHandler = (e) => {
        e.preventDefault()
        let params = {...formState}
        let email = formState.email
        let lastAtPos = email.lastIndexOf('@');
        let lastDotPos = email.lastIndexOf('.');
        if (
            !(
              lastAtPos < lastDotPos &&
              lastAtPos > 0 &&
              email.indexOf('@@') === -1 &&
              email.indexOf('@-') === -1 &&
              email.indexOf('-.') === -1 &&
              email.indexOf('--') === -1 &&
              lastDotPos > 2 &&
              email.length - lastDotPos > 2 &&
              email[0] !== '_'
            )
        ){
            showToast("Please enter a valid email", TOAST_MSG_TYPE.ERROR)
            return
        }
        if (
            !/^[a-zA-Z0-9_]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,15})$/.test(
              email
            )
        ) {
            showToast("Please enter a valid email", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(props.crud === "create") {
            if(!formState.password) {
                showToast("Please Add password to login", TOAST_MSG_TYPE.ERROR)
                return
            }
        }
        if(!formState.first_name) {
            showToast("Please Fill First Name", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.business_name) {
            showToast("Please Fill Business Name", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.business_type || formState.business_type.length == 0) {
            showToast("Please Select Business Type", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.merchant_delivery_city || formState.merchant_delivery_city.length == 0) {
            //showToast("Please Select Deliverable Cities", TOAST_MSG_TYPE.ERROR)
           // return
        }
        if(formState.phone) {
            if (
                (formState.country_phone_code.dial_code === '+91' && formState.phone.length !== 10) ||
                (formState.country_phone_code.dial_code !== '+91' && formState.phone.length > 14)
              ) {
                showToast('Please enter a valid phone number', TOAST_MSG_TYPE.ERROR)
                return
            }
            if(!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(formState.phone)){
                showToast("Please enter a valid phone number", TOAST_MSG_TYPE.ERROR)
                return
            }
        }
        const apiPromise = userService.addUpdateMerchant(axiosPrivate, params)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res) {
                showToast(props.crud === "create" ? "Merchant Added Successfully!" : "Merchant Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
            setFormState({...initialState})
            navigate(`${MERCHANT_PREFIX}`)
        })
    }

    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>First Name*</label>
                            <input type="text" className='form-control' name='first_name' value={formState.first_name} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Last Name</label>
                            <input type="text" className='form-control' name='last_name' value={formState.last_name} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>Login Email*</label>
                            <input type="text" className='form-control' name='email' value={formState.email} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>Password{props.crud === "create" ? `*` : ""}</label>
                            <input type="password" className='form-control' name='password' value={formState.password} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-4'>
                        <div className='form-group'>
                            <label>Is default</label>
							 <AntSelect  
                                className='select col-md-12'
                                value={formState.is_default_merchant}
                                options={COUPON_AMOUNT_TYPE}
                                onChange={onChangeDefaultMer}
                            />
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>Country*</label>
                            <Select 
                                className="select"
                                value={formState.country_phone_code}
                                options={countryCodes}
                                onChange={onCountryChange}
                                getOptionValue={(option) => `${option.dial_code}`}
						        getOptionLabel={(option) => `${option.name} (${option.dial_code})`}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>Phone*</label>
                            <input type="text" className='form-control' name='phone' value={formState.phone} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-4'>
                        <div className='form-group'>
                            <label>Packing Handing Charges </label>
                            <input type="text" className='form-control' name='merchant_packing_handing_charges' value={formState.merchant_packing_handing_charges} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Business Name*</label>
                            <input type="text" className='form-control' name='business_name' value={formState.business_name} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Business Type*</label>
                            <AntSelect 
                                mode='multiple'
                                className='select col-md-12'
                                value={formState.business_type}
                                options={getConstantData("BUSINESS_TYPE", auth.constants)}
                                onChange={onBusinessTypeChange}
                                optionFilterProp={"value"}
                                fieldNames={{label: "value", value: "id"}}
                            />
                        </div>
                    </div>
					<div className='col-md-12'>
                        <div className='form-group'>
                            <label>Stripe Secret Key</label>
                            <input type="text" className='form-control' name='merchant_stripe_secret_key' value={formState.merchant_stripe_secret_key} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-12'>
                        <div className='form-group'>
                            <label>Stripe Publishable Key</label>
                            <input type="text" className='form-control' name='merchant_stripe_publishable_key' value={formState.merchant_stripe_publishable_key} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label>Deliverable Cities</label>
                            <AntSelect 
                                showSearch={true}
                                mode='multiple'
                                loading={loading}
                                className='select col-md-12'
                                value={formState.merchant_delivery_city}
                                options={citiesData}
                                onChange={onDeliveryCitiesChange}
                                optionFilterProp={"area_name"}
                                fieldNames={{label: "area_name", value: "area_name"}}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default MerchantForm