import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { AREA_PREFIX, MERCHANT_PREFIX, TOAST_MSG_TYPE, getConstantData } from '../utils/Constants';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { userService } from '../Services/UserService';
import { useCityList } from '../hooks/RouteHooks';
import { routeService } from '../Services/RouteService';

const Areas = () => {
	
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo } = useAuth()
    const [params, setParams] = useState({counter: 0, limit: 10, offset: 0, search: "", field: "area_name", sort: "ASC"})
    const {citiesData, loading, error, total} = useCityList(axiosPrivate, params);
	
    useEffect(() => {
        if(userInfo.id) {
            setParams({...params, merchant_id: userInfo.id})
        }
    }, [userInfo])

    const render = useMemo(() => {
        const columns = [
            {
                title: 'Area Name',
                dataIndex: 'area_name',
                sorter: true,
            },
            {
                title: 'Min. Order Amount ($)',
                dataIndex: 'min_order_amount',
                render: (text) => {
                    return `${text}`
                }
            },
            {
                title: 'Shipping Charges ($)',
                dataIndex: 'shipping_charges',
                render: (text) => {
                    return `${text}`
                }
            },
			{
                title: 'Free Shipping Days',
                dataIndex: 'free_delivary_days',
                render: (text,row) => {
                    return `${text} ($${row.free_shipping_day_amount})`
                }
            }
        ]
        if(auth.permissions.includes("InActiveCity")) {
            columns.push(
                {
                    title: 'Status',
                    render: (text, record) => (
                        <div className="dropdown action-label text-center">
                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">{`${getCityStatusString(record.city_status)}`}</a>
                            <div className="dropdown-menu dropdown-menu-right">
                            {
                                getConstantData("STATUS", auth.constants).map(status => {
                                    return <a key={`${record.id}_${status.id}`} className="dropdown-item" href="#" onClick={(e) => changeCityStatus(e, record, status.id)}>{status.value}</a>
                                })
                            }
                            </div>
                        </div>
                    )
                        
                }
            )
        }
        if(auth.permissions.includes("EditCity")) {
            columns.push(
                {
                    title: 'Action',
                    render: (text, record) => (
                        <div className="dropdown dropdown-action text-right">
                            <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <Link className="dropdown-item" to={`${AREA_PREFIX}/edit/${record.id}`} data-toggle="modal" data-target="#edit_leave"><i className="fa fa-pencil m-r-5" /> Edit</Link>
                            </div>
                        </div>
                    ),
                }
            )
        }
        return columns
    }, [auth.permissions])

    const getCityStatusString = (status) => {
        var statusObj = {}
        if(auth.constants.hasOwnProperty("STATUS")) {
            statusObj = auth.constants["STATUS"].find((s) => s.id == status)
        }
        return statusObj.hasOwnProperty("value") ? statusObj.value : ""
    }

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const changeCityStatus = (e, record, objKey) => {
        const param = {
            id: record.id,
            status: objKey
        }
        const apiPromise = routeService.changeCityStatus(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.changeCityStatus) {
                showToast("City Status Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
            let newCounter = params.counter + 1
            setParams((prev) => {
                return {
                    ...prev,
                    counter: newCounter
                }
            })
        })
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Area</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Manage Area</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Area</li>
                            </ul>
                        </div>
                        {
                            auth.permissions.includes("AddCity") ?
                            <div className="col-sm-6">
                                <Link to={`${AREA_PREFIX}/create`} className="btn btn-primary btn-width float-right d-block">Add New Area</Link>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 10, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={citiesData}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default Areas;