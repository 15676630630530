import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { CATEGORY_PREFIX, USER_ROLE } from '../utils/Constants';
import 'antd/dist/antd.css';
import "../assets/css/antdstyle.css"
import useAuth from '../hooks/useAuth';
import AdminCategoryTable from '../Components/Category/AdminCategoryTable';
import MerchantCategoryTable from '../Components/Category/MerchantCategoryTable';

const Categories = () => {
    const { auth, userInfo } = useAuth()
    // console.log(auth.constants)
    
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Category</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Manage Category</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Category</li>
                            </ul>
                        </div>
                        {
                            auth.permissions.includes("AddCategory") ?
                            <div className="col-sm-6">
                                <Link to={`${CATEGORY_PREFIX}/create`} className="btn btn-primary btn-width float-right d-block">Add New Category</Link>
                            </div> : null
                        }
                    </div>
                </div>
                
                <div className="card mb-0">
                    {
                        userInfo.account_type == USER_ROLE.ADMIN ? 
                        <AdminCategoryTable /> : <MerchantCategoryTable />
                    }
                </div>
            </div>
        </div>
    
    );
}

export default Categories;