class FleetService {
	orderReportsData(axios, params) {
        let body = {
            query: `
                query {
                    orderReportsData(
                        is_excel: ${params.is_excel !== undefined ? params.is_excel : false}
                        report_name: "${params.report_name}"
						paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getFleetListing(axios, params) {
        let body = {
            query: `
                query {
                    fleetListingCMS(
                        paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            fleet_make
                            fleet_model
                            fleet_year
                            insurance_cost
                            insurance_expiry
                            notes
                            fleet_other_details
                            fleet_licence_number
                            fleet_order_limit
                            fleet_status
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    updateCustomerDetails(axios, params) {
        let query = `
                mutation($customer_id: Int!, $first_name: String!, $last_name: String, $customer_address_city: String!, $phone: String!, $email: String!, $country_phone_code: String!) {
                    updateCustomerDetails(
                        customer_id: $customer_id
                        first_name: $first_name
                        last_name: $last_name
                        customer_address_city: $customer_address_city
                        phone: $phone
                        email: $email
                        country_phone_code: $country_phone_code
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    addUpdateFleet(axios, params) {
        let query = `
                mutation($id: Int, $fleet_make: String!, $fleet_model: String!, $fleet_year: String, $insurance_cost: Float, $insurance_expiry: String, $notes: String, $fleet_licence_number: String!, $fleet_order_limit: Int!, $fleet_other_details: JSON) {
                    createUpdateFleet(
                        id: $id
                        fleet_make: $fleet_make
                        fleet_model: $fleet_model
                        fleet_year: $fleet_year
                        insurance_cost: $insurance_cost
                        insurance_expiry: $insurance_expiry
                        notes: $notes
                        fleet_licence_number: $fleet_licence_number
                        fleet_order_limit: $fleet_order_limit
                        fleet_other_details: $fleet_other_details
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getCustomerByID(axios, customerId) {
        let body = {
            query: `
                query {
                    customerDetailsByid(
                        customer_id: ${customerId}
                    ) {
                        customer_details{
                            id
                            first_name
                            last_name
                            email
                            phone
                            country_phone_code
                            customer_address_city
                        }
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getFleetByID(axios, fleetID) {
        let body = {
            query: `
                query {
                    fleetDetailsById(
                        fleet_id: ${fleetID}
                    ) {
                        fleet_details{
                            id
                            fleet_make
                            fleet_model
                            fleet_year
                            insurance_cost
                            insurance_expiry
                            notes
                            fleet_other_details
                            fleet_licence_number
                            fleet_order_limit
                        }
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    changeFleetStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeFleetStatus(
                        id: ${params.id},
                        fleet_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
}
let fleetService = new FleetService()
export {
    fleetService
}