import { Table,Select } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { itemRender } from '../Components/paginationfunction';
import { CommonContext } from '../context/CommonContext';
import { useCostomerList } from '../hooks/ProductHooks';
import { useCityList } from '../hooks/RouteHooks';
import useAuth from '../hooks/useAuth';
import { PRODUCT_PREFIX,API_BASE_URL, TOAST_MSG_TYPE, getConstantData,CUSTOMER_PREFIX} from '../utils/Constants';
import { productService } from '../Services/ProductService';
import { routeService } from '../Services/RouteService';
const CARD_TYPE = [
    {label: "Cart Customers", value: "CART_USERS"},
    {label: "New Customers", value: "NEW_USERS"},	
	{label: "Existing Customers", value: "EXISTING_USERS"},
	{label: "Invalid City", value: "INVALID_CITY"},
	{label: "All Customers", value: ""},
]

const CustomersList = () => {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
	const [cityParam, seCityParams] = useState({paginated: false, offset:0, limit: 10, field: "area_name", sort: "ASC", search: ""})
	const {citiesData, loading2, error2, citytotal} = useCityList(axiosPrivate, cityParam)
	
    const [params, setParams] = useState({paginated: true, limit: 10, offset: 0, search: "", field: "id", sort: "DESC", counter: 0,customer_address_city: "",customer_type:""})
    const {customerData, loading, error, total} = useCostomerList(axiosPrivate, params)
	
    const changeProductStatus = (e, productRecord, newStatus) => {
        e.preventDefault()
        let apiParams = {
            id: productRecord.id,
            status: newStatus
        }
        const apiPromise = productService.changeCustomerStatus(axiosPrivate, apiParams)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.changeCustomerStatus) {
                showToast("Customer Status Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
            const newCounter = params.counter + 1
            setParams((prev) => {
                return {
                    ...prev,
                    counter: newCounter
                }
            })
        })
    }

    const render = useMemo(() => {
        const columns = [
		    {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: 'Name',
                dataIndex: 'product_title',
                render: (text,row) => {
                    return row.first_name ? row.first_name : ''
                }
            },
            {
                title: 'Phone',
                dataIndex: 'product_slug',
				render: (text,row) => {
                    return row.country_phone_code+' '+row.phone
                }
            },
            {
                title: 'Email',
                dataIndex: 'email'
            },
            {
                title: 'Reward Points',
                dataIndex: 'reward_points'
            },
			{
                title: 'Total Orders',
                dataIndex: 'total_orders'
            },
			{
                title: 'Cart',
                dataIndex: 'cart_id',
				render: (text,row) => {
                   return <a href={`customer-card-details/${row.cart_id}`}>{row.cart_id}<i class="btn">({row.total_products_in_cart})</i></a>
                }
            },
			{
                title: 'WhatsApp',
                dataIndex: 'total_orders',
				render: (text,row) => {
					var country_phone_code=row.country_phone_code.replace(/[^\w\s]/gi, '')
					var phone=Number(row.phone.replace(/[^0-9.]/g, ''))
					return <span><a href={`https://wa.me/${country_phone_code}${phone}`} target="_blank" rel="noopener noreferrer"><i class="fa fa-whatsapp whatsapp-icon wa-call-item"></i></a>   <a href={`tel:${row.country_phone_code}${phone}`} target="_blank" rel="noopener noreferrer"><i class="fa fa-phone phone-icon wa-call-item"></i></a></span>;
                }
            },
			{
                title: 'Device',
                dataIndex: 'ios',
				render: (text,row) => {
					return `${row.base_os}-${row.os_version}(${row.brand})`
                }
            },
			{
                title: 'City',
                dataIndex: 'customer_address_city'
            },
			{
                title: 'Created At',
                dataIndex: 'createdAt',
				render: (text,row) => {
                    return moment.unix(row.createdAt/1000).format("YYYY-MM-DD: HH:mm:ss")
                }
            },
            {
                title: 'Status',
                render: (text, record) => (
                    <div className="dropdown action-label text-center">
                        <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">{`${getProductStatusString(record.account_status)}`}</a>
                        <div className="dropdown-menu dropdown-menu-right">
                        {
                            getConstantData("CUSTOMER_STATUS", auth.constants).map(status => {
                                return <a key={`${record.id}_${status.id}`} className="dropdown-item" href="#" onClick={(e) => changeProductStatus(e, record, status.id)}>{status.value}</a>
                            })
                        }
                        </div>
                    </div>
                )    
            },
            {
                title: 'Action',
                render: (text, record) => (
                    <div className="dropdown dropdown-action text-right">
                        <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link className="dropdown-item" to={`${CUSTOMER_PREFIX}/edit/${record.id}`} data-toggle="modal" data-target="#edit_leave"><i className="fa fa-user m-r-5" /> EDIT PROFILE</Link>
							<Link className="dropdown-item" to={`${CUSTOMER_PREFIX}/reward-points/${record.id}`} data-toggle="modal" data-target="#edit_leave"><i className="fa fa-pencil m-r-5" />REWARD POINTS</Link>
                        </div>
                    </div>
                ),
            }
        ]
        return columns
    }, [auth.permissions])

    const getProductStatusString = (status) => {
        var statusObj = {}
        if(auth.constants.hasOwnProperty("CUSTOMER_STATUS")) {
            statusObj = auth.constants["CUSTOMER_STATUS"].find((s) => s.id == status)
        }
        return statusObj.hasOwnProperty("value") ? statusObj.value : ""
    }

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }
	const filterChange = (e, name, type) => {
        let newVal = e.target.value
        switch(type) {
            case "num":
                newVal = Number(newVal)
                break
            default:
                break
        }
        setParams({
            ...params,
            offset: 0,
            [name]: newVal
        })
    }
	const menuData = (e) => {
        e.preventDefault()
		let params={}
        const apiPromise = routeService.getCustomersExcel(axiosPrivate,params)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            const data = res.data.excelAllCustomersAdmin
            if(data.status == "success") {
                const filePath = data.path.replace("./", "")
                const link = document.createElement("a");
                link.download = `giftCards.xlsx`;
                link.href = `${API_BASE_URL}${filePath}`;
                link.click();
            }
        })
    }
	const orderCustomers = (e) => {
        e.preventDefault()
		let params={}
        const apiPromise = routeService.getOrderedCustomersExcel(axiosPrivate,params)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            const data = res.data.downloadOrderedCustomers
            if(data.status == "success") {
                const filePath = data.path.replace("./", "")
                const link = document.createElement("a");
                link.download = `ordered-customers-list.xlsx`;
                link.href = `${API_BASE_URL}${filePath}`;
                link.click();
            }
        })
    }
	const noOrderCustomers = (e) => {
        e.preventDefault()
		let params={}
        const apiPromise = routeService.getNoOrderCustomersExcel(axiosPrivate,params)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            const data = res.data.downloadNoOrderCustomers
            if(data.status == "success") {
                const filePath = data.path.replace("./", "")
                const link = document.createElement("a");
                link.download = `no-order-customers-list.xlsx`;
                link.href = `${API_BASE_URL}${filePath}`;
                link.click();
            }
        })
    }
	const customerSEarchData = (e) => {
        e.preventDefault()
		let params={}
        const apiPromise = routeService.excelAllCustomerSearchs(axiosPrivate,params)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            const data = res.data.excelAllCustomerSearchs
            if(data.status == "success") {
                const filePath = data.path.replace("./", "")
                console.log(filePath)
                const link = document.createElement("a");
                link.download = `giftCards.xlsx`;
                link.href = `${API_BASE_URL}${filePath}`;
                link.click();
            }
        })
    }
	const onChangeBannerType = (value) => {
		setParams({...params, customer_type: value});
    }
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Customers</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row filter-row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Manage Customers</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Manage Customers {total}</li>
                            </ul>
                        </div>
						<div className="col-sm-6">
						<div className="row filter-row">
						    <div className="col-md-3">                                    
							    <a className="btn btn-success" href="#" onClick={(e) => menuData(e)}>All Customers</a> 
							</div>							
							<div className="col-md-3">                                    
								<a className="btn btn-primary" href="#" onClick={(e) => orderCustomers(e)}>Order Customers</a> 
							</div>
							<div className="col-md-3 ">                                    
								<a className="btn btn-primary" href="#" onClick={(e) => noOrderCustomers(e)}>No Order Customers</a> 
							</div>
							<div className="col-md-3 ">                                    
								<a className="btn btn-primary" href="#" onClick={(e) => customerSEarchData(e)}>Customer Searches</a> 
							</div>
							</div>
						</div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
							<label className='mobile-lable-spacing'>Search:</label>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
							<div className='col-md-3'>
                                        <label className='mobile-lable-spacing'>City:</label>
                                        <select className='form-control table-search' value={params.customer_address_city} onChange={(e) => filterChange(e, "customer_address_city", "string")}>
                                            <option value={""}>Select City</option>
                                            {
                                                citiesData.map((city) => {
                                                    return (
                                                        <option key={city.id} value={city.area_name}>{city.area_name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                            </div>
							<div className='col-md-3'>
								<div className='form-group'>
									<label>Customer Filter*</label>
									<Select 
										showSearch={true}
										className='select col-md-12'
										value={params.customer_type}
										options={CARD_TYPE}
										onChange={onChangeBannerType}
									/>
								</div>
							</div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 10, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={customerData}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}
export default CustomersList