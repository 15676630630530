import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { AREA_PREFIX, BANNER_IMG, GIFT_PREFIX, COUPON_TYPE, TOAST_MSG_TYPE,COUPON_AMOUNT_TYPE } from '../../utils/Constants';
import { routeService } from '../../Services/RouteService';
import { bannerService } from '../../Services/BannerService';
import { Select } from 'antd';
import { DatePicker } from 'antd';
import moment from 'moment';
import ImageUpload from '../ImageUpload';
import { BANNER_LINK_TYPE } from '../../utils/Constants';
const CARD_TYPE = [
    {label: "Single", value: "Single"},
    {label: "Multiple", value: "Multiple"}
]
const initialState = {id: 0, giftcard_couponcode: "", giftcard_amount:0,giftcard_order_min_amount:0, giftcard_totalnumbers: 1, giftcard_desc: "", giftcard_status: 1, giftcard_startdate: undefined, giftcard_enddate: undefined,dataLoaded:false, giftcard_type: "Single",giftcard_name:""}
const GiftCardForm = (props) => {
    const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    useEffect(() => {
        setShowLoader(false)
    }, [props.id])
    
    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {		
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
    const onChangeBannerType = (value) => {
		setFormState({...formState, giftcard_type: value});
    }
    const onChangeStartDate = (value, dateString) => {
        setFormState({...formState, giftcard_startdate: moment(dateString)})
    }
    const onChangeEndDate = (value, dateString) => {
        setFormState({...formState, giftcard_enddate: moment(dateString)})
    }
	

    const submitHandler = (e) => {
        e.preventDefault()
        if(!formState.giftcard_type) {
            showToast("Please select Type Title", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.giftcard_startdate) {
            showToast("Please Fill Start Date", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.giftcard_enddate) {
            showToast("Please Fill End Date", TOAST_MSG_TYPE.ERROR)
            return
        }
        let startTime = moment(formState.giftcard_startdate)
        let endTime = moment(formState.giftcard_enddate)
		let paramData ={
			id: formState.id,
			giftcard_name: formState.giftcard_name,
			giftcard_type: formState.giftcard_type,
			giftcard_couponcode: formState.giftcard_couponcode,
			giftcard_startdate: Number(formState.giftcard_startdate/1000),
			giftcard_enddate: Number(formState.giftcard_enddate/1000),
			giftcard_order_min_amount: Number(formState.giftcard_order_min_amount),
			giftcard_totalnumbers: formState.giftcard_totalnumbers,
			giftcard_desc: formState.giftcard_desc,
			giftcard_amount: formState.giftcard_amount
		}
		console.log(paramData)
        const apiPromise = routeService.GenerateGiftCard(axiosPrivate, paramData)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.GenerateGiftCard) {
                showToast(props.crud === "create" ? "Gift Added Successfully!" : "Gift Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
               navigate(`${GIFT_PREFIX}`)
            }
        })
    }

    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
				    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Giftcard name*</label>
                            <input type="text" className='form-control' name='giftcard_name' value={formState.giftcard_name} onChange={onChangeField} />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Giftcard Type*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.giftcard_type}
                                options={CARD_TYPE}
                                onChange={onChangeBannerType}
                            />
                        </div>
                    </div>					
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>total number Gift cards*</label>
                            <input type="number" className='form-control' name='giftcard_totalnumbers' value={formState.giftcard_totalnumbers} onChange={onChangeField} disabled={disabled}/>
                        </div>	
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Giftcard Code</label>
                            <input type="text" className='form-control' name='giftcard_couponcode' value={formState.giftcard_couponcode} onChange={onChangeField} />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Giftcard Amount*</label>
                            <input type="number" className='form-control' name='giftcard_amount' value={formState.giftcard_amount} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Min purchase amount*</label>
                            <input type="number" className='form-control' name='giftcard_order_min_amount' value={formState.giftcard_order_min_amount} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Start Date*</label>
                            <DatePicker 
                                placeholder="Select Start Date" 
                                className="form-control" 
                                onChange={onChangeStartDate} 
                                value={formState.giftcard_startdate || ""}
                                disabledDate={(current) => {
                                    return moment().add(-1, 'days')  >= current
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>End Date*</label>
                            <DatePicker 
                                placeholder="Select End Date" 
                                className="form-control" 
                                onChange={onChangeEndDate} 
                                value={formState.giftcard_enddate || ""}
                                disabledDate={(current) => {
                                    return moment().add(-1, 'days')  >= current
                                    }}
                            />
                        </div>
                    </div>
					
                </div>
                    
				<div className='row mb-4'>
					<div className='col-md-12'>
					<div className='form-group'>
                                <label>Desc:</label>
                                <textarea className='form-control' rows={4} value={formState.giftcard_desc} name='giftcard_desc' onChange={onChangeField}></textarea>
                            </div>
					</div>
				</div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default GiftCardForm