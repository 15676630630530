import React, { Component, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams, withRouter } from 'react-router-dom';
import { ADMIN_PREFIX, CATEGORY_PREFIX, USER_ROLE } from '../utils/Constants';
import useAuth from '../hooks/useAuth';
import AdminCategoryForm from '../Components/Forms/AdminCategoryForm';
import MerchantCategoryForm from '../Components/Forms/MerchantCategoryForm';

const AddCategory = () => {
    let {useraction, id} = useParams()
    const { userInfo } = useAuth()
    let title = ""
    let breadcrumbData = []
    
    switch(useraction) {
        case "create":
            title = "Create Category"
            breadcrumbData.push({title: "Create Category", link: null})
            break
        case "edit":
            title = "Edit Category"
            breadcrumbData.push({title: "Categories", link: `${CATEGORY_PREFIX}`}, {title: "Edit Category", link: null})
            break
        case "read":
            title = "Read Category"
            breadcrumbData.push({title: "Categories", link: `${CATEGORY_PREFIX}`}, {title: "Read Category", link: null})
            break
    }
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3 className="page-title">{title}</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                {
                                    breadcrumbData.map((obj, index) => {
                                        if(obj.link){
                                            return (
                                                <li key={`bread_${index}`} className="breadcrumb-item"><Link to={`${obj.link}`}>{obj.title}</Link></li>
                                            )
                                        }else {
                                            return (
                                                <li key={`bread_${index}`} className="breadcrumb-item active">{obj.title}</li>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        {
                            userInfo.account_type == USER_ROLE.ADMIN ? 
                            <AdminCategoryForm category_id={id} crud={useraction}/> :
                            <MerchantCategoryForm category_id={id} crud={useraction}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddCategory