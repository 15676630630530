import { Route, Router, Routes } from "react-router-dom";
import Layout from "./Admin/AdminLayout/Layout";
import IndexPage from "./CommonPages";

import $ from "jquery";
import "@popperjs/core";
import "bootstrap";
import "font-awesome/css/font-awesome.min.css";

import "./assets/css/font-awesome.min.css";
import "./assets/css/line-awesome.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/js/app.js";
import "./assets/css/style.css";
// import './assets/css/select2.min.css';
// import './assets/js/select2.min.js';

import Loginpage from "./CommonPages/LoginPage";
import { AuthProvider } from "./context/AuthContext";
import RequireAuth from "./Components/RequireAuth";
import ErrorPage from "./CommonPages/ErrorPage";
// import merchantservice from './router_service/merchantservice';
import { CommonProvider } from "./context/CommonContext";
import UnAuthorized from "./CommonPages/UnAuthorized";
import app_routes from "./router_service/app_routes";

function App() {
    return (
        <CommonProvider>
            <AuthProvider>
                <Routes>
                    <Route exact path="/" element={<Loginpage />} />
                    <Route element={<RequireAuth />}>
                        <Route path="/" element={<Layout />}>
                            {app_routes.map((route, index) => {
                                return (
                                    <Route
                                        key={`admin_${index}`}
                                        path={route.path}
                                        element={route.component}
                                    />
                                );
                            })}
                            <Route
                                path="unauthorize"
                                element={<UnAuthorized />}
                            />
                            <Route path="*" element={<ErrorPage />} />
                        </Route>
                    </Route>
                    <Route path="/login" element={<Loginpage />} />
                    {/* <Route path="/register" element={<Registrationpage />} /> */}
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </AuthProvider>
        </CommonProvider>
    );
}

export default App;
