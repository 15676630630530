import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { AREA_PREFIX, MERCHANT_PREFIX, STORE_MENU, STORE_PREFIX, STORE_TYPE, TOAST_MSG_TYPE, getConstantData } from '../utils/Constants';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { useStoreListing } from '../hooks/StoreHooks';
import { storeService } from '../Services/StoreService';

const Stores = () => {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo } = useAuth()
    const [params, setParams] = useState({counter: 0, limit: 10, offset: 0, search: "", field: "store_name", sort: "ASC", paginated: true})
    const {storeData, loading, error, total} = useStoreListing(axiosPrivate, params)
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Store Name',
                dataIndex: 'store_name',
                sorter: true,
            },
            {
                title: 'GST No.',
                dataIndex: 'other_details',
                render: (text, record) => {
                    return `${record.other_details.gst_number || ""}`
                }
            },
            {
                title: 'Phone',
                dataIndex: 'phone',
                render: (text, record) => {
                    return `${text}`
                }
            },
            {
                title: 'Store Type',
                dataIndex: 'store_type',
                render: (text, record) => {
                    return `${getBusinessType(text)}`
                }
            },
            {
                title: 'Delivery Cities',
                dataIndex: 'store_delivery_city',
                render: (text, record) => {
                    return `${text && Array.isArray(text) ? text.join(", ") : ""}`
                }
            },
        ]
        if(auth.permissions.includes("InActiveStore")) {
            columns.push(
                {
                    title: 'Status',
                    render: (text, record) => (
                        <div className="dropdown action-label text-center">
                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">{`${getStoreStatusString(record.store_status)}`}</a>
                            <div className="dropdown-menu dropdown-menu-right">
                            {
                                getConstantData("STATUS", auth.constants).map(status => {
                                    return <a key={`${record.id}_${status.id}`} className="dropdown-item" href="#" onClick={(e) => changeStoreStatus(e, record, status.id)}>{status.value}</a>
                                })
                            }
                            </div>
                        </div>
                    )
                        
                }
            )
        }
        if(auth.permissions.includes("EditStore")) {
            columns.push(
                {
                    title: 'Action',
                    render: (text, record) => (
                        <div className="dropdown dropdown-action text-right">
                            <a href="#" className="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="material-icons">more_vert</i></a>
                            <div className="dropdown-menu dropdown-menu-right">
                                <Link className="dropdown-item" to={`${STORE_PREFIX}/edit/${record.store_id}`} data-toggle="modal" data-target="#edit_leave"><i className="fa fa-pencil m-r-5" /> Edit</Link>
                                {
                                    auth.permissions.includes("ListMenu") ? 
                                    <Link className="dropdown-item" to={`${STORE_PREFIX}/${record.store_id}${STORE_MENU}`} data-toggle="modal" data-target="#edit_leave"><i className="fa fa-th-list" /> Store Menu</Link> : null
                                }
                            </div>
                        </div>
                    ),
                }
            )
        }
        return columns
    }, [auth.permissions])

    const getBusinessType = (type) => {
        var typeObj = {}
        if(auth.constants.hasOwnProperty("BUSINESS_TYPE")) {
            typeObj = auth.constants["BUSINESS_TYPE"].find((s) => s.id == type)
        }
        return typeObj.hasOwnProperty("value") ? typeObj.value : ""
    }

    const getStoreStatusString = (status) => {
        var statusObj = {}
        if(auth.constants.hasOwnProperty("STATUS")) {
            statusObj = auth.constants["STATUS"].find((s) => s.id == status)
        }
        return statusObj.hasOwnProperty("value") ? statusObj.value : ""
    }

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const changeStoreStatus = (e, record, objKey) => {
        const param = {
            id: record.store_id,
            status: objKey
        }
        const apiPromise = storeService.changeStoreStatus(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.changeStoreStatus) {
                let newCounter = params.counter + 1
                setParams((prev) => {
                    return {
                        ...prev,
                        counter: newCounter
                    }
                })
                showToast("Store Status Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
        })
    }
    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Store</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Manage Stores</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Store</li>
                            </ul>
                        </div>
                        {
                            auth.permissions.includes("AddStore") ?
                            <div className="col-sm-6">
                                <Link to={`${STORE_PREFIX}/create`} className="btn btn-primary btn-width float-right d-block">Add New Store</Link>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 10, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={storeData}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default Stores;