class BannerService {
	merchantNamesList(axios,params) {
        let body = {
            query: `
                query {
                    merchantNamesListCMS
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getBannerFormData(axios, params) {
        let body = {
            query: `
                query {
                    storeListingWithMenuAndProducts(
                        store_id: ${params.store}
                    ) {
                        store{
                            store_id
                            store_name
                        }
                        menu_data{
                            menu_item_id
                            category
                        }
                        products{
                            product_id
                            product_title
                        }
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    addUpdateBanner(axios, params, categoryImage) {
        let query = `
                mutation($banner_id: ID, $banner_title: String!, $banner_display_order: Int!, $banner_link_type: String!, $banner_link_data: String, $banner_type: String!, $store_id: Int, $start_date: String, $end_date: String, $banner_image: Upload, $cat_pro_data: JSON,$banner_display_cities: JSON,$all_city_display:Int) {
                    addUpdateBanners(
                        banner_id: $banner_id
                        banner_title: $banner_title
                        banner_display_order: $banner_display_order
                        banner_link_type: $banner_link_type
                        banner_link_data: $banner_link_data
                        banner_type: $banner_type
                        store_id: $store_id
                        start_date: $start_date
                        end_date: $end_date
                        banner_image: $banner_image
                        cat_pro_data: $cat_pro_data
						banner_display_cities: $banner_display_cities
						all_city_display: $all_city_display
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    banner_id: parseInt(params.banner_id),
                    banner_title: params.banner_title,
                    banner_display_order: parseInt(params.banner_display_order),
                    banner_link_type: params.banner_link_type,
                    banner_link_data: params.banner_link_data,
                    banner_type: params.banner_type,
                    store_id: params.store_id,
                    start_date: params.start_date,
                    end_date: params.end_date,
                    banner_image: null,
                    cat_pro_data: params.cat_pro_data,
					banner_display_cities: params.banner_display_cities,
					all_city_display: params.all_city_display
                }
            }
        if(typeof categoryImage === "object") {
            let formData = new FormData()
            formData.append("operations", JSON.stringify(operations))
            const map = {
                "0": ["variables.banner_image"]
            }
            formData.append("map", JSON.stringify(map))
            formData.append("0", categoryImage)
            return axios.post('', formData).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }else {
            if(typeof categoryImage === "string" && categoryImage !== "") {
                operations.variables.banner_image = categoryImage
            }
            return axios.post('', JSON.stringify(operations)).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }
    }

    getBannerDetailByID(axios, id) {
        let body = {
            query: `
                query {
                    bannerDetailsById(
                        banner_id: ${id}
                    ) {
                        id
                        banner_title
                        banner_display_order
                        banner_image
                        banner_link_type
                        banner_link_data
                        banner_type
                        store_id
                        start_date
                        end_date
                        cat_pro_data
						all_city_display
						banner_display_cities
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    getBanners(axios, params) {
        let body = {
            query: `
                query {
                    bannersListing(
                        paginated: ${params.paginated}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            banner_title
                            banner_display_order
                            banner_image
                            banner_link_type
                            banner_link_data
                            banner_status
                            banner_type
                            store_id
                            start_date
                            end_date
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    changeBannerStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeBannerStatus(
                        banner_id: ${params.id},
                        banner_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
}

let bannerService = new BannerService()
export {
    bannerService
}