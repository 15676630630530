import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { STORE_MENU, STORE_PREFIX, TOAST_MSG_TYPE } from '../../utils/Constants';
import Select from 'react-select';
import MenuCategory from './MenuCategory';
import { useAdminCateogryList, useMerchantCateogryList } from '../../hooks/CategoryHooks';
import { menuService } from '../../Services/MenuService';

const initialState = {main_cat_id: undefined, child_cats_obj: [], store_id: 0, subcat_counter: 0}
const StoreMenuForm = (props) => {
    const navigate = useNavigate()
    
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [queryParam, setQueryParam] = useState({paginated: false, limit: 10, offset: 0, search: "", field: "category_title", sort: "ASC"})
    const [adminQueryParam, setAdminQueryParam] = useState({paginated: false, limit: 10, offset: 0, search: "", field: "category_title", sort: "ASC"})
    const {categoryData, loading, error, total} = useMerchantCateogryList(axiosPrivate, queryParam)
    const {categoryData: adminCategory, loading: adminLoading} = useAdminCateogryList(axiosPrivate, adminQueryParam)
    const [modifiedCategory, setModifiedCategory] = useState([])
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    useEffect(() => {
        if(categoryData.length > 0 && adminCategory.length > 0) {
            setModifiedCategory([...categoryData, ...adminCategory])
        }
    }, [categoryData, adminCategory])
    useEffect(() => {
        if(props.main_cat && modifiedCategory && modifiedCategory.length > 0) {
            
            menuService.getStoreMenuByID(axiosPrivate, {cat: props.main_cat, store: props.store}).then(res => {
                if(res.data.storeMenuDetailsWithSubMenus) {
                    const data = res.data.storeMenuDetailsWithSubMenus
                    let submenuData = []
                    let counter = 0
                    for(let i=0;i<data.subcats.length;i++) {
                        let localID = `local_${counter}`
                        let childObj = modifiedCategory.find((cat) => cat.id == data.subcats[i].child_cat_id)
                        let menuObj = {
                            child_cat_id: {...childObj},
                            display_order: data.subcats[i].display_order,
                            menu_item_id: data.subcats[i].menu_item_id,
                            localID: localID
                        }
                        submenuData.push(menuObj)
                        counter = counter + 1
                    }
                    setFormState({
                        main_cat_id: modifiedCategory.find((cat) => cat.id == data.main_cat_id),
                        child_cats_obj: [...submenuData],
                        store_id: data.store_id,
                        subcat_counter: counter
                    })
                    setShowLoader(false)
                }else {
                    navigate(`${STORE_PREFIX}/${props.store}${STORE_MENU}`)
                }
                
            })
        }else if(!props.maincat){
            setShowLoader(false)
        }
    }, [props.maincat, modifiedCategory])

    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }

    const onChangeCategory = (e) => {
        setFormState({...formState, main_cat_id: {...e}})
    }

    const updateFormState = (updatedObj, localID) => {
        let index = formState.child_cats_obj.findIndex((menu) => menu.localID === localID)
        if(index !== -1) {
            formState.child_cats_obj[index] = {...updatedObj}
        }
    }
    
    const addNewSubMenu = (e) => {
        e.preventDefault()
        let newSubcatObj = {
            child_cat_id: undefined,
            display_order: 0,
            menu_item_id: 0,
            localID: "local_"+formState.subcat_counter
        }
        let newCounter = formState.subcat_counter + 1
        setFormState({...formState, child_cats_obj: [...formState.child_cats_obj, {...newSubcatObj}], subcat_counter: newCounter})
    }

    const deleteSubcateogry = (e, localID) => {
        e.preventDefault()
        
        let newCatArray = formState.child_cats_obj.filter((child) => child.localID !== localID)
        setFormState({...formState, child_cats_obj: [...newCatArray]})
    }

    const submenuCheckAndDataCreation = (formData) => {
        
        for(var i=0;i<formState.child_cats_obj.length;i++) {
            let submenu = formState.child_cats_obj[i]
            
            if(!submenu.child_cat_id) {
                showToast(`Please Select Category`, TOAST_MSG_TYPE.ERROR)
                return false
            }
            if(isNaN(submenu.display_order)) {
                showToast(`Please fill correct display order for ${submenu.child_cat_id.category_title}`, TOAST_MSG_TYPE.ERROR)
                return false
            }
            formData.child_cats_obj.push({
                child_cat_id: parseInt(submenu.child_cat_id.id),
                display_order: parseInt(submenu.display_order),
                menu_item_id: parseInt(submenu.menu_item_id),
            })
        }
        return true
    }

    const submitHandler = (e) => {
        e.preventDefault()
        let formData = {
            main_cat_id: formState.main_cat_id.id,
            child_cats_obj: [],
            store_id: props.store
        }
        if(!formData.main_cat_id) {
            showToast("Please Select Main Menu Category", TOAST_MSG_TYPE.ERROR)
            return
        }
        
        if(!submenuCheckAndDataCreation(formData)) {
            return
        }
    
        const apiPromise = menuService.addUpdateMenu(axiosPrivate, formData)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.addUpdateStoreMenu) {
                showToast(props.crud === "create" ? "Menu Added Successfully!" : "Menu Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${STORE_PREFIX}/${props.store}${STORE_MENU}`)
            }
        })
    }
    
    return (
        <>
        {
            showLoader || loading ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label>Main Menu*</label>
                            <Select 
                                className="select" 
                                value={formState.main_cat_id} 
                                options={adminCategory}
                                isLoading={props.loading}
                                name="category" 
                                onChange={(e) => onChangeCategory(e)} 
                                isDisabled={disabled}
                                getOptionValue={(option) => `${option.id}`}
                                getOptionLabel={(option) => `${option.category_title}`}
                            >
                            </Select>
                        </div>
                    </div>
                </div>
                {
                    formState.child_cats_obj.map((childCat, index) => {
                        return <MenuCategory updateFormState={updateFormState} deleteSubcateogry={deleteSubcateogry} key={index} menu_item={childCat} loading={loading} categoriesData={categoryData} />
                    })
                }
                <div className='row position-relative'>
                    <div className='col-md-12'>
                        <button className="newcitybtn btn btn-primary float-right" onClick={addNewSubMenu}>Add New Submenu</button>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default StoreMenuForm