import { useContext, useEffect, useState } from "react"
import { useStoreList } from "../hooks/StoreHooks"
import { CommonContext } from "../context/CommonContext"
import Select from 'react-select';

const StoreSelector = (props) => {
    const { axiosPrivate } = useContext(CommonContext)
    const [params, setParams] = useState({merchant_id: null, limit: 10, offset: 0, search: "", field: "store_name", sort: "ASC", paginated: false})
	const {storeData, loading, error, total} = useStoreList(axiosPrivate, params)
    const [dashboardStore, setDashboardStore] = useState([])
    useEffect(() => {
        if(storeData.length) {
            let allStoreObj = {
                id: 0,
                store_id: 0,
                store_name: "All"
            }
            let newStores = [allStoreObj, ...storeData]
            setDashboardStore(newStores)
        }
    }, [storeData])

    const storeChange = (e) => {
		props.changeStore(e)
	}

    return (
        <Select 
            onChange={(e) => storeChange(e)}
            value={props.selectedStore}
            name="store"
            placeholder="Select Store" 
            options={dashboardStore}
            getOptionValue={(option) => `${option.id}`}
            getOptionLabel={(option) => `${option.store_name}`}
        />
    )
}
export default StoreSelector