class MenuService {

    addUpdateMenu(axios, params) {
        let query = `
                mutation($main_cat_id: Int!, $child_cats_obj: [child_cats_obj], $store_id: Int!) {
                    addUpdateStoreMenu(
                        main_cat_id: $main_cat_id
                        child_cats_obj: $child_cats_obj
                        store_id: $store_id
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    main_cat_id: parseInt(params.main_cat_id),
                    child_cats_obj: params.child_cats_obj,
                    store_id: parseInt(params.store_id)
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    getStoreMenuList(axios, params) {
        let body = {
            query: `
                query {
                    storeMenuListing(
                        paginated: ${params.paginated ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                        store_id: ${params.store}
                    ) {
                        list {
                            main_cat_id
                            main_cat_title
                            menu_status
                            store_id
                            subcats {
                                menu_item_id
                                child_cat_id
                                child_cat_title
                                display_order
                            }
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    getStoreMenuByID(axios, params) {
        let body = {
            query: `
                query {
                    storeMenuDetailsWithSubMenus(
                        main_cat_id: ${params.cat}
                        store_id: ${params.store}
                    ) {
                        main_cat_id
                        main_cat_title
                        store_id
                        subcats {
                            menu_item_id
                            child_cat_id
                            child_cat_title
                            display_order
                        }
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    changeStoreMenuStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeStatusStoreMenu(
                        main_cat_id: ${params.cat},
                        store_id: ${params.store}
                        menu_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    
    getMenuExcel(axios, params) {
        let body = {
            query: `
                query {
                    merchantAllStoreMenusEXCEL
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
}

let menuService = new MenuService()
export {
    menuService
}