import { useEffect, useReducer } from "react"
import { ACTIONS } from "../utils/Constants"
import { dashboardService } from "../Services/DashboardService"

function dashboardDataReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, dashboardData: action.payload.data}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}

function useDashboardData(axios, params) {
    const [state, dispatch] = useReducer(dashboardDataReducer, {loading: true, dashboardData: [], error: ""})
    useEffect(() => {
        if(params.id !== -1) {
            dispatch({type: ACTIONS.MAKE_REQUEST})
            dashboardService.getDashboardData(axios, params).then(res => {
                dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.dashboardData}})
            })
        }
    }, [params])
    return state
}

export {
    useDashboardData
}