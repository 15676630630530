import axios from "axios";
import { API_BASE_URL, TOAST_MSG_TYPE } from "../utils/Constants";
const API_URL = `${API_BASE_URL}graphql`;

export function axiosPublicInterceptor(errHandler) {
    const axiosPublic = axios.create({
        baseURL: API_URL,
    });
    axiosPublic.interceptors.request.use(
        (config) => {
            config.headers["Content-Type"] = "application/json";
            return config;
        },
        (err) => Promise.reject(err)
    );
    axiosPublic.interceptors.response.use(
        (res) => {
            let data = res.data;
            if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                errHandler(data.errors[0].message, TOAST_MSG_TYPE.ERROR);
                return Promise.reject(data.errors[0].message);
            }
            return data;
        },
        (err) => {
            errHandler(err.message, TOAST_MSG_TYPE.ERROR);
            return Promise.reject(err);
        }
    );
    return axiosPublic;
}

export function axiosPrivateInterceptor(errHandler) {
    const axiosPrivate = axios.create({
        baseURL: API_URL,
    });
    axiosPrivate.interceptors.request.use(
        (config) => {
            config.headers["Content-Type"] = "application/json";
            if (localStorage.getItem("access_token")) {
                config.headers["x-token"] =
                    localStorage.getItem("access_token");
            }
            return config;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    axiosPrivate.interceptors.response.use(
        (res) => {
            let data = res.data;
            if (data.hasOwnProperty("errors") && data.errors.length > 0) {
                errHandler(data.errors[0].message, TOAST_MSG_TYPE.ERROR);
                return Promise.reject(data.errors[0].message);
            }
            return data;
        },
        (err) => {
            errHandler(err.message, TOAST_MSG_TYPE.ERROR);
            return Promise.reject(err);
        }
    );
    return axiosPrivate;
}
