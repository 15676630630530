import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet-async";
import {Applogo} from '../utils/imagepath'
import useAuth from '../hooks/useAuth';
import { TOAST_MSG_TYPE } from '../utils/Constants';
import Select from 'react-select';
import countryCodes from '../utils/countryCodes';
import { userService } from '../Services/UserService';
import { CommonContext } from '../context/CommonContext';
import OTPForm from '../Components/OTPForm';
import { useNavigate } from 'react-router-dom';
 
const LOGIN_FORM_TYPE = {
    EMAIL: "email",
    MOBILE: "mobile"
}
const Loginpage = () => {
    const { setLocalStorage } = useAuth()
    const { showToast, showPromiseToast, axiosPublic } = useContext(CommonContext)
    const userRef = useRef()
    const passRef = useRef()
    const errRef = useRef()

    const [formState, setFormState] = useState({email: "", password: "", mobile: "", countryCode: {...countryCodes.find((obj) => obj.code === "IN")}, formType: LOGIN_FORM_TYPE.EMAIL, showOTPForm: false, userID: 0})
    const [errMsg, setErrMsg] = useState("")
    const [testRemainingTime, setRemainingTime] = useState({timing: 0})
    const navigate = useNavigate()
    useEffect(() => {
        if(userRef.current) {
            userRef.current.focus()
        }
    }, [])

    useEffect(() => {
        setErrMsg("")
    }, [formState])

    const onFieldChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }

    const onCodeChange = (e) => {
        setFormState({...formState, countryCode: e})
    }

    const formToShow = (e, formType) => {
        e.preventDefault()
        setFormState({...formState, formType: formType})
    }

    const validateLoginForm = () => {
        if(formState.formType === LOGIN_FORM_TYPE.MOBILE) {
            if (
                formState.mobile === '' ||
                (formState.countryCode.dial_code === '+91' && formState.mobile.length !== 10) ||
                (formState.countryCode.dial_code !== '+91' && formState.mobile.length > 14)
              ) {
                setErrMsg('Please enter a valid phone number')
                return false
            }else if(!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(formState.mobile)){
                setErrMsg("Please enter a valid phone number")
                return false
            }
            return true
        }else if(formState.formType === LOGIN_FORM_TYPE.EMAIL) {
            let lastAtPos = formState.email.lastIndexOf('@');
            let lastDotPos = formState.email.lastIndexOf('.');
            if (
                !(
                  lastAtPos < lastDotPos &&
                  lastAtPos > 0 &&
                  formState.email.indexOf('@@') === -1 &&
                  formState.email.indexOf('@-') === -1 &&
                  formState.email.indexOf('-.') === -1 &&
                  formState.email.indexOf('--') === -1 &&
                  lastDotPos > 2 &&
                  formState.email.length - lastDotPos > 2 &&
                  formState.email[0] !== '_'
                )
            ){
                setErrMsg("Please enter a valid email")
                return false
            }else if (
                !/^[a-zA-Z0-9_]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,15})$/.test(
                    formState.email
                )
            ) {
                setErrMsg("Please enter a valid email")
                return false
            }
            return true
        }
    }
    const loginHandler = (e) => {
        e.preventDefault()
        // showToast("Error Found", TOAST_MSG_TYPE.SUCCESS)
        if(validateLoginForm()) {
            let params = {}
            if(formState.formType === LOGIN_FORM_TYPE.MOBILE) {
                params["phone"] = `${formState.countryCode.dial_code} ${formState.mobile}`
            }else if(formState.formType === LOGIN_FORM_TYPE.EMAIL){
                params["email"] = formState.email
                params["password"] = formState.password
            }
            let servicePromise = userService.userSignIn(axiosPublic, params)
            showPromiseToast(servicePromise, "Sending OTP...")
            servicePromise.then(res => {
                if(res.data) {
                    let data = res.data.adminSignIn
                    setLocalStorage(data)
                    if(data.account_type) {
                        window.location.href = `/dashboard`
                        return
                    }else {
                        showToast("Role Not Defined", TOAST_MSG_TYPE.ERROR)
                        return
                    }
                    // setFormState({...formState, showOTPForm: true, userID: res.data.merchantSignIn.merchant_id})
                    // setRemainingTime({...testRemainingTime, timing: 30})
                }else {
                    showToast("Something Wrong..", TOAST_MSG_TYPE.ERROR)
                }
            }).catch(err => {
                console.log("Fail")
                console.log(err)
            })
        }
    }

    const backPressed = (e) => {
        e.preventDefault()
		setFormState({...formState, showOTPForm: false})
	}

    const updateRemainingTime = (time) => {
        setRemainingTime({...testRemainingTime, timing: time})
    }

    const registerClicked = (e) => {
        e.preventDefault()
        navigate(`/register`)
    }

    return (
        <div className="main-wrapper">
            <Helmet>
                <title>Order App Login</title>
                <meta name="description" content="Login page"/>					
            </Helmet>
            <div className="account-content">
                <div className="container">
                    {/* Account Logo */}
                    <div className="account-logo">
                    <a href="/blue/app/main/dashboard"><img src={Applogo} alt="Dreamguy's Technologies" /></a>
                    </div>
                    {/* /Account Logo */}
                    <div className="account-box">
                        {errMsg && <p className="alert-danger p-3" ref={errRef} aria-live="assertive">{errMsg}</p>}
                        <div className="account-wrapper">
                            <h3 className="account-title">Login</h3>
                            {(() => {
                                if(formState.showOTPForm){
                                    return (
                                        <OTPForm remainingTime={testRemainingTime} updateRemainingTime={updateRemainingTime} formState={formState} backPressed={backPressed}/>
                                    )
                                }else {
                                    if(formState.formType === LOGIN_FORM_TYPE.MOBILE) {
                                        return (
                                            <form>
                                                <div className="form-group">
                                                    <label>Country Code</label>
                                                    <Select 
                                                        onChange={(e) => onCodeChange(e)}
                                                        value={formState.countryCode}
                                                        name="countryCode"
                                                        placeholder="Select Country Code" 
                                                        options={countryCodes}
                                                        getOptionValue={(option) => `${option.dial_code}`}
                                                        getOptionLabel={(option) => `${option.name} (${option.dial_code})`}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Phone Number</label>
                                                    <input className="form-control" type="text" name="mobile" ref={userRef} value={formState.mobile} onChange={(e) => onFieldChange(e)}/>
                                                </div>
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <a className="text-muted" href="#" onClick={(e) => formToShow(e, LOGIN_FORM_TYPE.EMAIL)}>
                                                            Login by email?
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group text-center">
                                                    <button className="btn btn-primary account-btn" onClick={(e) => loginHandler(e)}>
                                                    Send OTP</button>
                                                </div>
                                            </form>
                                        )
                                    }else if(formState.formType === LOGIN_FORM_TYPE.EMAIL) {
                                        return (
                                            <form>
                                                <div className="form-group m-b-10">
                                                    <label>Email</label>
                                                    <input className="form-control" type="text" name="email" ref={userRef} value={formState.email} onChange={(e) => onFieldChange(e)}/>
                                                </div>
                                                <div className="form-group m-b-10">
                                                    <label>Password</label>
                                                    <input className="form-control" type="password" name="password" ref={passRef} value={formState.password} onChange={(e) => onFieldChange(e)}/>
                                                </div>
                                                <div className='form-group'>
                                                    <a href="#" className='float-right color-blue m-b-20' style={{color: "blue"}} onClick={registerClicked}>Register Now</a>
                                                </div>
                                                {/* <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-auto">
                                                            <a className="text-muted" href="#" onClick={(e) => formToShow(e, LOGIN_FORM_TYPE.MOBILE)}>
                                                            Login by mobile?
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="form-group text-center">
                                                    <button className="btn btn-primary account-btn" onClick={(e) => loginHandler(e)}>
                                                    Sign IN</button>
                                                </div>
                                            </form>
                                        )
                                    }
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
 }
 
 export default Loginpage;
 