import { useEffect, useReducer } from "react"
import { ACTIONS } from "../utils/Constants"
import { bannerService } from "../Services/BannerService"

function bannerFormReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, data: {storeData: action.payload.data.store, menuData: action.payload.data.menu_data, productData: action.payload.data.products}}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}

function bannerListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, bannerData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}

function useBannerForm(axios, params) {
    const [state, dispatch] = useReducer(bannerFormReducer, {loading: false, data: {storeData: [], menuData: [], productData:[]}, error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        bannerService.getBannerFormData(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.storeListingWithMenuAndProducts}})
        })
    }, [params])
    return state
}

function useBannerList(axios, params) {
    const [state, dispatch] = useReducer(bannerListReducer, {loading: false, bannerData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        bannerService.getBanners(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.bannersListing.list, total: res.data.bannersListing.total}})
        })
    }, [params])
    return state
}

export {
    useBannerForm,
    useBannerList
}