import React from 'react';
import { Helmet } from 'react-helmet-async';
import SuperAdmin from '../Components/Dashboards/SuperAdmin';

const Dashboard = () => {
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Admin Dashboard</title>
            </Helmet>
            <SuperAdmin />
        </div>
    )
}
export default Dashboard