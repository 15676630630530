import { useState } from "react";
import { createContext } from "react";
import { toast } from 'react-toastify';
import ToastContainerComponent from "../Components/Notifications/ToastContainerComponent";
import { axiosPrivateInterceptor, axiosPublicInterceptor } from "../Services/axios";
import { TOAST_MSG_TYPE } from "../utils/Constants";

export const CommonContext = createContext({})

export const CommonProvider = ({children}) => {
    const [headerSelectedStore, setHeaderSelectedStore] = useState(null)
    const showToast = (msg, msgType) => {
        switch (msgType) {
            case TOAST_MSG_TYPE.ERROR:
                toast.error(msg, {autoClose: 8000})
                break;
            case TOAST_MSG_TYPE.SUCCESS:
                toast.success(msg, {autoClose: 8000})
            default:
                break;
        }
    }

    const showPromiseToast = (promiseObj, pending, success, error) => {
        toast.promise(promiseObj, {
            pending,
            error,
            success
        })
    }

    const axiosPublic = axiosPublicInterceptor(showToast)
    const axiosPrivate = axiosPrivateInterceptor(showToast)

    const updateStoreHeader = (e) => {
        setHeaderSelectedStore(e)
    }

    return (
        <CommonContext.Provider 
            value={
                {
                    showToast,
                    showPromiseToast,
                    axiosPublic,
                    axiosPrivate,
                    headerSelectedStore,
                    updateStoreHeader
                }
            }>
            <ToastContainerComponent/>
            {children}
        </CommonContext.Provider>
    )
}