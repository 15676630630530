import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { FLEET_PREFIX, TOAST_MSG_TYPE } from '../../utils/Constants';
import { DatePicker } from 'antd';
import moment from 'moment';
import { fleetService } from '../../Services/FleetService';
import { CKEditor } from 'ckeditor4-react';

const initialState = {id: 0, fleet_make: "", fleet_model: "", fleet_year: "", fleetYear: "", insurance_cost: 0, insurance_expiry: "", insuranceExpiry: "",fleet_licence_number: "", fleet_order_limit: 0, notes: ""}
const FleetForm = (props) => {
    const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        if(props.fleet_id) {
            fleetService.getFleetByID(axiosPrivate, props.fleet_id).then(res => {
                if(res.data.fleetDetailsById) {
                    const data = res.data.fleetDetailsById.fleet_details
                    setFormState({
                        ...data,
                        fleet_year: data.fleet_year ? moment(data.fleet_year) : "",
                        fleetYear: data.fleet_year,
                        insurance_expiry: data.insurance_expiry ? moment(data.insurance_expiry) : "",
                        insuranceExpiry: data.insurance_expiry
                    })
                    setShowLoader(false)
                }else {
                    navigate(`${FLEET_PREFIX}`)
                }
            })    
        }else {
            setShowLoader(false)
        }
    }, [props.fleet_id])

    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
    
    const onChangeModalYear = (value, dateString) => {
        setFormState({...formState, fleet_year: moment(dateString), fleetYear: dateString})
    }
    const onChangeInsuranceExpiry = (value, dateString) => {
        setFormState({...formState, insurance_expiry: moment(dateString), insuranceExpiry: dateString})
    }
    const submitHandler = (e) => {
        e.preventDefault()
        if(!formState.fleet_make) {
            showToast("Please Fill Fleet Make", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.fleet_model) {
            showToast("Please Fill Fleet Modal", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.fleet_year) {
            showToast("Please Fill Fleet Modal Year", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.fleet_licence_number) {
            showToast("Please Fill Fleet Licence Number", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.fleet_order_limit) {
            showToast("Please Fill Fleet Order Limit", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(isNaN(Number(formState.fleet_order_limit))) {
            showToast("Please Fill correct Fleet Order Limit", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.insurance_cost) {
            showToast("Please Fill Fleet Insurance Cost", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(isNaN(Number(formState.insurance_cost))) {
            showToast("Please Fill correct Fleet Insurance Cost", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.insurance_expiry) {
            showToast("Please Fill Fleet Insurance Expiry Date", TOAST_MSG_TYPE.ERROR)
            return
        }
        let param = {
            id: formState.id,
            fleet_make: formState.fleet_make,
            fleet_model: formState.fleet_model,
            fleet_year: formState.fleetYear,
            insurance_cost: Number(formState.insurance_cost),
            insurance_expiry: formState.insuranceExpiry,
            fleet_licence_number: formState.fleet_licence_number,
            fleet_order_limit: Number(formState.fleet_order_limit),
            notes: formState.notes,
            fleet_other_details: {}
        }
        const apiPromise = fleetService.addUpdateFleet(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.createUpdateFleet) {
                showToast(props.crud === "create" ? "Fleet Added Successfully!" : "Fleet Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${FLEET_PREFIX}`)
            }
        })
    }

    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Fleet Make*</label>
                            <input type="text" className='form-control' name='fleet_make' value={formState.fleet_make} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Fleet Modal*</label>
                            <input type="text" className='form-control' name='fleet_model' value={formState.fleet_model} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Modal Year*</label>
                            <DatePicker 
                                picker='year'
                                placeholder="Select Start Date" 
                                className="form-control" 
                                onChange={onChangeModalYear} 
                                value={formState.fleet_year || ""}
                                disabledDate={(current) => {
                                    return moment().add(0, 'year')  <= current
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Licence Number*</label>
                            <input type="text" className='form-control' name='fleet_licence_number' value={formState.fleet_licence_number} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Fleet Order Limit*</label>
                            <input type="text" className='form-control' name='fleet_order_limit' value={formState.fleet_order_limit} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Insurance Cost*</label>
                            <input type="text" className='form-control' name='insurance_cost' value={formState.insurance_cost} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-12'>
					<label>Note:</label>
                       <textarea className='form-control' rows={4} value={formState.notes} name='notes' onChange={onChangeField}></textarea>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default FleetForm