class StoreService {

    getStoresList(axios, params) {
        let body = {
            query: `
                query {
                    merchantStoreListing(
                        paginated: ${params.paginated != undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            store_id
                            merchant_id
                            store_name
                            store_slug
                            phone
                            store_type
                            other_details
                            store_delivery_city
                            store_address
                            store_status
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    addStore(axios, params) {
        
        let query = `
                mutation($store_id: ID, $store_name: String, $store_type: Int!, $phone: String, $store_slug: String, $country_phone_code: String, $store_address: JSON, $other_details: JSON, $store_delivery_city: JSON) {
                    createUpdateMerchantStore(
                        store_id: $store_id
                        store_name: $store_name
                        store_type: $store_type
                        phone: $phone
                        store_slug: $store_slug
                        country_phone_code: $country_phone_code
                        store_address: $store_address
                        other_details: $other_details
                        store_delivery_city: $store_delivery_city
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    ...params
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    getStoreByID(axios, storeID) {
        let body = {
            query: `
                query {
                    merchantStoreDetailsByid(
                        store_id: ${storeID}
                    ) {
                        merchantStoreDetails {
                            id
                            store_id
                            store_name
                            store_slug
                            phone
                            store_type
                            other_details
                            store_delivery_city
                            store_address
                            category_ids
                        }
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    changeStoreStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeStoreStatus(
                        store_id: ${params.id},
                        store_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
}

let storeService = new StoreService()
export { storeService }