import React from 'react'

function MetaProprties({ formState, onChangeField }) {

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    onChangeField(name, value);
  }

  return (
                 <div class="card mb-4">
                      <div class="card-header">
                      <h5 class="card-title mb-0">Meta Properties</h5>
                      </div>

                      <div class="card-body">
                      <div class="row mb-3">
                          <div class="col">
                          <label class="form-label" for="ecommerce-mrta-Title">Title</label>
                          <input
                            type="text"
                            class="form-control"
                            id="ecommerce-meta-title"
                            placeholder="Title"
                            name="meta_title"
                            aria-label="Meta title" 
                            value={formState.meta_title}
                            onChange={handleFieldChange}
                            />
                          </div>
                          <div class="col">
                          <label class="form-label" for="ecommerce-meta-Keyword">Keyword</label>
                          <input
                            type="text"
                            class="form-control"
                            id="ecommerce-meta-Keyword"
                            placeholder="Keyword"
                            name="meta_keywords"
                            aria-label="meta keyword" 
                            value={formState.meta_keywords}
                            onChange={handleFieldChange}
                            />
                          </div>
                        </div>
                        

                        <div class="mb-3">
                          <label class="form-label" for="ecommerce-product-name">Description</label>
                          <textarea
                            type="text"
                            class="form-control"
                            id="ecommerce-product-name"
                            placeholder="Product Description"
                            name="meta_desc"
                            aria-label="Product Description"
                            rows={3}
                            style={{ height: "80px", resize: "none" }}
                            value={formState.meta_desc}
                            onChange={handleFieldChange}
                            />
                        </div>
                     </div>
             </div>
          
  )
}


export default MetaProprties
