import React from "react";
import { useLocation, Redirect, Route, Navigate, Outlet } from "react-router-dom";

import useAuth from "../hooks/useAuth";

const RequireAuth = ({children, ...rest}) => {
    const { auth, getUserCookie } = useAuth();
    const location = useLocation()
    const userCookie = auth.access_token
    if(userCookie){
        return (
            <Outlet />
          );
    }else {
        return (
            <Navigate to={{pathname: "/login", state: { from: location }}}/>
        );
    }
}

export default RequireAuth