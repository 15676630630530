import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import { PRODUCT_PREFIX, TOAST_MSG_TYPE } from '../utils/Constants';
import { CommonContext } from '../context/CommonContext';
import { productService } from '../Services/ProductService';
import { useNavigate } from 'react-router-dom';
import ProductInfo from '../Components/EditProduct/ProductInfo';
import ProductVariants from '../Components/EditProduct/ProductVariants';
import UploadImage from '../Components/EditProduct/UploadImage';
import Organize from '../Components/EditProduct/Organize';
import MetaProprties from '../Components/EditProduct/MetaProprties';

const initialState = { id: 0, product_sku: "", product_slug: "", product_status: "", product_title: "",product_display_order:"", 
store_name: "", product_barcode: "", product_description: "",product_short_description:"",product_brand:"",product_hst:"", category:[],meta_desc:"",
meta_keywords:"",meta_title:"",product_supplier:"",status:"",top_selling:0,notify_customers:0,product_variants: [],product_isfree:0,product_in_stock:true,
product_categories:[],product_tags:[], product_images:[],product_uploaded_image:[],store_id:[],product_isfree:0,related_products:[],top_deal_missingpop:0,top_selling_missingpop:0};

function ProductEdit() {
  let { productId } = useParams();
  const navigate = useNavigate();
  const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext);
  const [formState, setFormState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [storeId,setStoreId] = useState([]);
  let breadcrumbData = [{ title: "Product", link: `${PRODUCT_PREFIX}` }, { title: "Edit Product", link: null }];
  
  useEffect(() => {
    if (productId != undefined) {
      setLoading(true);
      productService.productDetailsById(axiosPrivate, productId).then((res) => {
        const productRes = res.data?.productDetailsById;
        console.log("productRes____________",productRes);
        setStoreId(productRes?.store_id)
        const updatedVariants = productRes?.product_variants?.map(variant => ({
          ...variant,
          originFileObj: null
        }));
        setFormState((prev) => ({
          ...prev,
          id: productRes.id,
          product_sku: productRes?.product_sku,
          product_slug: productRes?.product_slug,
          product_status: productRes?.product_status,
          product_title: productRes?.product_title,
          store_name: productRes?.store_name,
          product_barcode: productRes?.product_barcode,
          product_description: productRes?.product_description,
          product_display_order:productRes?.product_display_order,
          product_brand:productRes?.product_brand,
          product_hst:productRes?.product_hst,
          product_supplier:productRes?.product_supplier,
          product_short_description:productRes?.product_short_description,
          meta_desc:productRes?.meta_data.meta_desc,
          meta_keywords:productRes?.meta_data.meta_keywords,
          meta_title:productRes?.meta_data.meta_title,
          product_variants:updatedVariants,
          product_categories:productRes?.product_categories,
          product_images:productImage(productRes?.product_images),
          top_selling:productRes?.top_selling,
		  top_deal_missingpop:productRes?.top_deal_missingpop,
		  top_selling_missingpop:productRes?.top_selling_missingpop,
          product_in_stock:productRes?.product_in_stock,
          product_uploaded_image:productRes?.product_uploaded_image,
          store_id:[`${productRes?.store_id}`],
          product_tags:productRes?.product_tags,
          product_isfree:productRes?.product_isfree,
          related_products:productRes?.related_products
        }));
        setLoading(false);
      }).catch(error => {
        console.error("Error fetching product details:", error);
        setLoading(false);
      });
    }
  }, [productId]);

  function generateUUID() {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 10000);
    const uuid = `${timestamp}-${randomNum}`;

    return uuid;
}

function productImage(imageArray){
  const updatedProductImages = imageArray?.map(image => ({
    uid: image,
    name: image,
    status: 'ulreadyUpload',
    originFileObj:{
        uid: generateUUID(),
        name: image,
        status: 'done', 
        response: 'Server response here',
        error: null,
        percent: 100,
        originFileObj: null,
    }
  }));
  return updatedProductImages;
}

  const onChangeField = (fieldName, value) => {
    setFormState(prevState => ({
      ...prevState,
      [fieldName]: value
    }));
  }

  const submitHandler = (e) => {
    e.preventDefault();
    // after update api
	console.log(formState)
    const apiPromise = productService.addUpdateProduct(axiosPrivate,formState,productId,storeId)
    showPromiseToast(apiPromise, "Please Wait...")
    apiPromise.then((res) => {
        console.log("Editres____________",res)
        if(res.data?.addUpdateProductsCMS){
         showToast("Product Updated Successfully", TOAST_MSG_TYPE.SUCCESS);
         setFormState({ ...initialState });
         setStoreId([]);
         navigate(`${PRODUCT_PREFIX}`);
        }
    }).catch((error)=>{
        console.log("Editerror____________",error)
    })
  }

  return (
    <div className="page-wrapper">
      <Helmet>
        <title>Edit Product</title>
      </Helmet>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-sm-6">
              <h3 className="page-title">Edit Product</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboards</Link></li>
                {breadcrumbData.map((obj, index) => (
                  <li key={`bread_${index}`} className={`breadcrumb-item ${!obj.link ? 'active' : ''}`}>
                    {obj.link ? <Link to={obj.link}>{obj.title}</Link> : obj.title}
                  </li>
                ))}
              </ul>
            </div>
           {!loading&& <div className="col-sm-6">
              <div className="d-flex justify-content-end gap-3">
                <button type="submit" className="btn btn-primary" onClick={submitHandler}>Confirm Edit Product</button>
              </div>
            </div>}
          </div>
        </div>

        {loading ? (
          <div className="text-center">
            <p>Loading...</p>
          </div>
        ) : (
          <div className="row">
            <div className="col-12 col-lg-8">
              <ProductInfo formState={formState} onChangeField={onChangeField} />
              <ProductVariants formState={formState} onChangeField={onChangeField} />
              <UploadImage formState={formState} onChangeField={onChangeField} />
              <MetaProprties formState={formState} onChangeField={onChangeField} />
            </div>

            <div className="col-12 col-lg-4">
              <Organize formState={formState} onChangeField={onChangeField} storeID={storeId}/>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductEdit;