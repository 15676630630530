import { Table } from 'antd';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Link,useParams } from 'react-router-dom';
import moment from 'moment';
import { itemRender } from '../Components/paginationfunction';
import { CommonContext } from '../context/CommonContext';
import { useCustomerRewardLogs } from '../hooks/OrderHooks';
import { orderService } from '../Services/OrderService';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PRODUCT_PREFIX, TOAST_MSG_TYPE, getConstantData,CUSTOMER_PREFIX,} from '../utils/Constants';
import { productService } from '../Services/ProductService';

const CardDetails = (props) => {
	const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
    let { id } = useParams()
	const [rewardPoint, setRewardPoint] = useState(0)
	
	const [params, setParams] = useState({paginated: true, limit: 10, offset: 0, search: "", field: "id", sort: "DESC", counter: 0,customer_id: Number(id)})
    const {orderLog, loading, error, total} = useCustomerRewardLogs(axiosPrivate, params)
	console.log(orderLog)
    const render = useMemo(() => {
        const columns = [		    
            {
                title: 'id',
                dataIndex: 'id'
            },{
                title: 'Reward Points',
                dataIndex: 'reward_points'
            },{
                title: 'Created At',
                dataIndex: 'createdAt',
				render: (text,row) => {
                    return moment.unix(row.createdAt/1000).format("YYYY-MM-DD: HH:mm:ss")
                }
            },{
                title: 'orderId',
                dataIndex: 'order_id'
            },
        ]
        return columns
    }, [auth.permissions])

    const onChangeField = (e) => {        
        const value = e.target.value
        setRewardPoint(value)
    }

    const submitHandler = (e) => {
        e.preventDefault()
        if(!rewardPoint) {
            showToast("Please enter rewardPoint", TOAST_MSG_TYPE.ERROR)
            return
        }
        let paramData = {
            reward_points: Number(rewardPoint),
			customer_id: Number(id),
        }
		console.log(paramData)
        const apiPromise = orderService.manageCustomerRewardPoints(axiosPrivate, paramData)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.manageCustomerRewardPoints) {
                showToast(props.crud === "create" ? "Reward Updated Sucessfully!" : "Reward Points Updated Sucessfully", TOAST_MSG_TYPE.SUCCESS)
                setRewardPoint(0)
				window.location.reload();

            }
        })
    }
	
	
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Products</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Customers card Items</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">{id}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                               
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= {
									{
									total : total,
									pageSize: 10, 
									showSizeChanger : false,
									itemRender : itemRender 
									} 
								}
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={orderLog}
                            />
                        </div>
                    </div>
					<div className="card-body">
					<div className='col-md-6'>
                        <div className='form-group'>
                            <label>New Reward Point*</label>
                            <input type="text" className='form-control' name='reward_points' onChange={onChangeField} />
                        </div>
						<button className='btn btn-primary mt-40 float-right text-white btn-width' onClick={submitHandler}>Submit</button>
                    </div>
                         
					</div>
				 </div>
            </div>
        </div>
    
    );
}
export default CardDetails