import React, { useEffect, useState } from 'react';
import { Upload, message, Modal, Button } from 'antd';


const ImageUpload = (props) => {
	const customRequest = (data) => {
		var reader = new FileReader();
		
		reader.addEventListener('load', () => {
			let fileList = {
				uid: data.file.uid,
				name: data.file.name,
				status: 'done',
				url: reader.result
			}
			props.getFile({local: fileList, server: data.file})
		});

		reader.readAsDataURL(data.file);
	};

	const handleChange = (data) => {
		let updateState = { fileList: data.fileList };

		if (data.fileList.length === 0) {
			updateState.fileSrc = '';
			updateState.file = null;
			if ('onRemove' in props) props.onRemove();
		}
		// setInitialState(updateState);
	};

	const beforeUpload = (file) => {
		const isLt5M = file.size / 1024 / 1024 < 5;
		if (!isLt5M) message.error('File must smaller than 5MB!');
		return isLt5M;
	}

	const beforeUploadImage = (file) => {
		let isImg;

		if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif') isImg = true;
		else isImg = false;

		if (!isImg) message.error('You can only upload JPG, PNG, or GIF file!');

		const isLt5M = file.size / 1024 / 1024 < 5;
		if (!isLt5M) message.error('Image must smaller than 5MB!');

		return isImg && isLt5M;
	}

	const toggleUploadButton = () => {
		const { crud, fileName, maxImagesCount, fileList } = props;
		// const { fileList,placeholder } = initialState;
		const placeholder = props.btnText ? props.btnText : "Image"
		const uploadButton = (
			<div>
				<Button style={{ marginBottom: 0 }}>+ {placeholder}</Button>
			</div>
		);

		switch (crud) {
			case 'read':
			if (fileName === null) return null;
			if (fileList.length && !maxImagesCount) return null;
			return uploadButton;

			case 'create':
			if (fileList.length && !maxImagesCount) return null;
			if (fileList.length && fileList.length === maxImagesCount) return null;
			return uploadButton;

			case 'edit':
			if (fileList.length && !maxImagesCount) return null;
			if (fileList.length && fileList.length >= maxImagesCount) return null;
			return uploadButton;

			default:
			return null;
		}
	};

	return (
		<>
			<Upload
				listType="picture-card"
				customRequest={customRequest}
				fileList={props.fileList.length ? props.fileList : []}
				onChange={handleChange}
				supportServerRender={true}
				showUploadList={{
				showRemoveIcon: props.crud === 'read' ? false : true,
				showDownloadIcon: false,
				showPreviewIcon: false
				}}
				beforeUpload={
					props.fileType && props.fileType === 'image' ? beforeUploadImage : beforeUpload
				}
			>
				{toggleUploadButton()}
			</Upload>
		</>
	)
}

export default ImageUpload;
