import React from 'react';

function ProductInfo({ formState, onChangeField }) {
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    onChangeField(name, value);
  }

  return (
    <div className="card mb-4">
      <div className="card-header">
        <h5 className="card-title mb-0">Product Information</h5>
      </div>
      <div className="card-body">
        <div className="mb-3">
          <label className="form-label" htmlFor="ecommerce-product-name">Name</label>
          <input
            type="text"
            className="form-control"
            id="ecommerce-product-name"
            placeholder="Product title"
            name="product_title"
            value={formState.product_title}
            onChange={handleFieldChange}
            aria-label="Product title"
          />
        </div>
        <div className="row mb-3">
          <div className="col">
            <label className="form-label" htmlFor="ecommerce-product-sku">SKU</label>
            <input
              type="text"
              className="form-control"
              id="ecommerce-product-sku"
              placeholder="Product SKU"
              name="product_sku"
              value={formState.product_sku}
              onChange={handleFieldChange}
              aria-label="Product SKU"
            />
          </div>
          <div className="col">
            <label className="form-label" htmlFor="ecommerce-product-barcode">Barcode</label>
            <input
              type="text"
              className="form-control"
              id="ecommerce-product-barcode"
              placeholder="Product barcode"
              name="product_barcode"
              value={formState.product_barcode}
              onChange={handleFieldChange}
              aria-label="Product barcode"
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label className="form-label" htmlFor="ecommerce-product-shortdesc">Short Description</label>
            <input
              type="text"
              className="form-control"
              id="ecommerce-product-shortdesc"
              placeholder="Product short description"
              name="product_short_description"
              value={formState.product_short_description}
              onChange={handleFieldChange}
              aria-label="Product short description"
            />
          </div>
          <div className="col">
            <label className="form-label" htmlFor="ecommerce-product-displayorder">slug</label>
            <input
              type="text"
              className="form-control"
              id="ecommerce-product-slug"
              placeholder="Product slug"
              name="product_slug"
              value={formState.product_slug}
              onChange={handleFieldChange}
              aria-label="Product slug"
            />
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label" htmlFor="ecommerce-product-description">Description</label>
          <textarea
            className="form-control"
            id="ecommerce-product-description"
            placeholder="Product description"
            name="product_description"
            value={formState.product_description}
            onChange={handleFieldChange}
            rows={3}
            style={{ height: "100px", resize: "none" }}
          />
        </div>
      </div>
    </div>
  );
}

export default ProductInfo;