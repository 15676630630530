class TeamService {
    getMemberListing(axios, params) {
        let body = {
            query: `
                query {
                    teamMemberListingCMS(
                        paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            department_id
                            name
                            phone_number
                            emergency_phoneno
                            hourly_rate
                            fleet_id
                            fleet_licence_number
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    addNewTeamMember(axios, params, licenseImage, licenceBackImage) {
        let query = `
                mutation($id: Int, $department_id: Int!, $name: String!, $phone_number: String!, $emergency_phoneno: String, $hourly_rate: Float, $address: JSON, $notes: String, $fleet_id: Int, $country_phone_code: String!, $license_upload: Upload, $license_back_upload: Upload,$login_password: Int!) {
                    createUpdateTeam(
                        id: $id
                        department_id: $department_id
                        name: $name
                        phone_number: $phone_number
                        emergency_phoneno: $emergency_phoneno
                        hourly_rate: $hourly_rate
                        license_upload: $license_upload
                        license_back_upload: $license_back_upload
                        address: $address
                        notes: $notes
                        fleet_id: $fleet_id
                        country_phone_code: $country_phone_code
						login_password: $login_password
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    id: params.id,
                    department_id: params.department_id,
                    name: params.name,
                    phone_number: params.phone_number,
                    emergency_phoneno: params.emergency_phoneno,
                    hourly_rate: params.hourly_rate,
                    address: params.address,
                    notes: params.notes,
                    fleet_id: params.fleet_id,
                    country_phone_code: params.country_phone_code,
                    license_upload: null,
                    license_back_upload: null,
					login_password: params.login_password
                }
            }
        if(typeof licenseImage === "object" || typeof licenceBackImage === "object") {
            let formData = new FormData()
            formData.append("operations", JSON.stringify(operations))
            const map = {
                "0": [],
                "1": []
            }
            if(typeof licenseImage === "object") {
                map["0"].push("variables.license_upload")
            }else if(typeof licenseImage === "string" && licenseImage !== ""){
                operations.variables.license_upload = licenseImage
            }
            if(typeof licenceBackImage === "object") {
                map["1"].push("variables.license_back_upload")
            }else if(typeof licenceBackImage === "string" && licenceBackImage !== ""){
                operations.variables.license_back_upload = licenceBackImage
            }
            formData.append("map", JSON.stringify(map))
            if(typeof licenseImage === "object") {
                formData.append("0", licenseImage)
            }
            if(typeof licenceBackImage === "object") {
                formData.append("1", licenceBackImage)
            }
            return axios.post('', formData).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }else {
            if(typeof licenseImage === "string" && licenseImage !== "") {
                operations.variables.license_upload = licenseImage
            }
            return axios.post('', JSON.stringify(operations)).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }
    }
    getTeamMember(axios, memberID) {
        let body = {
            query: `
                query {
                    teamMemberDetailsById(
                        member_id: ${memberID}
                    ) {
                        member_details{
                            id
                            department_id
                            name
                            phone_number
                            emergency_phoneno
                            hourly_rate
                            license_upload
                            address
                            notes
                            fleet_id
                            license_back_upload
                            country_phone_code
							verification_data
                        }
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
}

let teamService = new TeamService()
export {
    teamService
}