import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { CommonContext } from "../../context/CommonContext"
import { CATEGORY_IMG, CATEGORY_PREFIX, TOAST_MSG_TYPE, getConstantData} from "../../utils/Constants"
import createSlug from "../../utils/createSlug"
import ImageUpload from "../ImageUpload"
import useAuth from "../../hooks/useAuth"
import Select from "react-select"
import { categoryService } from "../../Services/CategoryService"
import { useAdminCateogryList, useMerchantParentCateogry } from "../../hooks/CategoryHooks"
import { DropDownTree } from '@progress/kendo-react-dropdowns';
import { expandedState, processTreeData } from "../SelectOperation/tree-data-operation"

const initialState = {category_id: 0, category_title: "", category_slug: "", category_description: "", category_image: {server: ""}, category_type: undefined, category_short_description: "", enable_add_subcategory: false, category_display_homepage: false, category_display_order: 0, category_parent_ids: {}}
const selectField = 'selected';
const expandField = 'expanded';
const dataItemKey = 'id';
const textField = 'category_title';
const subItemsField = 'subcats';
const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField
};
export default function MerchantCategoryForm(props) {
    const { auth } = useAuth()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [params, setParams] = useState({paginated: false, counter: 0, limit: 10, offset: 0, search: "", field: "category_title", sort: "ASC"})
    const {categoryData, loading, error, total} = useAdminCateogryList(axiosPrivate, params)
    const [formState, setFormState] = useState({...initialState})
    const [showLoader, setShowLoader] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [expanded, setExpanded] = useState([]);
    const [filter, setFilter] = useState({value: "", operator: "contains"});
    const navigate = useNavigate()
    useEffect(() => {
        if(props.category_id && props.category_id > 0 && categoryData.length>0) {
            categoryService.getCategoryByID(axiosPrivate, props.category_id).then(res => {
                let data = res.data.categoryDetailsById
                let pCatObj = getParentCategoryObject(data.category_parent_ids, categoryData)
                
                setFormState({
                    ...formState,
                    category_id: data.id,
                    category_title: data.category_title,
                    category_slug: data.category_slug,
                    category_short_description: data.category_short_description,
                    category_description: data.category_description,
                    category_image: {server: data.category_image ? data.category_image : ""},
                    category_display_order: data.category_display_order,
                    enable_add_subcategory: data.enable_add_subcategory ? true : false,
                    category_display_homepage: data.category_display_homepage ? true : false,
                    category_parent_ids: pCatObj
                })
                setShowLoader(false)
            })
        }else {
            setFormState({...initialState})
            setShowLoader(false)
        }
    }, [props.category_id, categoryData])

    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    
    const treeData = useMemo(() => processTreeData(categoryData, {
        expanded,
        value: formState.category_parent_ids,
        filter
      }, fields), [expanded, formState.category_parent_ids, filter, categoryData]);

    const onChangeField = (e, slugSource=false) => {
        const name = e.target.name
        const value = e.target.value
        let slugObj = {}
        if(slugSource) {
            slugObj = {category_slug: createSlug(value)}
        }
        setFormState({...formState, [name]: value, ...slugObj})
    }

    function getParentCategoryObject(categoryID, categoryData) {
        let parentObj = undefined
        for(let i=0;i<categoryData.length;i++) {
            let obj = categoryData[i]
            if(obj.id == categoryID) {
                parentObj = obj
            }else {
                parentObj =  getParentInChild(categoryID, obj.subcats)
            }
            if(parentObj) {
                return parentObj
            }
        }
    }

    function getParentInChild(categoryID, childCategoryData) {
        if(childCategoryData && childCategoryData.length > 0) {
            for(let i=0;i<childCategoryData.length;i++) {
                let obj = childCategoryData[i]
                if(obj.id == categoryID) {
                    return {...obj}
                }else {
                    return getParentInChild(categoryID, obj.subcats)
                }
            }
        }
    }

    const onChange = (event) => {
        setFormState({...formState, category_parent_ids: event.value})
    }
    const onFilterChange = (event) => setFilter(event.filter);
    const onExpandChange = useCallback(event => setExpanded(expandedState(event.item, dataItemKey, expanded)), [expanded]);
    const onSubmitHandler = (e) => {
        e.preventDefault()
        if(!formState.category_title) {
            showToast("Please Fill Category Title", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.category_slug) {
            showToast("Category Slug can not be empty", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!/^[a-zA-Z0-9](-*[a-zA-Z0-9])*$/.test(formState.category_slug)){
            showToast("You can use only letters, numbers and single - at a time in slug", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.category_display_order || !parseInt(formState.category_display_order)) {
            showToast("Please Add Valid Display Order", TOAST_MSG_TYPE.ERROR)
            return
        }
        let params = {
            ...formState,
            category_display_order: parseInt(formState.category_display_order),
            category_display_homepage: formState.category_display_homepage ? 1 : 0,
            enable_add_subcategory: formState.enable_add_subcategory ? 1 : 0
        }
        delete params.category_image
        if("local" in formState.category_image) {
            delete formState.category_image.local
        }

        const apiPromise = categoryService.addUpdateCategory(axiosPrivate, params, formState.category_image.server)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.addUpdateProductCategory) {
                showToast(props.crud === "create" ? "Category Added Successfully!" : "Category Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${CATEGORY_PREFIX}`)
            }
        })
    }

    const onCancel = (e) => {
        e.preventDefault()
        navigate(-1)
    }
    return (
        <>
            {
                showLoader ? <p>Loading...</p> :
                <form action='#'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Parent Category</label>
                                <DropDownTree 
                                    size="large"
                                    value={formState.category_parent_ids} 
                                    onChange={onChange}
                                    className="select" 
                                    data={treeData}
                                    textField={textField} 
                                    dataItemKey={dataItemKey} 
                                    selectField={selectField}
                                    subItemsField={subItemsField}
                                    expandField={expandField}
                                    onExpandChange={onExpandChange}
                                    filterable={true}
                                    onFilterChange={onFilterChange}
                                    filter={filter.value}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Category Name<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" placeholder='' name="category_title" value={formState.category_title} onChange={(e) => onChangeField(e, true)} disabled={disabled}/>                                        
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Category Slug<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" placeholder='' name="category_slug" value={formState.category_slug} onChange={(e) => onChangeField(e)} disabled={disabled}/>                                        
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Category Display Order<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" placeholder='' name="category_display_order" value={formState.category_display_order} onChange={(e) => onChangeField(e)} disabled={disabled}/>                                        
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='form-group mb-30'>
                                <label>Short Description</label>
                                <textarea rows={5} cols={5} className="form-control" placeholder="" name="category_short_description" value={formState.category_short_description} onChange={(e) => onChangeField(e)} disabled={disabled}/>                                        
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group mb-30'>
                                <label>Description</label>
                                <textarea rows={5} cols={5} className="form-control" placeholder="" name="category_description" value={formState.category_description} onChange={(e) => onChangeField(e)} disabled={disabled}/>                                        
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Upload Logo</label>
                                <ImageUpload
                                    fileName={formState.category_image.server}
                                    fileList={
                                    formState.category_image.server
                                        ? [
                                            {
                                            uid: formState.category_image,
                                            url:"local" in formState.category_image ? `${formState.category_image.local.url}` : `${CATEGORY_IMG}/${formState.category_image.server}`
                                            }
                                        ]
                                        : []
                                    }
                                    getFile={file => {
                                        setFormState({...formState, category_image: file});
                                    }}
                                    onRemove={() => setFormState({...formState, category_image: {server: ""}})}
                                    crud={props.crud}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row form-group'>
                        <div className='col-md-12'>
                        {(() => {
                            if(props.crud === "create") {
                                return (
                                    <a href="#" className="btn btn-primary btn-width mx-auto d-block" onClick={(e) => onSubmitHandler(e)}>Save</a>
                                )
                            }else if(props.crud === "read") {
                                return (
                                    <>
                                        <Link to={`${CATEGORY_PREFIX}/edit/${props.category_id}`} className="btn btn-primary btn-width mx-auto d-block">Edit</Link>
                                        <Link to={`${CATEGORY_PREFIX}`} className="btn btn-primary btn-width mx-auto d-block">Back</Link>
                                    </>
                                )
                            }else if(props.crud === "edit") {
                                return (
                                    <>
                                        <div className='mx-auto d-block btn-width mt-3'>
                                            <a href="#" className="btn btn-primary btn-width" onClick={(e) => onSubmitHandler(e)}>Save</a>
                                            <Link to={`${CATEGORY_PREFIX}`} className="btn btn-primary mx-3 btn-width">Back</Link>
                                            <a href="#" className="btn btn-primary btn-width" onClick={(e) => onCancel(e)}>Cancel</a>
                                        </div>
                                    </>
                                )
                            }
                        })()}
                        </div>
                    </div>
                </form>
            }
        </>
    )
}