export default [
    {
      name: "Afghanistan",
      dial_code: "+93",
      code: "AF",
      latitude: 33,
      longitude: 65,
    },
    {
      name: "Albania",
      dial_code: "+355",
      code: "AL",
      latitude: 41,
      longitude: 20,
    },
    {
      name: "Algeria",
      dial_code: "+213",
      code: "DZ",
      latitude: 28,
      longitude: 3,
    },
    {
      name: "AmericanSamoa",
      dial_code: "+1684",
      code: "AS",
      latitude: -14.3333,
      longitude: -170,
    },
    {
      name: "Andorra",
      dial_code: "+376",
      code: "AD",
      latitude: 42.5,
      longitude: 1.6,
    },
    {
      name: "Angola",
      dial_code: "+244",
      code: "AO",
      latitude: -12.5,
      longitude: 18.5,
    },
    {
      name: "Anguilla",
      dial_code: "+1264",
      code: "AI",
      latitude: 18.25,
      longitude: -63.1667,
    },
    {
      name: "Antarctica",
      dial_code: "+672",
      code: "AQ",
      latitude: -90,
      longitude: "0",
    },
    {
      name: "Antigua and Barbuda",
      dial_code: "+1268",
      code: "AG",
      latitude: 17.05,
      longitude: -61.8,
    },
    {
      name: "Argentina",
      dial_code: "+54",
      code: "AR",
      latitude: -34,
      longitude: -64,
    },
    {
      name: "Armenia",
      dial_code: "+374",
      code: "AM",
      latitude: 40,
      longitude: 45,
    },
    {
      name: "Aruba",
      dial_code: "+297",
      code: "AW",
      latitude: 12.5,
      longitude: -69.9667,
    },
    {
      name: "Australia",
      dial_code: "+61",
      code: "AU",
      latitude: -27,
      longitude: 133,
    },
    {
      name: "Austria",
      dial_code: "+43",
      code: "AT",
      latitude: 47.3333,
      longitude: 13.3333,
    },
    {
      name: "Azerbaijan",
      dial_code: "+994",
      code: "AZ",
      latitude: 40.5,
      longitude: 47.5,
    },
    {
      name: "Bahamas",
      dial_code: "+1242",
      code: "BS",
      latitude: 24.25,
      longitude: -76,
    },
    {
      name: "Bahrain",
      dial_code: "+973",
      code: "BH",
      latitude: 26,
      longitude: 50.55,
    },
    {
      name: "Bangladesh",
      dial_code: "+880",
      code: "BD",
      latitude: 24,
      longitude: 90,
    },
    {
      name: "Barbados",
      dial_code: "+1246",
      code: "BB",
      latitude: 13.1667,
      longitude: -59.5333,
    },
    {
      name: "Belarus",
      dial_code: "+375",
      code: "BY",
      latitude: 53,
      longitude: 28,
    },
    {
      name: "Belgium",
      dial_code: "+32",
      code: "BE",
      latitude: 50.8333,
      longitude: 4,
    },
    {
      name: "Belize",
      dial_code: "+501",
      code: "BZ",
      latitude: 17.25,
      longitude: -88.75,
    },
    {
      name: "Benin",
      dial_code: "+229",
      code: "BJ",
      latitude: 9.5,
      longitude: 2.25,
    },
    {
      name: "Bermuda",
      dial_code: "+1441",
      code: "BM",
      latitude: 32.3333,
      longitude: -64.75,
    },
    {
      name: "Bhutan",
      dial_code: "+975",
      code: "BT",
      latitude: 27.5,
      longitude: 90.5,
    },
    {
      name: "Bolivia, Plurinational State of Bolivia",
      dial_code: "+591",
      code: "BO",
      latitude: -17,
      longitude: -65,
    },
    {
      name: "Bosnia and Herzegovina",
      dial_code: "+387",
      code: "BA",
      latitude: 44,
      longitude: 18,
    },
    {
      name: "Botswana",
      dial_code: "+267",
      code: "BW",
      latitude: -22,
      longitude: 24,
    },
    {
      name: "Bouvet Island",
      dial_code: "+55",
      code: "BV",
      latitude: -54.4333,
      longitude: 3.4,
    },
    {
      name: "Brazil",
      dial_code: "+55",
      code: "BR",
      latitude: -10,
      longitude: -55,
    },
    {
      name: "British Indian Ocean Territory",
      dial_code: "+246",
      code: "IO",
      latitude: -6,
      longitude: 71.5,
    },
    {
      name: "Brunei Darussalam",
      dial_code: "+673",
      code: "BN",
      latitude: 4.5,
      longitude: 114.6667,
    },
    {
      name: "Bulgaria",
      dial_code: "+359",
      code: "BG",
      latitude: 43,
      longitude: 25,
    },
    {
      name: "Burkina Faso",
      dial_code: "+226",
      code: "BF",
      latitude: 13,
      longitude: -2,
    },
    {
      name: "Burundi",
      dial_code: "+257",
      code: "BI",
      latitude: -3.5,
      longitude: 30,
    },
    {
      name: "Cambodia",
      dial_code: "+855",
      code: "KH",
      latitude: 13,
      longitude: 105,
    },
    {
      name: "Cameroon",
      dial_code: "+237",
      code: "CM",
      latitude: 6,
      longitude: 12,
    },
    { name: "Canada", dial_code: "+1", code: "CA", latitude: 60, longitude: -95 },
    {
      name: "Cape Verde",
      dial_code: "+238",
      code: "CV",
      latitude: 16,
      longitude: -24,
    },
    {
      name: "Cayman Islands",
      dial_code: "+1345",
      code: "KY",
      latitude: 19.5,
      longitude: -80.5,
    },
    {
      name: "Central African Republic",
      dial_code: "+236",
      code: "CF",
      latitude: 7,
      longitude: 21,
    },
    { name: "Chad", dial_code: "+235", code: "TD", latitude: 15, longitude: 19 },
    {
      name: "Chile",
      dial_code: "+56",
      code: "CL",
      latitude: -30,
      longitude: -71,
    },
    { name: "China", dial_code: "+86", code: "CN", latitude: 35, longitude: 105 },
    {
      name: "Christmas Island",
      dial_code: "+61",
      code: "CX",
      latitude: -10.5,
      longitude: 105.6667,
    },
    {
      name: "Cocos (Keeling) Islands",
      dial_code: "+61",
      code: "CC",
      latitude: -12.5,
      longitude: 96.8333,
    },
    {
      name: "Colombia",
      dial_code: "+57",
      code: "CO",
      latitude: 4,
      longitude: -72,
    },
    {
      name: "Comoros",
      dial_code: "+269",
      code: "KM",
      latitude: -12.1667,
      longitude: 44.25,
    },
    { name: "Congo", dial_code: "+242", code: "CG", latitude: -1, longitude: 15 },
    {
      name: "Congo, The Democratic Republic of the",
      dial_code: "+243",
      code: "CD",
      latitude: "0",
      longitude: 25,
    },
    {
      name: "Cook Islands",
      dial_code: "+682",
      code: "CK",
      latitude: -21.2333,
      longitude: -159.7667,
    },
    {
      name: "Costa Rica",
      dial_code: "+506",
      code: "CR",
      latitude: 10,
      longitude: -84,
    },
    {
      name: "Cote d'Ivoire",
      dial_code: "+225",
      code: "CI",
      latitude: 8,
      longitude: -5,
    },
    {
      name: "Croatia",
      dial_code: "+385",
      code: "HR",
      latitude: 45.1667,
      longitude: 15.5,
    },
    {
      name: "Cuba",
      dial_code: "+53",
      code: "CU",
      latitude: 21.5,
      longitude: -80,
    },
    {
      name: "Cyprus",
      dial_code: "+357",
      code: "CY",
      latitude: 35,
      longitude: 33,
    },
    {
      name: "Czech Republic",
      dial_code: "+420",
      code: "CZ",
      latitude: 49.75,
      longitude: 15.5,
    },
    {
      name: "Denmark",
      dial_code: "+45",
      code: "DK",
      latitude: 56,
      longitude: 10,
    },
    {
      name: "Djibouti",
      dial_code: "+253",
      code: "DJ",
      latitude: 11.5,
      longitude: 43,
    },
    {
      name: "Dominica",
      dial_code: "+1767",
      code: "DM",
      latitude: 15.4167,
      longitude: -61.3333,
    },
    {
      name: "Dominican Republic",
      dial_code: "+1849",
      code: "DO",
      latitude: 19,
      longitude: -70.6667,
    },
    {
      name: "Ecuador",
      dial_code: "+593",
      code: "EC",
      latitude: -2,
      longitude: -77.5,
    },
    { name: "Egypt", dial_code: "+20", code: "EG", latitude: 27, longitude: 30 },
    {
      name: "El Salvador",
      dial_code: "+503",
      code: "SV",
      latitude: 13.8333,
      longitude: -88.9167,
    },
    {
      name: "Equatorial Guinea",
      dial_code: "+240",
      code: "GQ",
      latitude: 2,
      longitude: 10,
    },
    {
      name: "Eritrea",
      dial_code: "+291",
      code: "ER",
      latitude: 15,
      longitude: 39,
    },
    {
      name: "Estonia",
      dial_code: "+372",
      code: "EE",
      latitude: 59,
      longitude: 26,
    },
    {
      name: "Ethiopia",
      dial_code: "+251",
      code: "ET",
      latitude: 8,
      longitude: 38,
    },
    {
      name: "Falkland Islands (Malvinas)",
      dial_code: "+500",
      code: "FK",
      latitude: -51.75,
      longitude: -59,
    },
    {
      name: "Faroe Islands",
      dial_code: "+298",
      code: "FO",
      latitude: 62,
      longitude: -7,
    },
    {
      name: "Fiji",
      dial_code: "+679",
      code: "FJ",
      latitude: -18,
      longitude: 175,
    },
    {
      name: "Finland",
      dial_code: "+358",
      code: "FI",
      latitude: 64,
      longitude: 26,
    },
    { name: "France", dial_code: "+33", code: "FR", latitude: 46, longitude: 2 },
    {
      name: "French Guiana",
      dial_code: "+594",
      code: "GF",
      latitude: 4,
      longitude: -53,
    },
    {
      name: "French Polynesia",
      dial_code: "+689",
      code: "PF",
      latitude: -15,
      longitude: -140,
    },
    {
      name: "French Southern and Antarctic Lands",
      dial_code: "+262",
      code: "TF",
      latitude: -43,
      longitude: 67,
    },
    {
      name: "Gabon",
      dial_code: "+241",
      code: "GA",
      latitude: -1,
      longitude: 11.75,
    },
    {
      name: "Gambia",
      dial_code: "+220",
      code: "GM",
      latitude: 13.4667,
      longitude: -16.5667,
    },
    {
      name: "Georgia",
      dial_code: "+995",
      code: "GE",
      latitude: 42,
      longitude: 43.5,
    },
    { name: "Germany", dial_code: "+49", code: "DE", latitude: 51, longitude: 9 },
    { name: "Ghana", dial_code: "+233", code: "GH", latitude: 8, longitude: -2 },
    {
      name: "Gibraltar",
      dial_code: "+350",
      code: "GI",
      latitude: 36.1833,
      longitude: -5.3667,
    },
    { name: "Greece", dial_code: "+30", code: "GR", latitude: 39, longitude: 22 },
    {
      name: "Greenland",
      dial_code: "+299",
      code: "GL",
      latitude: 72,
      longitude: -40,
    },
    {
      name: "Grenada",
      dial_code: "+1473",
      code: "GD",
      latitude: 12.1167,
      longitude: -61.6667,
    },
    {
      name: "Guadeloupe",
      dial_code: "+590",
      code: "GP",
      latitude: 16.25,
      longitude: -61.5833,
    },
    {
      name: "Guam",
      dial_code: "+1671",
      code: "GU",
      latitude: 13.4667,
      longitude: 144.7833,
    },
    {
      name: "Guatemala",
      dial_code: "+502",
      code: "GT",
      latitude: 15.5,
      longitude: -90.25,
    },
    {
      name: "Guernsey",
      dial_code: "+44",
      code: "GG",
      latitude: 49.5,
      longitude: -2.56,
    },
    {
      name: "Guinea",
      dial_code: "+224",
      code: "GN",
      latitude: 11,
      longitude: -10,
    },
    {
      name: "Guinea-Bissau",
      dial_code: "+245",
      code: "GW",
      latitude: 12,
      longitude: -15,
    },
    {
      name: "Guyana",
      dial_code: "+592",
      code: "GY",
      latitude: 5,
      longitude: -59,
    },
    {
      name: "Haiti",
      dial_code: "+509",
      code: "HT",
      latitude: 19,
      longitude: -72.4167,
    },
    {
      name: "Heard Island and McDonald Islands",
      dial_code: "+672",
      code: "HM",
      latitude: -53.1,
      longitude: 72.5167,
    },
    {
      name: "Holy See (Vatican City State)",
      dial_code: "+379",
      code: "VA",
      latitude: 41.9,
      longitude: 12.45,
    },
    {
      name: "Honduras",
      dial_code: "+504",
      code: "HN",
      latitude: 15,
      longitude: -86.5,
    },
    {
      name: "Hong Kong",
      dial_code: "+852",
      code: "HK",
      latitude: 22.25,
      longitude: 114.1667,
    },
    {
      name: "Hungary",
      dial_code: "+36",
      code: "HU",
      latitude: 47,
      longitude: 20,
    },
    {
      name: "Iceland",
      dial_code: "+354",
      code: "IS",
      latitude: 65,
      longitude: -18,
    },
    { name: "India", dial_code: "+91", code: "IN", latitude: 20, longitude: 77 },
    {
      name: "Indonesia",
      dial_code: "+62",
      code: "ID",
      latitude: -5,
      longitude: 120,
    },
    {
      name: "Iran, Islamic Republic of",
      dial_code: "+98",
      code: "IR",
      latitude: 32,
      longitude: 53,
    },
    { name: "Iraq", dial_code: "+964", code: "IQ", latitude: 33, longitude: 44 },
    {
      name: "Ireland",
      dial_code: "+353",
      code: "IE",
      latitude: 53,
      longitude: -8,
    },
    {
      name: "Isle of Man",
      dial_code: "+44",
      code: "IM",
      latitude: 54.23,
      longitude: -4.55,
    },
    {
      name: "Israel",
      dial_code: "+972",
      code: "IL",
      latitude: 31.5,
      longitude: 34.75,
    },
    {
      name: "Italy",
      dial_code: "+39",
      code: "IT",
      latitude: 42.8333,
      longitude: 12.8333,
    },
    {
      name: "Jamaica",
      dial_code: "+1876",
      code: "JM",
      latitude: 18.25,
      longitude: -77.5,
    },
    { name: "Japan", dial_code: "+81", code: "JP", latitude: 36, longitude: 138 },
    {
      name: "Jersey",
      dial_code: "+44",
      code: "JE",
      latitude: 49.21,
      longitude: -2.13,
    },
    {
      name: "Jordan",
      dial_code: "+962",
      code: "JO",
      latitude: 31,
      longitude: 36,
    },
    {
      name: "Kazakhstan",
      dial_code: "+7",
      code: "KZ",
      latitude: 48,
      longitude: 68,
    },
    { name: "Kenya", dial_code: "+254", code: "KE", latitude: 1, longitude: 38 },
    {
      name: "Kiribati",
      dial_code: "+686",
      code: "KI",
      latitude: 1.4167,
      longitude: 173,
    },
    {
      name: "Korea, Democratic People's Republic of",
      dial_code: "+850",
      code: "KP",
      latitude: 40,
      longitude: 127,
    },
    {
      name: "Korea, Republic of",
      dial_code: "+82",
      code: "KR",
      latitude: 37,
      longitude: 127.5,
    },
    {
      name: "Kuwait",
      dial_code: "+965",
      code: "KW",
      latitude: 29.3375,
      longitude: 47.6581,
    },
    {
      name: "Kyrgyzstan",
      dial_code: "+996",
      code: "KG",
      latitude: 41,
      longitude: 75,
    },
    {
      name: "Lao People's Democratic Republic",
      dial_code: "+856",
      code: "LA",
      latitude: 18,
      longitude: 105,
    },
    {
      name: "Latvia",
      dial_code: "+371",
      code: "LV",
      latitude: 57,
      longitude: 25,
    },
    {
      name: "Lebanon",
      dial_code: "+961",
      code: "LB",
      latitude: 33.8333,
      longitude: 35.8333,
    },
    {
      name: "Lesotho",
      dial_code: "+266",
      code: "LS",
      latitude: -29.5,
      longitude: 28.5,
    },
    {
      name: "Liberia",
      dial_code: "+231",
      code: "LR",
      latitude: 6.5,
      longitude: -9.5,
    },
    {
      name: "Libyan Arab Jamahiriya",
      dial_code: "+218",
      code: "LY",
      latitude: 25,
      longitude: 17,
    },
    {
      name: "Liechtenstein",
      dial_code: "+423",
      code: "LI",
      latitude: 47.1667,
      longitude: 9.5333,
    },
    {
      name: "Lithuania",
      dial_code: "+370",
      code: "LT",
      latitude: 56,
      longitude: 24,
    },
    {
      name: "Luxembourg",
      dial_code: "+352",
      code: "LU",
      latitude: 49.75,
      longitude: 6.1667,
    },
    {
      name: "Macao",
      dial_code: "+853",
      code: "MO",
      latitude: 22.1667,
      longitude: 113.55,
    },
    {
      name: "Macedonia, The Former Yugoslav Republic of",
      dial_code: "+389",
      code: "MK",
      latitude: 41.8333,
      longitude: 22,
    },
    {
      name: "Madagascar",
      dial_code: "+261",
      code: "MG",
      latitude: -20,
      longitude: 47,
    },
    {
      name: "Malawi",
      dial_code: "+265",
      code: "MW",
      latitude: -13.5,
      longitude: 34,
    },
    {
      name: "Malaysia",
      dial_code: "+60",
      code: "MY",
      latitude: 2.5,
      longitude: 112.5,
    },
    {
      name: "Maldives",
      dial_code: "+960",
      code: "MV",
      latitude: 3.25,
      longitude: 73,
    },
    { name: "Mali", dial_code: "+223", code: "ML", latitude: 17, longitude: -4 },
    {
      name: "Malta",
      dial_code: "+356",
      code: "MT",
      latitude: 35.8333,
      longitude: 14.5833,
    },
    {
      name: "Marshall Islands",
      dial_code: "+692",
      code: "MH",
      latitude: 9,
      longitude: 168,
    },
    {
      name: "Martinique",
      dial_code: "+596",
      code: "MQ",
      latitude: 14.6667,
      longitude: -61,
    },
    {
      name: "Mauritania",
      dial_code: "+222",
      code: "MR",
      latitude: 20,
      longitude: -12,
    },
    {
      name: "Mauritius",
      dial_code: "+230",
      code: "MU",
      latitude: -20.2833,
      longitude: 57.55,
    },
    {
      name: "Mayotte",
      dial_code: "+262",
      code: "YT",
      latitude: -12.8333,
      longitude: 45.1667,
    },
    {
      name: "Mexico",
      dial_code: "+52",
      code: "MX",
      latitude: 23,
      longitude: -102,
    },
    {
      name: "Micronesia, Federated States of",
      dial_code: "+691",
      code: "FM",
      latitude: 6.9167,
      longitude: 158.25,
    },
    {
      name: "Moldova, Republic of",
      dial_code: "+373",
      code: "MD",
      latitude: 47,
      longitude: 29,
    },
    {
      name: "Monaco",
      dial_code: "+377",
      code: "MC",
      latitude: 43.7333,
      longitude: 7.4,
    },
    {
      name: "Mongolia",
      dial_code: "+976",
      code: "MN",
      latitude: 46,
      longitude: 105,
    },
    {
      name: "Montenegro",
      dial_code: "+382",
      code: "ME",
      latitude: 42,
      longitude: 19,
    },
    {
      name: "Montserrat",
      dial_code: "+1664",
      code: "MS",
      latitude: 16.75,
      longitude: -62.2,
    },
    {
      name: "Morocco",
      dial_code: "+212",
      code: "MA",
      latitude: 32,
      longitude: -5,
    },
    {
      name: "Mozambique",
      dial_code: "+258",
      code: "MZ",
      latitude: -18.25,
      longitude: 35,
    },
    {
      name: "Myanmar",
      dial_code: "+95",
      code: "MM",
      latitude: 22,
      longitude: 98,
    },
    {
      name: "Namibia",
      dial_code: "+264",
      code: "NA",
      latitude: -22,
      longitude: 17,
    },
    {
      name: "Nauru",
      dial_code: "+674",
      code: "NR",
      latitude: -0.5333,
      longitude: 166.9167,
    },
    { name: "Nepal", dial_code: "+977", code: "NP", latitude: 28, longitude: 84 },
    {
      name: "Netherlands",
      dial_code: "+31",
      code: "NL",
      latitude: 52.5,
      longitude: 5.75,
    },
    {
      name: "Netherlands Antilles",
      dial_code: "+599",
      code: "AN",
      latitude: 12.25,
      longitude: -68.75,
    },
    {
      name: "New Caledonia",
      dial_code: "+687",
      code: "NC",
      latitude: -21.5,
      longitude: 165.5,
    },
    {
      name: "New Zealand",
      dial_code: "+64",
      code: "NZ",
      latitude: -41,
      longitude: 174,
    },
    {
      name: "Nicaragua",
      dial_code: "+505",
      code: "NI",
      latitude: 13,
      longitude: -85,
    },
    { name: "Niger", dial_code: "+227", code: "NE", latitude: 16, longitude: 8 },
    {
      name: "Nigeria",
      dial_code: "+234",
      code: "NG",
      latitude: 10,
      longitude: 8,
    },
    {
      name: "Niue",
      dial_code: "+683",
      code: "NU",
      latitude: -19.0333,
      longitude: -169.8667,
    },
    {
      name: "Norfolk Island",
      dial_code: "+672",
      code: "NF",
      latitude: -29.0333,
      longitude: 167.95,
    },
    {
      name: "Northern Mariana Islands",
      dial_code: "+1670",
      code: "MP",
      latitude: 15.2,
      longitude: 145.75,
    },
    { name: "Norway", dial_code: "+47", code: "NO", latitude: 62, longitude: 10 },
    { name: "Oman", dial_code: "+968", code: "OM", latitude: 21, longitude: 57 },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
      latitude: 30,
      longitude: 70,
    },
    {
      name: "Palau",
      dial_code: "+680",
      code: "PW",
      latitude: 7.5,
      longitude: 134.5,
    },
    {
      name: "Palestinian Territory, Occupied",
      dial_code: "+970",
      code: "PS",
      latitude: 32,
      longitude: 35.25,
    },
    {
      name: "Panama",
      dial_code: "+507",
      code: "PA",
      latitude: 9,
      longitude: -80,
    },
    {
      name: "Papua New Guinea",
      dial_code: "+675",
      code: "PG",
      latitude: -6,
      longitude: 147,
    },
    {
      name: "Paraguay",
      dial_code: "+595",
      code: "PY",
      latitude: -23,
      longitude: -58,
    },
    { name: "Peru", dial_code: "+51", code: "PE", latitude: -10, longitude: -76 },
    {
      name: "Philippines",
      dial_code: "+63",
      code: "PH",
      latitude: 13,
      longitude: 122,
    },
    {
      name: "Pitcairn",
      dial_code: "+870",
      code: "PN",
      latitude: -24.7,
      longitude: -127.4,
    },
    { name: "Poland", dial_code: "+48", code: "PL", latitude: 52, longitude: 20 },
    {
      name: "Portugal",
      dial_code: "+351",
      code: "PT",
      latitude: 39.5,
      longitude: -8,
    },
    {
      name: "Puerto Rico",
      dial_code: "+1939",
      code: "PR",
      latitude: 18.25,
      longitude: -66.5,
    },
    {
      name: "Qatar",
      dial_code: "+974",
      code: "QA",
      latitude: 25.5,
      longitude: 51.25,
    },
    {
      name: "Réunion",
      dial_code: "+262",
      code: "RE",
      latitude: -21.1,
      longitude: 55.6,
    },
    {
      name: "Romania",
      dial_code: "+40",
      code: "RO",
      latitude: 46,
      longitude: 25,
    },
    { name: "Russia", dial_code: "+7", code: "RU", latitude: 60, longitude: 100 },
    {
      name: "Rwanda",
      dial_code: "+250",
      code: "RW",
      latitude: -2,
      longitude: 30,
    },
    {
      name: "Saint Helena, Ascension and Tristan Da Cunha",
      dial_code: "+290",
      code: "SH",
      latitude: -15.9333,
      longitude: -5.7,
    },
    {
      name: "Saint Kitts and Nevis",
      dial_code: "+1869",
      code: "KN",
      latitude: 17.3333,
      longitude: -62.75,
    },
    {
      name: "Saint Lucia",
      dial_code: "+1758",
      code: "LC",
      latitude: 13.8833,
      longitude: -61.1333,
    },
    {
      name: "Saint Pierre and Miquelon",
      dial_code: "+508",
      code: "PM",
      latitude: 46.8333,
      longitude: -56.3333,
    },
    {
      name: "Saint Vincent and the Grenadines",
      dial_code: "+1784",
      code: "VC",
      latitude: 13.25,
      longitude: -61.2,
    },
    {
      name: "Samoa",
      dial_code: "+685",
      code: "WS",
      latitude: -13.5833,
      longitude: -172.3333,
    },
    {
      name: "San Marino",
      dial_code: "+378",
      code: "SM",
      latitude: 43.7667,
      longitude: 12.4167,
    },
    {
      name: "Sao Tome and Principe",
      dial_code: "+239",
      code: "ST",
      latitude: 1,
      longitude: 7,
    },
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
      latitude: 25,
      longitude: 45,
    },
    {
      name: "Senegal",
      dial_code: "+221",
      code: "SN",
      latitude: 14,
      longitude: -14,
    },
    {
      name: "Serbia",
      dial_code: "+381",
      code: "RS",
      latitude: 44,
      longitude: 21,
    },
    {
      name: "Seychelles",
      dial_code: "+248",
      code: "SC",
      latitude: -4.5833,
      longitude: 55.6667,
    },
    {
      name: "Sierra Leone",
      dial_code: "+232",
      code: "SL",
      latitude: 8.5,
      longitude: -11.5,
    },
    {
      name: "Singapore",
      dial_code: "+65",
      code: "SG",
      latitude: 1.3667,
      longitude: 103.8,
    },
    {
      name: "Slovakia",
      dial_code: "+421",
      code: "SK",
      latitude: 48.6667,
      longitude: 19.5,
    },
    {
      name: "Slovenia",
      dial_code: "+386",
      code: "SI",
      latitude: 46,
      longitude: 15,
    },
    {
      name: "Solomon Islands",
      dial_code: "+677",
      code: "SB",
      latitude: -8,
      longitude: 159,
    },
    {
      name: "Somalia",
      dial_code: "+252",
      code: "SO",
      latitude: 10,
      longitude: 49,
    },
    {
      name: "South Africa",
      dial_code: "+27",
      code: "ZA",
      latitude: -29,
      longitude: 24,
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      dial_code: "+500",
      code: "GS",
      latitude: -54.5,
      longitude: -37,
    },
    { name: "Spain", dial_code: "+34", code: "ES", latitude: 40, longitude: -4 },
    {
      name: "Sri Lanka",
      dial_code: "+94",
      code: "LK",
      latitude: 7,
      longitude: 81,
    },
    { name: "Sudan", dial_code: "+249", code: "SD", latitude: 15, longitude: 30 },
    {
      name: "Suriname",
      dial_code: "+597",
      code: "SR",
      latitude: 4,
      longitude: -56,
    },
    {
      name: "Svalbard and Jan Mayen",
      dial_code: "+47",
      code: "SJ",
      latitude: 78,
      longitude: 20,
    },
    {
      name: "Swaziland",
      dial_code: "+268",
      code: "SZ",
      latitude: -26.5,
      longitude: 31.5,
    },
    { name: "Sweden", dial_code: "+46", code: "SE", latitude: 62, longitude: 15 },
    {
      name: "Switzerland",
      dial_code: "+41",
      code: "CH",
      latitude: 47,
      longitude: 8,
    },
    {
      name: "Syrian Arab Republic",
      dial_code: "+963",
      code: "SY",
      latitude: 35,
      longitude: 38,
    },
    {
      name: "Taiwan",
      dial_code: "+886",
      code: "TW",
      latitude: 23.5,
      longitude: 121,
    },
    {
      name: "Tajikistan",
      dial_code: "+992",
      code: "TJ",
      latitude: 39,
      longitude: 71,
    },
    {
      name: "Tanzania, United Republic of",
      dial_code: "+255",
      code: "TZ",
      latitude: -6,
      longitude: 35,
    },
    {
      name: "Thailand",
      dial_code: "+66",
      code: "TH",
      latitude: 15,
      longitude: 100,
    },
    {
      name: "Timor-Leste",
      dial_code: "+670",
      code: "TL",
      latitude: -8.55,
      longitude: 125.5167,
    },
    {
      name: "Togo",
      dial_code: "+228",
      code: "TG",
      latitude: 8,
      longitude: 1.1667,
    },
    {
      name: "Tokelau",
      dial_code: "+690",
      code: "TK",
      latitude: -9,
      longitude: -172,
    },
    {
      name: "Tonga",
      dial_code: "+676",
      code: "TO",
      latitude: -20,
      longitude: -175,
    },
    {
      name: "Trinidad and Tobago",
      dial_code: "+1868",
      code: "TT",
      latitude: 11,
      longitude: -61,
    },
    {
      name: "Tunisia",
      dial_code: "+216",
      code: "TN",
      latitude: 34,
      longitude: 9,
    },
    { name: "Turkey", dial_code: "+90", code: "TR", latitude: 39, longitude: 35 },
    {
      name: "Turkmenistan",
      dial_code: "+993",
      code: "TM",
      latitude: 40,
      longitude: 60,
    },
    {
      name: "Turks and Caicos Islands",
      dial_code: "+1649",
      code: "TC",
      latitude: 21.75,
      longitude: -71.5833,
    },
    {
      name: "Tuvalu",
      dial_code: "+688",
      code: "TV",
      latitude: -8,
      longitude: 178,
    },
    { name: "Uganda", dial_code: "+256", code: "UG", latitude: 1, longitude: 32 },
    {
      name: "Ukraine",
      dial_code: "+380",
      code: "UA",
      latitude: 49,
      longitude: 32,
    },
    {
      name: "United Arab Emirates",
      dial_code: "+971",
      code: "AE",
      latitude: 24,
      longitude: 54,
    },
    {
      name: "United Kingdom",
      dial_code: "+44",
      code: "GB",
      latitude: 54,
      longitude: -2,
    },
    {
      name: "United States",
      dial_code: "+1",
      code: "US",
      latitude: 38,
      longitude: -97,
    },
    {
      name: "United States Minor Outlying Islands",
      dial_code: "+1581",
      code: "UM",
      latitude: 19.2833,
      longitude: 166.6,
    },
    {
      name: "Uruguay",
      dial_code: "+598",
      code: "UY",
      latitude: -33,
      longitude: -56,
    },
    {
      name: "Uzbekistan",
      dial_code: "+998",
      code: "UZ",
      latitude: 41,
      longitude: 64,
    },
    {
      name: "Vanuatu",
      dial_code: "+678",
      code: "VU",
      latitude: -16,
      longitude: 167,
    },
    {
      name: "Venezuela, Bolivarian Republic of",
      dial_code: "+58",
      code: "VE",
      latitude: 8,
      longitude: -66,
    },
    {
      name: "Vietnam",
      dial_code: "+84",
      code: "VN",
      latitude: 16,
      longitude: 106,
    },
    {
      name: "Virgin Islands, British",
      dial_code: "+1284",
      code: "VG",
      latitude: 18.5,
      longitude: -64.5,
    },
    {
      name: "Virgin Islands, U.S.",
      dial_code: "+1340",
      code: "VI",
      latitude: 18.3333,
      longitude: -64.8333,
    },
    {
      name: "Wallis and Futuna",
      dial_code: "+681",
      code: "WF",
      latitude: -13.3,
      longitude: -176.2,
    },
    {
      name: "Western Sahara",
      dial_code: "+732",
      code: "EH",
      latitude: 24.5,
      longitude: -13,
    },
    { name: "Yemen", dial_code: "+967", code: "YE", latitude: 15, longitude: 48 },
    {
      name: "Zambia",
      dial_code: "+260",
      code: "ZM",
      latitude: -15,
      longitude: 30,
    },
    {
      name: "Zimbabwe",
      dial_code: "+263",
      code: "ZW",
      latitude: -20,
      longitude: 30,
    },
  ];
  