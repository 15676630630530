import { useEffect, useReducer } from "react"
import { ACTIONS } from "../utils/Constants"
import { userService } from "../Services/UserService"

function merchantListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, merchantData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}

function useMerchantList(axios, params) {
    const [state, dispatch] = useReducer(merchantListReducer, {loading: false, merchantData: [], error: "", total: 0})
    useEffect(() => {
        if(params.merchant_id){
            dispatch({type: ACTIONS.MAKE_REQUEST})
            userService.getMerchantsList(axios, params).then(res => {
                dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.merchantListing.list, total: res.data.merchantListing.total}})
            })
        }
        
    }, [params])
    return state
}

export {
    useMerchantList
}