import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

const ErrorPage = () => {
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Error</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3 className="page-title">Page Not Found!</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ErrorPage