import { useEffect, useReducer } from "react"
import { ACTIONS } from "../utils/Constants"
import { menuService } from "../Services/MenuService"

function storeMenuListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, menuData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}

function useStoreMenuList(axios, params) {
    const [state, dispatch] = useReducer(storeMenuListReducer, {loading: false, menuData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        menuService.getStoreMenuList(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.storeMenuListing.list, total: res.data.storeMenuListing.total}})
        })
    }, [params])
    return state
}

export {
    useStoreMenuList
}