import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { AREA_PREFIX, F_D_CHANGE, MERCHANT_PREFIX, TOAST_MSG_TYPE, getConstantData } from '../utils/Constants';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { useLogList } from '../hooks/RouteHooks';

const ChangeLog = () => {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo } = useAuth()
    const [params, setParams] = useState({limit: 10, offset: 0, search: "", field: "id", sort: "DESC", eventType: "DEL_FL_DR_CHNG"})
    const {logData, loading, error, total} = useLogList(axiosPrivate, params)
    console.log(logData)
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Route',
                render: (text, record) => {
                    return `${record.event_new_data.route_name}`
                }
            },
            {
                title: 'Old/New Fleet',
                render: (text, record) => {
                    return `${record.event_prev_data.fleet_licence_number}/${record.event_new_data.fleet_licence_number}`
                }
            },
            {
                title: 'Old/New Driver',
                render: (text, record) => {
                    return `${record.event_prev_data.driver_name}/${record.event_new_data.driver_name}`
                }
            },
            {
                title: 'Order Date',
                render: (text, record) => {
                    return `${record.event_new_data.order_delivery_date}`
                }
            },
        ]
        return columns
    }, [auth.permissions])

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Log</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Manage Log</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Logs</li>
                            </ul>
                        </div>
                        {
                            auth.permissions.includes("ChangeDeliveryFleetDriver") ?
                            <div className="col-sm-6">
                                <Link to={`${F_D_CHANGE}/create`} className="btn btn-primary btn-width float-right d-block">Change</Link>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 10, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={logData}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default ChangeLog;