import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { FLEET_PREFIX, TOAST_MSG_TYPE,CUSTOMER_PREFIX } from '../../utils/Constants';
import { DatePicker } from 'antd';
import moment from 'moment';
import { fleetService } from '../../Services/FleetService';
import { CKEditor } from 'ckeditor4-react';
import { useCityList } from '../../hooks/RouteHooks';

const initialState = {id: 0, fleet_make: "", fleet_model: "", fleet_year: "", fleetYear: "", insurance_cost: 0, insurance_expiry: "", insuranceExpiry: "",fleet_licence_number: "", fleet_order_limit: 0, notes: ""}
const FleetForm = (props) => {
    const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
	const [cityParam, seCityParams] = useState({paginated: false, offset:0, limit: 10, field: "area_name", sort: "ASC", search: ""})
    const {citiesData, loading:Floading, error:Eerror, total:allTotal} = useCityList(axiosPrivate, cityParam);

    useEffect(() => {
        if(props.customer_id) {
            fleetService.getCustomerByID(axiosPrivate, props.customer_id).then(res => {
                if(res.data.customerDetailsByid) {
                    const data = res.data.customerDetailsByid.customer_details
                    setFormState(data)
                    setShowLoader(false)
                }else {
                    navigate(`${CUSTOMER_PREFIX}`)
                }
            })    
        }else {
            setShowLoader(false)
        }
    }, [props.fleet_id])

    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
    
    const filterChange = (e, name, type) => {
        let newVal = e.target.value
        
		setFormState({...formState, [name]: newVal})
    }
    const submitHandler = (e) => {
        e.preventDefault()
        if(!formState.first_name) {
            showToast("Please Fill first_name", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.phone) {
            showToast("Please Fill phone", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.country_phone_code) {
            showToast("Please Fill country_phone_code", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.email) {
            showToast("Please Fill email", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.customer_address_city) {
            showToast("Please Fill customer_address_city", TOAST_MSG_TYPE.ERROR)
            return
        }
        let param = {
            customer_id: formState.id,
            first_name: formState.first_name,
            last_name: formState.last_name,
            email: formState.email,
            phone: formState.phone,
            country_phone_code: formState.country_phone_code,
            customer_address_city: formState.customer_address_city
        }
        const apiPromise = fleetService.updateCustomerDetails(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.updateCustomerDetails) {
                showToast(props.crud === "create" ? "Customer Added Successfully!" : "Customer Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${CUSTOMER_PREFIX}`)
            }
        })
    }

    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>First Name*</label>
                            <input type="text" className='form-control' name='first_name' value={formState.first_name} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='form-group'>
                            <label>Last Name</label>
                            <input type="text" className='form-control' name='last_name' value={formState.last_name} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-4'>
                        <div className='form-group'>
                            <label>Email</label>
                            <input type="text" className='form-control' name='email' value={formState.email} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-1'>
                        <div className='form-group'>
                            <label>Phone Code</label>
                            <input type="text" className='form-control' name='country_phone_code' value={formState.country_phone_code} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Phone</label>
                            <input type="text" className='form-control' name='phone' value={formState.phone} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
						<label className='mobile-lable-spacing'>City:</label>
						<select className='form-control table-search' value={formState.customer_address_city} onChange={(e) => filterChange(e, "customer_address_city", "string")}>
						<option value={""}>Select City</option>
						{
							citiesData.map((city) => {
								return (
									<option key={city.id} value={city.area_name}>{city.area_name}</option>
								)
							})
						}
						</select>
					</div>
                    
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default FleetForm