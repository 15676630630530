import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom';
import { AREA_PREFIX, F_D_CHANGE, TOAST_MSG_TYPE } from '../../utils/Constants';
import { routeService } from '../../Services/RouteService';
import { useRouteList } from '../../hooks/RouteHooks';
import { useTeamMemberList } from '../../hooks/MemberHooks';
import { useFleetList } from '../../hooks/FleetHooks';
import { Select } from 'antd';

const initialState = {route_id: "", old_fleet: "", new_fleet: "", driver: ""}
const ChangeForm = (props) => {
    const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState})
    const [routeFleets, setRouteFleets] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [routeParams, setRouteParams] = useState({limit: 0, offset: 0, search: "", field: "route_name", sort: "ASC", paginated: false})
    const {routeData, loading: routeLoading} = useRouteList(axiosPrivate, routeParams)
    
    const [teamParams, setTeamParams] = useState({limit: 0, offset: 0, search: "", field: "id", sort: "ASC", paginated: false})
    const {memberData, loading: teamLoading} = useTeamMemberList(axiosPrivate, teamParams)
    
    const [params, setParams] = useState({limit: 0, offset: 0, search: "", field: "id", sort: "ASC", paginated: false})
    const {fleetData, loading: fleetLoading} = useFleetList(axiosPrivate, params)
    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeRoute = (e) => {
        const routeObj = routeData.find((route) => route.id == e)
        if(routeObj) {
            let fleetArray = []
            for(let i=0;i<routeObj.route_fleets.length;i++) {
                let fleetObj = fleetData.find((fleet) => fleet.id == routeObj.route_fleets[i])
                if(fleetObj) {
                    fleetArray.push({
                        ...fleetObj
                    })
                }
            }
            setRouteFleets((prev) => {
                return [
                    ...fleetArray,
                ]
            })
        }
        setFormState((prev) => {
            return {
                ...prev,
                route_id: e,
            }
        })
    }
    
    const onChangeOldFleet = (e) => {
        setFormState({...formState, old_fleet: e})
    }

    const onChangeFleet = (e) => {
        let driver = memberData.find((member) => member.fleet_id == e)
        setFormState({...formState, new_fleet: e, driver: driver.id})
    }

    const onChangeDriver = (e) => {
        setFormState({...formState, driver: e})
    }

    const submitHandler = (e) => {
        e.preventDefault()
        if(!formState.route_id) {
            showToast("Please Select Route", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.old_fleet) {
            showToast("Please Select Current Fleet", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.new_fleet) {
            showToast("Please Select New Fleet", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.driver) {
            showToast("Please Choose Driver for new fleet", TOAST_MSG_TYPE.ERROR)
            return
        }
        const param = {
            route_id: formState.route_id,
            current_fleet_id: formState.old_fleet,
            new_fleet_id: formState.new_fleet,
            driver_id: formState.driver
        }
        const apiPromise = routeService.changeDriverOrFleet(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.changeFleetAndDriverTodayOrders) {
                showToast(props.crud === "create" ? "Updated Successfully!" : "Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${F_D_CHANGE}`)
            }
        })
    }

    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label>Select Route*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.route_id}
                                options={routeData}
                                onChange={onChangeRoute}
                                optionFilterProp={"route_name"}
                                fieldNames={{label: "route_name", value: "id"}}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Current Fleet*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.old_fleet}
                                options={routeFleets}
                                onChange={onChangeOldFleet}
                                optionFilterProp={"fleet_licence_number"}
                                fieldNames={{label: "fleet_licence_number", value: "id"}}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>New Fleet*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.new_fleet}
                                options={formState.route_id ? fleetData : []}
                                onChange={onChangeFleet}
                                optionFilterProp={"fleet_licence_number"}
                                fieldNames={{label: "fleet_licence_number", value: "id"}}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Driver*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.driver}
                                options={formState.route_id ? memberData : []}
                                onChange={onChangeDriver}
                                optionFilterProp={"name"}
                                fieldNames={{label: "name", value: "id"}}
                            />
                        </div>
                    </div>
                    {/* <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Min. Order Amount*</label>
                            <input type="text" className='form-control' name='min_order_amount' value={formState.min_order_amount} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Shipping Charges*</label>
                            <input type="text" className='form-control' name='shipping_charges' value={formState.shipping_charges} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Order Amount for Free Shipping*</label>
                            <input type="text" className='form-control' name='free_shipping_amount' value={formState.free_shipping_amount} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div> */}
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default ChangeForm