import { useEffect, useReducer } from "react"
import { ACTIONS } from "../utils/Constants"
import { fleetService } from "../Services/FleetService"
import { teamService } from "../Services/TeamService"

function memnerListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, memberData: action.payload.data, total: action.payload.total}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}

function useTeamMemberList(axios, params) {
    const [state, dispatch] = useReducer(memnerListReducer, {loading: false, memberData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        teamService.getMemberListing(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.teamMemberListingCMS.list, total: res.data.teamMemberListingCMS.total}})
        })
    }, [params])
    return state
}

export {
    useTeamMemberList
}