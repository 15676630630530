class ProductService {
	getALLProductsExcel(axios, params) {
        let body = {
            query: `
                query {
                    excelProductsList
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	sendNotifications(axios, params, notice_image) {
        let query = `
                mutation($title: String!, $message: String!, $external_link: String, $notification_type: String, $send_to: JSON,$firebase_topic_name: String!,$customer_city: String,$category_id: Int,$product_id:Int,$schedule_date: Int,$notice_image:Upload) {
                    sendNotifications(
                        title: $title
                        message: $message
                        external_link: $external_link
                        notification_type: $notification_type
                        send_to: $send_to
						firebase_topic_name: $firebase_topic_name
						customer_city: $customer_city
						category_id: $category_id
						product_id: $product_id
						schedule_date: $schedule_date
						notice_image: $notice_image
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    title: params.title,
                    message: params.message,
                    external_link: params.external_link,
                    notification_type: params.notification_type,
                    send_to: params.send_to,
					firebase_topic_name: params.firebase_topic_name,
					customer_city: params.customer_city,
					category_id: Number(params.category_id),
					product_id: Number(params.product_id),
					schedule_date: Number(params.schedule_date)
                }
            }
        if(typeof notice_image === "object") {
            let formData = new FormData()
            formData.append("operations", JSON.stringify(operations))
            const map = {
                "0": ["variables.notice_image"]
            }
            formData.append("map", JSON.stringify(map))
            formData.append("0", notice_image)
            return axios.post('', formData).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }else {
            if(typeof notice_image === "string" && notice_image !== "") {
                operations.variables.notice_image = notice_image
            }
            return axios.post('', JSON.stringify(operations)).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }
    }
    pushNotificationCustomers(axios, params) {
        let body = {
            query: `
                query {
                    pushNotificationCustomers(
					customer_city: "${params.customer_city}"
					search_string: "${params.search_string}"
					order_customers: ${params.order_customers ? params.order_customers : false}
					order_date: "${params.order_date}"
					){
						id
						email
						customer_name
						phone
						country_phone_code
						app_token
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getCustomers(axios, params) {
        let body = {
            query: `
                query {
                    customersListing(
                        paginated: ${params.paginated != undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
						customer_address_city: "${params.customer_address_city}"
						customer_type: "${params.customer_type}"
                    ) {
                        list {
							first_name
							last_name
							email
							phone
							country_phone_code
							referred_by
							referral_code
							reward_points
							createdAt
							updatedAt
							account_status
							total_orders
							id
							cart_id
							customer_city
							base_os
							os_version
							brand
							customer_address_city
							total_products_in_cart
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getNotificationCustomers(axios, params) {
        let body = {
            query: `
                query {
                    notificationCustomersList(
                        paginated: ${params.paginated != undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
						id: ${params.id}
                    ) {
                        list {
							first_name
							last_name
							email
							phone
							country_phone_code
							referred_by
							referral_code
							reward_points
							createdAt
							updatedAt
							account_status
							total_orders
							id
							cart_id
							customer_city
							base_os
							os_version
							brand
							customer_address_city
							total_products_in_cart
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getMerchantProducts(axios, params) {
        let body = {
            query: `
                query {
                    productListingAdmin(
                        paginated: ${params.paginated != undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            product_title
                            product_slug
                            product_sku
                            product_status
                            product_display_order
                            store_name
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	productVariantListingAdmin(axios, params) {
        let body = {
            query: `
                query {
                    productVariantListingAdmin(
                        paginated: ${params.paginated != undefined ? params.paginated : true}
                        limit: ${params.limit}
						merchant_id: ${params.merchant_id ? params.merchant_id : 0}
						store_id: ${params.store_id ? params.store_id : 0}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
						product_brand: "${params.product_brand ? params.product_brand : ""}"
						product_supplier: "${params.product_supplier ? params.product_supplier : ""}"
						stockFilter: "${params.stockFilter ? params.stockFilter : ""}"
						product_id: ${params.product_id ? params.product_id : 0}
                    ) {
                        list {
                            id
                            product_title
                            product_slug
                            product_sku
							product_brand
                            product_status
                            product_display_order
                            store_name
							variant_value
							variant_qty
							variant_stock
							variant_price
							variant_discounted_price
							varaint_supplier_price
							variant_id
							variant_status
                        }
                        total
						merchants
						stores
						product_brands
	                    product_suppliers
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    uploadProductCsv(axios, csvFile) {
        let query = `
                mutation($csvFile: Upload!) {
                    updloadCsvAddGrocery(
                        csvFile: $csvFile
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    csvFile: null,
                }
            }
        if(typeof csvFile === "object") {
            let formData = new FormData()
            formData.append("operations", JSON.stringify(operations))
            const map = {
                "0": ["variables.csvFile"]
            }
            formData.append("map", JSON.stringify(map))
            formData.append("0", csvFile)
            return axios.post('', formData).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }else {
            return Promise.reject("File Not Selected")
        }
    }

    changeProductStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeProductStatus(
                        product_id: ${params.id},
                        product_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	changeProductVariantStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeProductVariantStatus(
                        variant_id: ${params.id},
                        variant_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	changeCustomerStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeCustomerStatus(
                        customer_id: ${params.id},
                        account_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	deleteProductsTags(axios) {
        let body = {
            query: `
                mutation {
                    deleteAllProductsAndTags
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    searchProduct(axios, params) {
        let body = {
            query: `
                query {
                    searchProductsByOrderID(
                        order_id: "${params.orderID}",
                        searchTerm: "${params.search}"
                    ) {
                        list {
                            variant_id
                            product_id
                            product_title
                            product_brand
                            variant_name
                            variant_value
                            variant_price
                            product_rackid
                            variant_discounted_price
                            variant_qty
                        }
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    getOrderSlots(axios, date, city) {
        let body = {
            query: `
                query {
                    shippingSlotsByCity(
                        customer_city: "${city}",
                        customer_date: "${date}"
                    ) {
                        shipping_date
                        day_name
                        available_slots {
							id
							available_slots
							shipping_slot_status
							enter_time
							shipping_date
							exit_time
							day_name
							area_name
							min_order_amount
							shipping_charges
							route_ids
                        }
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getCardItems(axios, card_id) {
        let body = {
            query: `
                query {
                    cartItemsListing(
                        cart_id: "${card_id}"
                    ) {
                      cart_items{
product_id						  
						product_title
						product_brand
						variant_name
						variant_value
						variant_price
						variant_discounted_price
						variant_qty
						quantity
					  }
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    productDetailsById(axios, id) {
        let body = {
            query: `
                query {
                    productDetailsById(
                        product_id: ${id}
                    ) {
                        id
                        product_sku
                        product_slug
                        product_status
                        product_title
                        store_name
                        product_display_order
                        store_id
                        merchant_id
                        product_categories
                        product_logo
                        product_images
                        menu_layout
                        product_rating
                        product_description
                        product_other_details
                        product_brand
                        product_supplier
                        product_rackid
                        product_barcode
                        product_hst
                        product_short_description
                        product_in_stock
                        top_selling
                        product_uploaded_image
                        product_tags
                        product_isfree
                        related_products
						top_deal_missingpop
	                    top_selling_missingpop
                        product_variants{
                            variant_qty
                            variant_name
                            variant_value
                            variant_stock
                            variant_price
                            variant_discounted_price
                            id
                            variant_barcode
                            variant_rackid
                            varaint_supplier_price
                            qty_per_user
                            variant_status
                            variant_image
                            variant_uploaded_image
							varaint_total_weight
							varaint_length
							varaint_width
							varaint_height
                        }
                        meta_data{
                            meta_desc
                            meta_title
                            meta_keywords                    
                        }
                    }
                }
            `
        };
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    productStoreNameList(axios) {
        let body = {
            query: `
                query {
                    merchantStoreListing(
                        paginated: false
                    ) {
                        list {
                            store_name
                            store_id
                        }
                    }
                }
            `
        };
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    productCategoryList(axios, store_id) {
        let body = {
            query: `
                query {
                    multiStoreMenuListing(
                        paginated: false
                        store_id: [${store_id}]
                    ) {
                        list {
                            main_cat_id
                            main_cat_title
                            subcats{
                              menu_item_id
                              child_cat_title
                           }
                        }
                    }
                }
            `
        };
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    addUpdateProduct(axios, formState, productId, storeId) {
        const transformedVariants = formState.product_variants?.map(variant => {
            const variantData = {
                id: parseInt(variant.id),
                variant_name: variant.variant_name,
                variant_value: variant.variant_value,
                variant_stock: variant.variant_stock,
                variant_price: variant.variant_price,
                variant_discounted_price: variant.variant_discounted_price,
                variant_qty: variant.variant_qty,
                qty_per_user: variant.qty_per_user,
                variant_barcode: variant.variant_barcode,
                variant_rackid: variant.variant_rackid,
                variant_status: variant.variant_status,
                varaint_supplier_price: variant.varaint_supplier_price,
				varaint_total_weight: variant.varaint_total_weight,
				varaint_length: variant.varaint_length,
				varaint_width: variant.varaint_width,
				varaint_height: variant.varaint_height,
            };
        
          try {
                const fileObject = JSON.parse(variant.variant_image);
                if (typeof fileObject === 'object' && fileObject !== null) {
                    variantData.variant_image = fileObject;
                    variantData.variant_uploaded_image =  fileObject.name;
                    variantData.originFileObj = variant.originFileObj;
                 }
            } catch (error) {
                variantData.variant_image = null;
                variantData.variant_uploaded_image = variant.variant_image;
                variantData.originFileObj = variant.originFileObj;
              }
            return variantData;
        });

        const formattedVariants = transformedVariants?.map(variant => ({
            id: variant.id,
            variant_name: String(variant.variant_name),
            variant_value: String(variant.variant_value),
            variant_stock: parseInt(variant.variant_stock),
            variant_price: parseFloat(variant.variant_price),
            variant_discounted_price: parseFloat(variant.variant_discounted_price),
            variant_qty: parseInt(variant.variant_qty),
            qty_per_user: parseInt(variant.qty_per_user),
            variant_barcode: String(variant.variant_barcode),
            variant_rackid: String(variant.variant_rackid),
            variant_status: variant.variant_status,
            varaint_supplier_price: parseFloat(variant.varaint_supplier_price),
            variant_image: null,
            variant_uploaded_image:variant.variant_uploaded_image,
			varaint_total_weight: parseFloat(variant.varaint_total_weight),
			varaint_length: parseFloat(variant.varaint_length),
			varaint_width: parseFloat(variant.varaint_width),
			varaint_height: parseFloat(variant.varaint_height),
        }));
    
        const formattedDescription = formState.product_description.replace(/"/g, '\\"').replace(/\n/g, '\\n');
        const metaData = {
            meta_title: formState.meta_title,
            meta_desc: formState.meta_desc,
            meta_keywords: formState.meta_keywords
        };
    
        let operations = {
            query: `
            mutation ($metaData: metaDataInput, $product_images:[Upload], $product_variants: [product_variant_input]) {
                addUpdateProductsCMS(
                    product_id: ${productId},
                    storeIDs: [${formState.store_id}],
                    product_tags: ["${formState.product_tags.join('", "')}"],
                    product_title: "${formState.product_title}",
                    product_slug: "${formState.product_slug}",
                    product_sku: "${formState.product_sku}",
                    product_short_description: "${formState.product_short_description}",
                    product_description: "${formattedDescription}",
                    product_display_order: ${formState.product_display_order},
                    product_status: ${formState.product_status},
                    product_brand: "${formState.product_brand}",
                    product_supplier: "${formState.product_supplier}",
                    product_hst: ${formState.product_hst},
                    product_isfree: ${formState.product_isfree},
                    meta_data: $metaData,
                    top_deal_missingpop: ${formState.top_deal_missingpop ? formState.top_deal_missingpop : 0},
					top_selling_missingpop: ${formState.top_selling_missingpop ? formState.top_selling_missingpop : 0},
					top_selling: ${formState.top_selling},
					notify_customers: ${formState.notify_customers},
                    product_in_stock: ${formState.product_in_stock},
                    product_categories: [${formState.product_categories}],
                    product_variants:  $product_variants,
                    product_images: $product_images
                    product_uploaded_image: ${JSON.stringify(formState.product_uploaded_image)}
                    related_products: [${formState.related_products?.map(product => `"${product.product_sku}"`).join(',')}]
                )
            }
        `,
        variables: {
            metaData: metaData,
            product_variants: formattedVariants
         }
        }

        let imageNeedToUpload = false;
        let variantImageNeedToUpload = false;
        const formData = new FormData();
        const map = {};
        const fianlFile = formState.product_images.filter(image => image.status !== "ulreadyUpload");
        if(fianlFile.length > 0) {
            imageNeedToUpload = true;
            for(let i = 0; i < fianlFile.length; i++) {
                map[`product_images.${i}`] = [`variables.product_images.${i}`];
            }
        }
      
        transformedVariants.forEach((variant, index) => {
            if (variant.variant_image !== null) {
                variantImageNeedToUpload = true;
                map[`product_variants.${index}.variant_image`] = [`variables.product_variants.${index}.variant_image`];
            }
        });
    
        formData.append("operations", JSON.stringify(operations));
        formData.append("map", JSON.stringify(map));

        if (imageNeedToUpload) {
            if (fianlFile.length > 0) {
                for (let i = 0; i < fianlFile.length; i++) {
                    formData.append(`product_images.${i}`, fianlFile[i]?.originFileObj);
                }
            }
         }

         if (variantImageNeedToUpload) {
            transformedVariants.forEach((variant, index) => {
                if (variant.variant_image !== null) {
                    console.log("variant.variant_image.originFileObj",variant.originFileObj)
                    formData.append(`product_variants.${index}.variant_image`, variant.originFileObj);
                }
            });
        }

        return axios.post('', formData)
         .then(res => res)
         .catch(err => Promise.reject(err));
    }

    searchProductBrandList(axios, key) {
        let body = {
            query: `
                query SearchProductBrands($searchTerm: String) {
                    searchProductBrands(search_term: $searchTerm)
                }
            `,
            variables: {
                searchTerm: key
            }
        };
    
        return axios.post('', JSON.stringify(body), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.data)
        .catch(err => Promise.reject(err));
    }

    searchProductSupplierList(axios, key) {
        let body = {
            query: `
                query searchProductSuppiers($searchTerm: String) {
                    searchProductSuppiers(search_term: $searchTerm)
                }
            `,
            variables: {
                searchTerm: key
            }
        };
    
        return axios.post('', JSON.stringify(body), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.data)
        .catch(err => Promise.reject(err));
    }

    searchProductTag(axios, key) {
        let body = {
            query: `
                query searchProductTags($searchTerm: String) {
                    searchProductTags(search_term: $searchTerm)
                }
            `,
            variables: {
                searchTerm: key
            }
        };
    
        return axios.post('', JSON.stringify(body), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.data)
        .catch(err => Promise.reject(err));
    }

    searchRelatedProduct(axios, key,store_id) {
        let body = {
            query: `
                query {
                    searchproductsListingAdmin(
                        search_term:"${key}"
						store_id: ${store_id != undefined ? store_id : 0}
                    ) {
                            id
                            product_title
                            product_sku
							product_brand
							store_name
                    }
                }
            `
        };
        return axios.post('', JSON.stringify(body), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.data)
        .catch(err => Promise.reject(err));
    }
}

let productService = new ProductService()
export {
    productService
}