import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import Select from 'react-select';
import { TIMING } from '../../utils/Constants';

const initialState = {area: undefined, enter_time: "00:00", exit_time: "00:30"}
const RouteCity = (props) => {
    const { axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState, localID: props.area.localID})
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        props.updateFormState({...formState}, props.area.localID)
    }, [formState])
    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])
    useEffect(() => {
        if(props.citiesData.length && props.area) {
            setFormState({
                ...formState, 
                ...props.area
            })
        }
    }, [props.area, props.citiesData])
    const onChangeEnterTime = (e) => {
        setFormState({...formState, enter_time: {...e}})
    }
    const onChangeExitTime = (e) => {
        setFormState({...formState, exit_time: {...e}})
    }

    const onChangeCity = (e) => {
        setFormState({...formState, area: {...e}})
    }
    return (
        <>
        {
            <div className='row mb-4'>
                <div className='col-md-3'>
                    <label>Route City<span className="text-danger">*</span></label>
                    <Select 
                        className="select" 
                        value={formState.area} 
                        options={props.citiesData}
                        isLoading={props.loading}
                        name="area" 
                        onChange={(e) => onChangeCity(e)} 
                        isDisabled={disabled}
                        getOptionValue={(option) => `${option.id}`}
                        getOptionLabel={(option) => `${option.area_name}`}
                    >
                    </Select>
                </div>
                <div className='col-md-3'>
                    <label>Enter Time<span className="text-danger">*</span></label>
                    <Select 
                        className="select" 
                        value={formState.enter_time} 
                        options={TIMING.map(t => ({value: t, label: t}))}
                        name="enter_time" 
                        onChange={(e) => onChangeEnterTime(e)} 
                        isDisabled={disabled}
                    >
                    </Select>
                </div>
                <div className='col-md-3'>
                    <label>Exit Time<span className="text-danger">*</span></label>
                    <Select 
                        className="select" 
                        value={formState.exit_time} 
                        options={TIMING.map(t => ({value: t, label: t}))}
                        name="exit_time" 
                        onChange={(e) => onChangeExitTime(e)} 
                        isDisabled={disabled}
                    >
                    </Select>
                </div>
                <div className="col-md-1">
                    <i className='la la-trash delete-icon float-right variant-delete' onClick={(e) => props.deleteCity(e, props.area.localID)}/>
                </div>
            </div>
        }
        </>
    )
}

export default RouteCity