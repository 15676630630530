import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom';
import { REVIEW_PREFIX, TOAST_MSG_TYPE } from '../../utils/Constants';
import { routeService } from '../../Services/RouteService';

const initialState = {id: 0, review_stars: 5,review_comment: ""}
const CityForm = (props) => {
    const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    useEffect(() => {
        if(props.city_id) {
            routeService.getReviewById(axiosPrivate, props.city_id).then(res => {
                if(res.data.getReviewById) {
                    const data = res.data.getReviewById
                    setFormState({
                        ...data,
                        id: data.id
                    })
                    setShowLoader(false)
                }else {
                    navigate(`${REVIEW_PREFIX}`)
                }
            })    
        }else {
            setShowLoader(false)
        }
    }, [props.city_id])

    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
		setFormState({...formState, [name]: value})
		
    }
    const submitHandler = (e) => {
		console.log(formState)
        e.preventDefault()
        if(!formState.review_stars) {
            showToast("Please Fill Area", TOAST_MSG_TYPE.ERROR)
            return
        }
        const apiPromise = routeService.addUpdateProductReviews(axiosPrivate, formState)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.addUpdateProductReviews) {
                showToast(props.crud === "create" ? "Review Added Successfully!" : "Review Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${REVIEW_PREFIX}`)
            }
        })
    }

    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-2'>
                        <div className='form-group'>
                            <label>Review Stars*</label>
                            <input type="number" max='5' className='form-control' name='review_stars' value={formState.review_stars} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-10'>
                        <div className='form-group'>
                            <label>Review Comment*</label>
                            <input type="text" className='form-control' name='review_comment' value={formState.review_comment} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>               
									
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default CityForm