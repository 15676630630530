import React, { useState } from 'react'

const OTPInput = (props) => {
    const [otpVal, setOTPValue] = useState("")
    const otpTextInput = []
    for(let i=0;i<props.otpLength; i++){
        let nextVal = i+1
        let prevVal = i-1
        if(prevVal < 0) {
            prevVal = ""
        }else {
            prevVal = "OTP_"+prevVal
        }
        if(nextVal >= props.otpLength){
            nextVal = ""
        }else {
            nextVal = "OTP_"+nextVal
        }
        var obj = {
            id: "OTP_"+i,
            nextInput: nextVal,
            prevVal: prevVal,

        }
        otpTextInput.push(obj)
    }

    const onChangeHandle = (event) => {
        const name = event.target.name
        let prevInput = event.target.attributes.previnput.value
        let nextInput = event.target.attributes.nextinput.value
        const value = event.target.value

        let newOTPVal = ""
        if(value === ""){
            newOTPVal = otpVal.slice(0, -1)
            setOTPValue(newOTPVal)
            if(prevInput !== "") {
                document.getElementById(prevInput).focus()
            }
        }else {
            newOTPVal = otpVal+value
            
            if(nextInput !== ""){
                document.getElementById(nextInput).focus()
            }else{
                props.updateOTPValue(newOTPVal)
            }
            
        }
        setOTPValue(newOTPVal)
        props.updateOTPValue(newOTPVal)
    }

    return (
        otpTextInput.map((inputData, i) => {
            return <div className='col-2' key={inputData.id}>
                <input type="text" className="form-control text-center" style={{marginRight: "9px"}}
                    id={inputData.id}
                    maxLength={1} 
                    nextinput={inputData.nextInput} 
                    previnput={inputData.prevVal}
                    onChange={(event) => onChangeHandle(event)}
                />
            </div>
        })
    )
}

export default OTPInput