import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { COUPON_PREFIX, MERCHANT_PREFIX, TOAST_MSG_TYPE, getConstantData,GIFT_PREFIX,API_BASE_URL } from '../utils/Constants';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { userService } from '../Services/UserService';
import { useGiftCardList } from '../hooks/RouteHooks';
import { routeService } from '../Services/RouteService';

const GiftCards = () => {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo } = useAuth()
    const [params, setParams] = useState({counter: 0, limit: 10, offset: 0, search: "", field: "id", sort: "DESC"})
    const {giftCardData, loading, error, total} = useGiftCardList(axiosPrivate, params)
    useEffect(() => {
        if(userInfo.id) {
            setParams({...params, merchant_id: userInfo.id})
        }
    }, [userInfo])
    
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'giftcard_name',
                sorter: true,
            },
            {
                title: 'Type',
                dataIndex: 'giftcard_type',
                render: (text) => {
                    return `${text}`
                }
            },
            {
                title: 'Coupons',
                dataIndex: 'giftcard_couponcode',
				 render: (text,row) => {
                    return row.giftcard_type==='Single' ? row.giftcard_couponcode : <a className="btn btn-success" href="#" onClick={(e) => menuData(e,row)}>Download</a>
                }
            },
			{
                title: 'Gift Amount',
                dataIndex: 'giftcard_amount'
            },
			{
                title: 'Min Amount',
                dataIndex: 'giftcard_order_min_amount'
            },
			{
                title: 'Total No',
                dataIndex: 'giftcard_totalnumbers',
				render: (text) => {
                    return `${text}`
                }
            },
			{
                title: 'Start date',
                dataIndex: 'giftcard_startdate',
				render: (text) => {
                    return moment.unix(text).format("YYYY-MM-DD")
                }
            },
			{
                title: 'End date',
                dataIndex: 'giftcard_enddate',
				render: (text) => {
                    return moment.unix(text).format("YYYY-MM-DD")
                }
            }
        ]
        if(auth.permissions.includes("InActiveCoupon")) {
            columns.push(
                {
                    title: 'Status',
                    render: (text, record) => (
                        <div className="dropdown action-label text-center">
                            <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">{`${getCityStatusString(record.giftcard_status)}`}</a>
                            <div className="dropdown-menu dropdown-menu-right">
                            {
                                getConstantData("STATUS", auth.constants).map(status => {
                                    return <a key={`${record.id}_${status.id}`} className="dropdown-item" href="#" onClick={(e) => changeCityStatus(e, record, status.id)}>{status.value}</a>
                                })
                            }
                            </div>
                        </div>
                    )
                        
                }
            )
        }
        return columns
    }, [auth.permissions])

    const getCityStatusString = (status) => {
        var statusObj = {}
        if(auth.constants.hasOwnProperty("STATUS")) {
            statusObj = auth.constants["STATUS"].find((s) => s.id == status)
        }
        return statusObj.hasOwnProperty("value") ? statusObj.value : ""
    }
const menuData = (e,objKey) => {
        e.preventDefault()
		console.log("click")
		console.log(objKey)
		let params={
			id: objKey.id,
			title: objKey.giftcard_name
		}
        const apiPromise = routeService.getGiftCouponsExcel(axiosPrivate,params)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            const data = res.data.downloadAllCouponForAGrift
            if(data.status == "success") {
                const filePath = data.path.replace("./", "")
                console.log(filePath)
                const link = document.createElement("a");
                link.download = `giftCards.xlsx`;
                link.href = `${API_BASE_URL}${filePath}`;
                link.click();
            }
        })
    }
    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const changeCityStatus = (e, record, objKey) => {
        const param = {
            id: record.id,
            status: objKey
        }
        const apiPromise = routeService.changeStatusGiftCard(axiosPrivate, param)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.changeStatusGiftCard) {
                showToast("Gift Status Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
            }else {
                showToast("Something Wrong. Please try again later", TOAST_MSG_TYPE.ERROR)
            }
            let newCounter = params.counter + 1
            setParams((prev) => {
                return {
                    ...prev,
                    counter: newCounter
                }
            })
        })
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Gift Cards</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Manage Gift Cards</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Gift Cards</li>
                            </ul>
                        </div>
                        {
                            auth.permissions.includes("AddCity") ?
                            <div className="col-sm-6">
                                <Link to={`${GIFT_PREFIX}/create`} className="btn btn-primary btn-width float-right d-block">Add New Gift Cards</Link>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 10, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={giftCardData}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default GiftCards;