import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link,useNavigate } from 'react-router-dom';
import { COUPON_PREFIX, MERCHANT_PREFIX, TOAST_MSG_TYPE, getConstantData,PUSHNOTICE_PREFIX,API_BASE_URL,NOTICE_PREFIX } from '../utils/Constants';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { userService } from '../Services/UserService';
import { useNotificationsList,useCityList } from '../hooks/RouteHooks';
import { routeService } from '../Services/RouteService';
import { productService } from '../Services/ProductService';

const GiftCards = () => {
	const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo } = useAuth()
    const [params, setParams] = useState({counter: 0, limit: 20, offset: 0, search: "", field: "id", sort: "DESC"})
    const {giftCardData, loading, error, total} = useNotificationsList(axiosPrivate, params)
    
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Title',
                dataIndex: 'notification_title',
                sorter: true,
            },
            {
                title: 'message',
                dataIndex: 'notification_body',
                render: (text) => {
                    return `${text}`
                }
            },
			{
                title: 'Type',
                dataIndex: 'notification_type'
            },
			{
                title: 'City',
                dataIndex: 'customer_city',
				render: (text,row) => {
					return row.notification_data.customer_city;
				}
            },
            {
                title: 'SendTo',
                dataIndex: 'firebase_topic_name',
				render: (text,row) => {
					return row.notification_customers && row.notification_customers>1 ? row.notification_customers :  row.send_to.length;
				}
            },
			{
                title: 'Opend_By',
                dataIndex: 'firebase_topic_name',
				render: (text,row) => {
                   return <a href={`pushnotice/customers/${row.id}`}>{row.cart_id}<i class="btn">({row.opened_by ? (row.opened_by).length : 0})</i></a>
                }
            },
			{
                title: 'CreatedAt',
                dataIndex: 'createdAt',
				render: (text,row) => {
                    return moment.unix(row.createdAt/1000).format("YYYY-MM-DD: HH:mm:ss")
                }
            },
			{
                title: 'Type',
                dataIndex: 'notification_type',
				render: (text,row) => {
				return <a className="btn btn-primary" href="#" onClick={(e) => changeCityStatus(e, row)}>RESEND</a>
				}
            }
        ]
        return columns
    }, [auth.permissions])

    const getCityStatusString = (status) => {
        var statusObj = {}
        if(auth.constants.hasOwnProperty("STATUS")) {
            statusObj = auth.constants["STATUS"].find((s) => s.id == status)
        }
        return statusObj.hasOwnProperty("value") ? statusObj.value : ""
    }

    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }
	

    const changeCityStatus = (e, record) => {
		
        let paramData ={
			title: record.notification_title,
			message: record.notification_body,
			external_link: record.notification_data.external_link,
			notification_type: "GENERAL",
			firebase_topic_name: record.notification_type,
			customer_city: record.notification_data.customer_city,
			send_to: record.send_to,
			product_id: record.notification_data && record.notification_data.product_id ? record.notification_data.product_id: 0,
			category_id: record.notification_data && record.notification_data.category_id ? record.notification_data.category_id: 0
		}
        const apiPromise = productService.sendNotifications(axiosPrivate, paramData)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.sendNotifications) {
                showToast("Notification Send Successfully!", TOAST_MSG_TYPE.SUCCESS)
                navigate(`${NOTICE_PREFIX}`)
            }
        })
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Gift Cards</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Push Notications</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Push Notications</li>
                            </ul>
                        </div>
                        {
                            auth.permissions.includes("AddCity") ?
                            <div className="col-sm-6">
                                <Link to={`${PUSHNOTICE_PREFIX}/create`} className="btn btn-primary btn-width float-right d-block">Add New Notication</Link>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
							
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 20, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={giftCardData}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default GiftCards;