import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

const requireAuth = (WrappedComponent, componentName) => {
    // const { auth } = useAuth()

    return function(props) {
        const { auth, dashboardPrefix } = useAuth()
        const navigate = useNavigate()
        useEffect(() => {
            console.log(auth.permissions)
            console.log(componentName)
            if(!auth.permissions.includes(componentName)) {
                navigate(`/unauthorize`)
            }
        }, [])

        return (
            <WrappedComponent {...props} />
        )
    }
}

export default requireAuth