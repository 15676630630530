import { useEffect, useReducer } from "react"
import { storeService } from "../Services/StoreService"
import { ACTIONS } from "../utils/Constants"
import { routeService } from "../Services/RouteService"
import { orderService } from "../Services/OrderService"

function orderListReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, orderData: action.payload.data,driver_list: action.payload.driver_list, total: action.payload.total,total_amount: action.payload.total_amount,total_refund_amount: action.payload.total_refund_amount,total_refund_request: action.payload.total_refund_request,important_orders: action.payload.important_orders,}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}

function orderDetailReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true, fleetDetails: undefined}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, driver_list: action.payload.driver_list, orderDetail: action.payload.detail, orderItems: action.payload.items, fleetDetails: action.payload.fleet}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error, fleetDetails: undefined}
        default:
            return state
    }
}

function orderStatusLogReducer(state, action) {
    switch(action.type) {
        case ACTIONS.MAKE_REQUEST:
            return {...state, loading: true}
        case ACTIONS.GET_DATA:
            return {...state, loading: false, orderLog: action.payload.data}
        case ACTIONS.ERROR:
            return {...state, loading: false, error: action.error}
        default:
            return state
    }
}

function useOrderList(axios, params) {
    const [state, dispatch] = useReducer(orderListReducer, {loading: false, orderData: [], error: "", total: 0,total_amount: 0,total_refund_amount: 0,total_refund_request: 0,important_orders: 0,driver_list:[] })
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        orderService.getOrdersList(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.orderListingCMS.list,driver_list: res.data.orderListingCMS.driver_list, total: res.data.orderListingCMS.total,total_amount: res.data.orderListingCMS.total_amount,total_refund_amount: res.data.orderListingCMS.total_refund_amount,total_refund_request: res.data.orderListingCMS.total_refund_request,important_orders: res.data.orderListingCMS.important_orders}})
        })
    }, [params])
    return state
}
function useOrderProductsList(axios, params) {
    const [state, dispatch] = useReducer(orderListReducer, {loading: false, orderData: [], error: "", total: 0})
    useEffect(() => {
        dispatch({type: ACTIONS.MAKE_REQUEST})
        orderService.getOrdersProductsList(axios, params).then(res => {
            dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.excelOrdersProductsByDeliveryDateList.list, total: res.data.excelOrdersProductsByDeliveryDateList.total}})
        })
    }, [params])
    return state
}

function useOrderDetail(axios, orderID) {
    const [state, dispatch] = useReducer(orderDetailReducer, {loading: true,driver_list:[], orderDetail: {}, orderItems: [], fleetDetails: undefined, error: ""})
    useEffect(() => {
        if(orderID.order) {
            dispatch({type: ACTIONS.MAKE_REQUEST})
            orderService.getOrderDetails(axios, orderID.order).then(res => {
                dispatch({type: ACTIONS.GET_DATA, payload: {driver_list: res.data.orderDetailsCMS.driver_list,detail: res.data.orderDetailsCMS.order_details, items: res.data.orderDetailsCMS.order_items, fleet: res.data.orderDetailsCMS.driver_fleet_details}})
            })
        }
    }, [orderID])
    return state
}

function useOrderLog(axios, orderID) {
    const [state, dispatch] = useReducer(orderStatusLogReducer, {loading: false, orderLog: [], error: ""})
    useEffect(() => {
        if(orderID.order) {
            dispatch({type: ACTIONS.MAKE_REQUEST})
            orderService.getOrderStatusLog(axios, orderID.order).then(res => {
                dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.orderStatusLogs}})
            })
        }
    }, [orderID])
    return state
}
function useCustomerRewardLogs(axios, params) {
    const [state, dispatch] = useReducer(orderStatusLogReducer, {loading: false, orderLog: [], error: "",total:0})
    useEffect(() => {
        if(params) {
            dispatch({type: ACTIONS.MAKE_REQUEST})
            orderService.customerRewardPointsList(axios, params).then(res => {
                dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.customerRewardPointsList.list,total: res.data.customerRewardPointsList.total}})
            })
        }
    }, [params])
    return state
}
function useOrderCommentsListing(axios, params) {
    const [state, dispatch] = useReducer(orderStatusLogReducer, {loading: false, orderLog: [], error: "",total:0})
    useEffect(() => {
        if(params) {
            dispatch({type: ACTIONS.MAKE_REQUEST})
            orderService.orderCommentsListing(axios, params).then(res => {
                dispatch({type: ACTIONS.GET_DATA, payload: {data: res.data.orderCommentsListing.list,total: res.data.orderCommentsListing.total}})
            })
        }
    }, [params])
    return state
}

export {
    useOrderList,
    useOrderDetail,
	useOrderProductsList,
    useOrderLog,
	useCustomerRewardLogs,
	useOrderCommentsListing
}